import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
    Wizard,
    Main,
    ContentSection,
    Wrapper,
    Layout,
    Spinner,
    Link,
    Card,
    Button,
} from "@vwfs-bronson/bronson-react";
import { useDispatch } from "react-redux";

import { useSummary } from "../hooks/useSummary";

import { resetSelectedVehicle } from "../actions/summaryAction";
import { resetVehicleAvailables } from "../actions/vehicleAction";

import { Summary } from "../components/Summary";
import { Extras } from "../components/Optionals/Extras";
import { AdditionalDriver } from "../components/Optionals/AdditionalDriver";
import { Coverages } from "../components/Optionals/Coverages";
import { getExtras, getOptionals, resetOptionalData } from "../actions/optionalAction";
import { useOptional } from "../hooks/useOptional";

export const OptionalsSummaryPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { selectedVehicle, selectedDates, selectedTariff, selectedInstallation, selectedBigger25 } = useSummary();
    const { loadingOptionals, loadingExtras, extras, additionalDriver } = useOptional();

    useEffect(() => {
        if (!selectedVehicle) {
            dispatch(resetOptionalData());
            navigate("/vehicles");
        }
        if (!selectedDates) {
            dispatch(resetOptionalData());
            navigate("/");
        }

        if (selectedVehicle && selectedDates && selectedTariff && selectedInstallation && !additionalDriver) {
            const optionalsRequest = {
                startDate: selectedDates.startDate,
                endDate: selectedDates.endDate,
                fareId: selectedTariff.Id,
                installationId: selectedInstallation.id,
                vehicleId: selectedVehicle.id,
                over25: !!selectedBigger25,
            };

            const extrasRequest = {
                startDate: selectedDates.startDate,
                endDate: selectedDates.endDate,
                installationId: selectedInstallation.id,
            };

            dispatch(getOptionals(optionalsRequest));
            dispatch(getExtras(extrasRequest));
        }
    }, [
        selectedVehicle,
        selectedDates,
        selectedBigger25,
        selectedTariff,
        selectedInstallation,
        dispatch,
        navigate,
        additionalDriver,
    ]);

    const handleOnClick = () => {
        navigate("/contact-details");
    };

    return (
        <Main>
            <ContentSection pageWrapSize="medium">
                <Wrapper>
                    <Layout>
                        <Layout.Item className={"row"}>
                            <Layout className={"u-mt-large"}>
                                <Layout.Item>
                                    <Wizard collapseBreakpoint="m" collapsedCounter="3/4" collapsedText="Escoge coche">
                                        <Wizard.Item
                                            onClick={() => {
                                                dispatch(resetVehicleAvailables());
                                                dispatch(resetSelectedVehicle());
                                                dispatch(resetOptionalData());
                                            }}
                                            done
                                        >
                                            Localización y Fechas
                                        </Wizard.Item>
                                        <Wizard.Item
                                            onClick={() => {
                                                dispatch(resetSelectedVehicle());
                                                dispatch(resetOptionalData());
                                                navigate("/vehicles");
                                            }}
                                            done
                                        >
                                            Escoge coche
                                        </Wizard.Item>
                                        <Wizard.Item noInteraction active>
                                            Opcionales
                                        </Wizard.Item>
                                        <Wizard.Item noInteraction>Datos personales y Reserva</Wizard.Item>
                                    </Wizard>
                                </Layout.Item>
                            </Layout>
                        </Layout.Item>
                        <Layout.Item>
                            <h3 className="u-m-none">Coberturas</h3>
                        </Layout.Item>
                        <Layout.Item className={"u-m-none u-mt-small"}>
                            <Layout flush>
                                <Layout.Item>
                                    <Link
                                        small="true"
                                        to={() => {}}
                                        icon="semantic-back"
                                        onClick={() => {
                                            dispatch(resetSelectedVehicle());
                                        }}
                                    >
                                        Ver vehículos
                                    </Link>
                                </Layout.Item>
                            </Layout>
                        </Layout.Item>
                        <Layout.Item default={"3/5"} m="1/2" s="1/1">
                            <Layout>
                                <Layout.Item>
                                    <Spinner busy={loadingOptionals} section>
                                        <Coverages />
                                    </Spinner>
                                </Layout.Item>
                                <Layout.Item>
                                    <h3 className="u-m-none">Conductor adicional</h3>
                                </Layout.Item>
                                <Layout.Item>
                                    <Spinner busy={loadingOptionals} section>
                                        <AdditionalDriver />
                                    </Spinner>
                                </Layout.Item>
                                {!loadingExtras && extras.length > 0 && (
                                    <>
                                        <Layout.Item>
                                            <h3 className="u-m-none">Extras</h3>
                                        </Layout.Item>
                                        <Layout.Item>
                                            <Spinner busy={loadingOptionals} section>
                                                <Extras />
                                            </Spinner>
                                        </Layout.Item>
                                    </>
                                )}
                            </Layout>
                        </Layout.Item>
                        <Layout.Item default={"2/5"} m="1/2" s="1/1">
                            <Card element="article">
                                <Layout flush className="u-text-left">
                                    {selectedVehicle && (
                                        <Layout.Item>
                                            <Button className="u-mb" onClick={() => handleOnClick()} full>
                                                Reservar
                                            </Button>
                                        </Layout.Item>
                                    )}
                                    <Layout.Item>
                                        <Summary></Summary>
                                    </Layout.Item>
                                    {selectedVehicle && (
                                        <Layout.Item>
                                            <Button className="u-mt" onClick={() => handleOnClick()} full>
                                                Reservar
                                            </Button>
                                        </Layout.Item>
                                    )}
                                </Layout>
                            </Card>
                        </Layout.Item>
                    </Layout>
                </Wrapper>
            </ContentSection>
        </Main>
    );
};
