import { types } from "../types/types";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const initialState = {
    imagePath: "",
    installationTax: null,
    showCookiesModal: cookies.get("rentacar") ? false : true,
    showScheduleInfoModal: null,
};

export const globalReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.globalsGetImageGeneralVariablePathLoaded:
            return {
                ...state,
                imagePath: action.payload,
            };
        case types.globalsGetInstallationRacPercentTaxLoaded:
            return {
                ...state,
                installationTax: action.payload,
            };
        case types.globalsSetShowCookiesModal:
            return {
                ...state,
                showCookiesModal: action.payload,
            };
            case types.globalsSetSchedulesInfoModal:
                return {
                    ...state,
                    showScheduleInfoModal: action.payload,
                };
        default:
            return state;
    }
};
