/** @format */

import { types } from "../types/types";

export const defaultState = {
  selectedInstallation: null,
  selectedInstallationMap: null,
  selectedVehicle: null,
  selectedTariff: null,
  selectedDates: null,
  selectedBigger25: true,
  selectedAdditionalDriver: null,
  selectedCoverage: {
    coverage: "F3",
    name: "Seguro con Franquicia 300",
    text: "Cobertura de daños y robo con 300 euros de franquicia. Cobertura sin franquicia para lunas y cristales. Seguro contra daños a terceros. Protección para todos los ocupantes y el conductor. Asistencia en carretera en caso de accidente y/o avería.",
  },
  selectedExtras: [],
  bookingLocator: "",
  selectedContactData: null,
};

const initialState =
  JSON.parse(localStorage.getItem("ReactSummary")) || defaultState;

export const summaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.summarySetSelectedBigger25:
      return {
        ...state,
        selectedBigger25: action.payload,
      };
    case types.summarySetSelectedTariff:
      return {
        ...state,
        selectedTariff: { ...action.payload },
      };
    case types.summarySetSelectedInstallation:
      return {
        ...state,
        selectedInstallation: { ...action.payload },
      };
    case types.summaryResetSelectedInstallation:
      return {
        ...defaultState,
      };
    case types.summarySetSelectedInstallationMap:
      return {
        ...state,
        selectedInstallationMap: { ...action.payload },
      };
    case types.summaryResetSelectedInstallationMap:
      return {
        ...defaultState,
      };
    case types.summarySetSelectedVehicle:
      return {
        ...state,
        selectedVehicle: { ...action.payload },
        selectedCoverage: defaultState.selectedCoverage,
        selectedExtras: [],
      };
    case types.summaryResetSelectedVehicle:
      return {
        ...state,
        selectedVehicle: null,
        selectedCoverage: defaultState.selectedCoverage,
        selectedAdditionalDriver: null,
        selectedExtras: [],
      };
    case types.summarySetSelectedDates:
      return {
        ...state,
        selectedDates: { ...action.payload },
      };
    case types.summarySetSelectedCoverage:
      return {
        ...state,
        selectedCoverage: { ...action.payload },
      };
    case types.summarySetSelectedAdditionalDriver:
      return {
        ...state,
        selectedAdditionalDriver: { ...action.payload },
      };
    case types.summaryResetSelectedAdditionalDriver:
      return {
        ...state,
        selectedAdditionalDriver: null,
      };
    case types.summaryResetSelectedDates:
      return {
        ...state,
        selectedDates: null,
        selectedVehicle: null,
        selectedCoverage: defaultState.selectedCoverage,
        selectedExtras: [],
        selectedInstallation: [],
        selectedInstallationMap: [],
        bookingLocator: "",
        selectedContactData: null,
      };
    case types.summarySetAmountExtraSelectedExtras:
      return {
        ...state,
        selectedExtras: [...action.payload],
      };
    case types.summarySetBookingLocator:
      return {
        ...state,
        bookingLocator: action.payload,
      };
    case types.summarySetSelectedContactData:
      return {
        ...state,
        selectedContactData: { ...action.payload },
      };
    case types.summaryResetAll:
      return { ...defaultState, selectedTariff: state.selectedTariff };
    default:
      return state;
  }
};
