import React from "react";

import { Form, Fieldset, Layout, FormField, Input, Button, Spinner, FormHeading } from "@vwfs-bronson/bronson-react";
import { useForm } from "../hooks/useForm";
import { postRequest } from "../config/axiosClient";
import { useDispatch } from "react-redux";
import { addNotificationError } from "../actions/notificationAction";
import { useLoading } from "../hooks/useLoading";
import {
    setAdditionalDriverSelected,
    setAmountExtraSelectedExtras,
    setCoverageSelected,
    setSelectedBigger25,
    setSelectedBookingNumber,
    setSelectedContactData,
    setSelectedDates,
    setSelectedInstallation,
    setSelectedVehicle,
} from "../actions/summaryAction";
import moment from "moment";
import { showAdditionalDriverInfo } from "../helpers/showAdditionalDriverInfo";
import { showCoverageInfo } from "../helpers/showCoverageInfo";
import { showExtraInfo } from "../helpers/showExtraInfo";

export const BookingSearch = () => {
    const dispatch = useDispatch();

    const [formValues, handleOnChange, reset] = useForm({
        Email: "",
        Locator: "",
    });

    const [{ isLoading }, { start, stop }] = useLoading();

    const handleOnSubmit = async (e) => {
        e.preventDefault();

        start();

        try {
            var { data, status } = await postRequest("getBooking", formValues);
            stop();
            reset();

            if (status === 404) {
                dispatch(addNotificationError("No se ha encontrado la reserva"));
                return;
            }

            dispatch(setSelectedInstallation(data.installationPickUp));
            dispatch(setSelectedDates({ startDate: data.startDate, endDate: data.endDate }));

            dispatch(
                setSelectedVehicle({
                    ...data.rental.vehicle,
                    ...data.rental.cost,
                })
            );

            dispatch(
                setCoverageSelected({
                    ...data.insurance,
                    name: showCoverageInfo(data.insurance.coverage, "name"),
                    text: showCoverageInfo(data.insurance.coverage, "text"),
                })
            );

            data.extras.forEach((extra) => {
                dispatch(
                    setAmountExtraSelectedExtras({
                        ...extra,
                        amount: extra.quantity,
                        name: showExtraInfo(extra.id, "name"),
                    })
                );
            });

            if (data.driver) {
                dispatch(
                    setAdditionalDriverSelected({
                        total: data.driver.total,
                        name: showAdditionalDriverInfo("name"),
                        text: showAdditionalDriverInfo("text"),
                    })
                );
            }

            if (data.ageSupplement) {
                dispatch(setSelectedBigger25(false));
            }

            dispatch(
                setSelectedContactData({
                    CardDate: moment(data.client.licenseDate).format("DD/MM/YYYY"),
                    BirthDate: moment(data.client.birthDate).format("DD/MM/YYYY"),
                    Email: data.client.email,
                    Name: data.client.name,
                    Phone: data.client.phone,
                })
            );
            dispatch(
                setSelectedBookingNumber({
                    Locator: formValues.Locator,
                    Status: data.status,
                    ModificationDate: data.modifDate,
                })
            );
        } catch (error) {
            stop();
            dispatch(addNotificationError("No se ha encontrado la reserva"));
        }
    };

    return (
        <Layout center>
            <Layout.Item className={"row u-mt"}></Layout.Item>
            <Layout.Item className={"row u-mt"}></Layout.Item>
            <Layout.Item default={"1/3"} s="1/1">
                <Spinner busy={isLoading} section>
                    <Form onSubmit={handleOnSubmit} floatingLabel>
                        <React.Fragment key=".0">
                            <Fieldset>
                                <FormHeading className="u-text-center">Autenticación</FormHeading>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item>
                                            <FormField
                                                labelElement="div"
                                                labelText="Introduce el correo electrónico de tu reserva"
                                                type="input"
                                            >
                                                <Input
                                                    name={"Email"}
                                                    value={formValues.Email}
                                                    required
                                                    type="email"
                                                    onChange={handleOnChange}
                                                />
                                            </FormField>
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                            </Fieldset>
                            <Fieldset>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item>
                                            <FormField
                                                labelElement="div"
                                                labelText="Introduce el localizador de tu reserva"
                                                type="input"
                                            >
                                                <Input
                                                    name={"Locator"}
                                                    value={formValues.Locator}
                                                    required
                                                    type="input"
                                                    onChange={handleOnChange}
                                                />
                                            </FormField>
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                            </Fieldset>
                            <Fieldset>
                                <Fieldset.Row className="u-mt-small u-text-center">
                                    <Button disabled={isLoading} type="submit">
                                        Confirmar
                                    </Button>
                                </Fieldset.Row>
                            </Fieldset>
                        </React.Fragment>
                    </Form>
                </Spinner>
            </Layout.Item>
        </Layout>
    );
};
