const coverageInfo = {
    TR: {
        name: "Seguro con Franquicia 0",
        text: "Cobertura de daños y robo con 0 euros de franquicia. Cobertura sin franquicia para lunas y cristales. Seguro contra daños a terceros. Protección para todos los ocupantes y el conductor. Asistencia en carretera en caso de accidente y/o avería.",
    },
    F150: {
        name: "Seguro con Franquicia 150",
        text: "Cobertura de daños y robo con 150 euros de franquicia. Cobertura sin franquicia para lunas y cristales. Seguro contra daños a terceros. Protección para todos los ocupantes y el conductor. Asistencia en carretera en caso de accidente y/o avería.",
    },
    F3: {
        name: "Seguro con Franquicia 300",
        text: "Cobertura de daños y robo con 300 euros de franquicia. Cobertura sin franquicia para lunas y cristales. Seguro contra daños a terceros. Protección para todos los ocupantes y el conductor. Asistencia en carretera en caso de accidente y/o avería.",
    },
    F6: {
        name: "Seguro con Franquicia 600",
        text: "Cobertura de daños y robo con 600 euros de franquicia. Cobertura sin franquicia para lunas y cristales. Seguro contra daños a terceros. Protección para todos los ocupantes y el conductor. Asistencia en carretera en caso de accidente y/o avería.",
    },
};

export const showCoverageInfo = (coverage, option) => {
    if (!(coverage && option)) return;
    return coverageInfo[`${coverage}`][`${option}`];
};
