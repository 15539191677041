import React from "react";
import { Modal } from "@vwfs-bronson/bronson-react";
import { useGlobal } from "../hooks/useGlobal";
import { useDispatch } from "react-redux";
import { setShowSchedulesInfoModal } from "../actions/globalsAction";
import { Layout } from "@vwfs-bronson/bronson-react";
import { ScheduleInfo } from "./ScheduleInfo";

export const ScheduleInfoModal = () => {
  const { showScheduleInfoModal } = useGlobal();
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(setShowSchedulesInfoModal(null));
  };

  const handleAccept = () => {
    closeModal();
  };

  return (
    <Modal
      buttonConfirmText="Aceptar"
      center
      id="modalId"
      onClose={closeModal}
      onConfirm={handleAccept}
      shown={showScheduleInfoModal}
      title="Horario de la instalación"
    >
      <Layout>
        <Layout.Item className="u-mb-large">
          <p style={{ textAlign: "justify" }}>
            El horario seleccionado no coincide con el de apertura de la
            instalación. Si mantienes el horario indicado, ten en cuenta que no
            es definitivo y que tendrá que ser confirmado por el concesionario.
          </p>
          <p style={{ textAlign: "justify" }}>Horario de apertura:</p>
          <ScheduleInfo installation={showScheduleInfoModal}></ScheduleInfo>
        </Layout.Item>
      </Layout>
    </Modal>
  );
};
