import { ScheduleInfoModal } from "../components/ScheduleInfoModal";
import { postRequest } from "../config/axiosClient";
import { types } from "../types/types";
import { addNotificationError } from "./notificationAction";
import { setSelectedTariff } from "./summaryAction";

export const getImageGeneralVariablePath = () => {
    return async (dispatch) => {
        try {
            var requestObj = {
                IdAplication: 56,
                VariableName: "CNST_URL_OBTENERFICHERO",
            };

            var { data } = await postRequest("constants/generalvariable", requestObj);

            if (data) dispatch(getImageGeneralVariablePathLoaded(data));
        } catch (error) {
            console.error("Error al obtener la URL para las imagenes");
        }
    };
};

export const getTariff = () => {
    return async (dispatch) => { 
        
        try {
            var requestObj = process.env.REACT_APP_TARIFF;
            var { data } = await postRequest("fare", requestObj);
            if (data) dispatch(setSelectedTariff({ ...data }));
        } catch (error) {
            dispatch(addNotificationError("Ha ocurrido un error al obtener la tarifa"));
        }
    };
};

export const setShowCookiesModal = (payload) => ({
    type: types.globalsSetShowCookiesModal,
    payload,
});
export const setShowSchedulesInfoModal =(payload) => ({
    type: types.globalsSetSchedulesInfoModal,
    payload,
});
export const sendEmail = (obj) => {
    return async (dispatch) => {
        try {
            postRequest("bookings/sendemail", obj);
        } catch (error) {}
    };
};

export const getInstallationRacPercentTax = (id) => {
    return async (dispatch) => {
        try {
            var { data } = await postRequest("installation/tax", id);
            if (data) dispatch(getInstallationRacPercentTaxLoaded(data));
        } catch (error) {
            dispatch(addNotificationError("Ha ocurrido un error al obtener impuesto"));
        }
    };
};

export const getImageGeneralVariablePathLoaded = (payload) => ({
    type: types.globalsGetImageGeneralVariablePathLoaded,
    payload,
});

export const getInstallationRacPercentTaxLoaded = (payload) => ({
    type: types.globalsGetInstallationRacPercentTaxLoaded,
    payload,
});
