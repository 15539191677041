import { applyMiddleware, compose, createStore } from "redux";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import { globalReducer } from "../reducers/globalReducer";

import { installationReducer } from "../reducers/installationReducer";
import { notificationReducer } from "../reducers/notificationReducer";
import { optionalReducer } from "../reducers/optionalReducer";
import { summaryReducer } from "../reducers/summaryReducer";
import { vehicleReducer } from "../reducers/vehicleReducer";

const reducers = combineReducers({
    installation: installationReducer,
    vehicle: vehicleReducer,
    notification: notificationReducer,
    global: globalReducer,
    summary: summaryReducer,
    optional: optionalReducer,
});

const composeEnchancers = (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(reducers, composeEnchancers(applyMiddleware(thunk)));
