/** @format */

import {
  Checkbox,
  Layout,
  CheckboxGroup,
  Modal,
  Paragraph,
} from "@vwfs-bronson/bronson-react";
import { useFormik } from "formik";
import { useContext } from "react";
import useAdobeAnalytics from "../../../../hooks/useAdobeAnalytics";
import CookiesContext from "../../../../context/CookiesContextProvider";
export const CookiesGenericCustomizationModal = () => {
  const { setShowConfig, setShowModal } = useContext(CookiesContext);

  const { getCookies, setCookies } = useAdobeAnalytics();

  const handleOnSave = () => {
    setTimeout(() => {
      handleSubmit();
      closeModal();
    }, 999);
  };

  const handleAcceptAll = () => {
    setFieldValue("analiticas", true);
    setFieldValue("personalizacion", true);
    setFieldValue("marketing", true);
    setTimeout(() => {
      handleSubmit();
      closeModal();
    }, 1000);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowConfig(false);
  };

  const { getFieldProps, handleSubmit, setFieldValue } = useFormik({
    initialValues: getCookies() || {
      analiticas: false,
      personalizacion: false,
      marketing: false,
    },
    onSubmit: async (values) => {
      setCookies(values);
    },
  });

  return (
    <Modal
      buttonCancelText="Guardar"
      buttonConfirmText="Aceptar todas"
      center
      hideCloseButton
      onCancel={handleOnSave}
      onClose={closeModal}
      onConfirm={handleAcceptAll}
      title="Configuración de las cookies"
      shown
    >
      <form>
        <Layout>
          <Layout.Item>
            <div>
              <span>
                Desde este panel de configuración puedes configurar el uso de
                cookies. Nuestra configuración recomendada es que todas estas
                cookies estén activas, pero si no te encuentras satisfecho con
                esto, puedes administrar tus preferencias a continuación. Para
                más información consulta nuestra política de cookies haciendo{" "}
                <a
                  href="/#/cookies-policy"
                  onClick={() => setShowConfig(false)}
                >
                  clic aquí
                </a>
              </span>
            </div>
          </Layout.Item>
          <Layout.Item className="u-mb">
            <CheckboxGroup>
              <span>
                <strong> Cookies Técnicas </strong> <br />
                <Paragraph>
                  Las cookies técnicas permiten la funcionalidad principal.
                  Nuestro sitio web no puede funcionar correctamente sin estas
                  cookies, y solo pueden deshabilitarse cambiado las
                  preferencias de su navegador (tenga en cuenta que si esta
                  deshabilitado, nuestro sitio web puede no funcionar
                  correctamente o mostrarse correctamente en su dispositivo)
                </Paragraph>
              </span>
              <Checkbox
                {...getFieldProps("analiticas")}
                checked={getFieldProps("analiticas").value}
              >
                <strong> Cookies Analíticas </strong> <br />
                Incluyen las cookies propias y de terceros. Nos permiten
                cuantificar el número de usuarios y así realizar la medición y
                análisis estadístico de la utilización que hacen los usuarios
                del servicio ofertado. Para ello se analiza su navegación en
                nuestra página web con el fin de mejorar la oferta de productos
                o servicios que le ofrecemos
              </Checkbox>
              <Checkbox
                {...getFieldProps("personalizacion")}
                checked={getFieldProps("personalizacion").value}
              >
                <strong>Cookies de Personalización</strong> <br />
                Podrá acceder al servicio en función de características
                predeterminadas como podrían ser el tipo de navegador, el
                idioma, la configuración, etc.
              </Checkbox>
              <Checkbox
                {...getFieldProps("marketing")}
                checked={getFieldProps("marketing").value}
              >
                <strong>Cookies de Marketing</strong> <br />
                Incluyen las cookies propias y de terceros. Nos permiten
                gestionar de forma eficaz la oferta de los espacios
                publicitarios de la página web y también mostrarte contenidos
                adecuados a sus gustos e intereses, tanto en nuestra página web
                como en las de terceros.
              </Checkbox>
            </CheckboxGroup>
          </Layout.Item>
        </Layout>
      </form>
    </Modal>
  );
};
