export const adapterInstallationToGoogleMap = (installation) => {
    return {
        type: "Feature",
        properties: {
            cluster: false,
            category: "installation",
            installationId: installation.id,
        },
        geometry: {
            type: "Point",
            coordinates: [parseFloat(installation.longitude), parseFloat(installation.latitude)],
        },
    };
};
