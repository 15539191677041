import { types } from "../types/types";

export const initialState = {
    vehiclesAvailables: [],
    vehiclesAvailablesLoading: false,
    familiesAvailables: [],
};

export const vehicleReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.vehiclesSetListAvailableLoading:
            return {
                ...state,
                vehiclesAvailablesLoading: action.payload,
            };
        case types.vehiclesGetListAvailableLoaded:
            return {
                ...state,
                vehiclesAvailables: [...action.payload],
                vehiclesAvailablesLoading: false,
            };
        case types.vehiclesAvailablesReset:
            return {
                ...state,
                vehiclesAvailables: [],
            };
        case types.vehiclesSetFamiliesAvailablesLoaded:
            return {
                ...state,
                familiesAvailables: [...action.payload],
            };
        default:
            return state;
    }
};
