/** @format */

import { types } from "../types/types";

export const setSelectedBigger25 = (payload) => ({
  type: types.summarySetSelectedBigger25,
  payload,
});

export const setSelectedTariff = (payload) => ({
  type: types.summarySetSelectedTariff,
  payload,
});

export const setSelectedInstallation = (payload) => ({
  type: types.summarySetSelectedInstallation,
  payload,
});
export const setSelectedInstallationMap = (payload) => ({
  type: types.summarySetSelectedInstallationMap,
  payload,
});

export const resetSelectedInstallation = (payload) => ({
  type: types.summaryResetSelectedInstallation,
  payload,
});
export const resetSelectedInstallationMap = (payload) => ({
  type: types.summaryResetSelectedInstallationMap,
  payload,
});

export const setSelectedVehicle = (vehicle) => ({
  type: types.summarySetSelectedVehicle,
  payload: vehicle,
});

export const resetSelectedVehicle = () => ({
  type: types.summaryResetSelectedVehicle,
});

export const setSelectedDates = (selectedDates) => ({
  type: types.summarySetSelectedDates,
  payload: selectedDates,
});

export const setCoverageSelected = (selectedCoverage) => ({
  type: types.summarySetSelectedCoverage,
  payload: selectedCoverage,
});

export const setAdditionalDriverSelected = (selectedAdditionalDriver) => ({
  type: types.summarySetSelectedAdditionalDriver,
  payload: selectedAdditionalDriver,
});

export const resetAdditionalDriverSelected = () => ({
  type: types.summaryResetSelectedAdditionalDriver,
});

export const setAmountExtraSelectedExtras = (extra) => {
  return async (dispatch, getState) => {
    const selectedExtras = getState().summary.selectedExtras;

    if (extra.amount === 0) {
      dispatch({
        type: types.summarySetAmountExtraSelectedExtras,
        payload: [...(selectedExtras.filter((x) => x.id !== extra.id) || [])],
      });
      return;
    }

    let selectedExtra = selectedExtras.find((x) => x.id === extra.id);

    if (selectedExtra) {
      selectedExtra = selectedExtras.map((x) => {
        const resp = { ...x };

        if (resp.id === extra.id) {
          resp.amount = extra.amount;
        }

        return resp;
      });
    } else {
      selectedExtra = [...selectedExtras, { ...extra }];
    }

    dispatch({
      type: types.summarySetAmountExtraSelectedExtras,
      payload: [...selectedExtra],
    });
  };
};

export const setSelectedBookingNumber = (bookingLocator) => ({
  type: types.summarySetBookingLocator,
  payload: bookingLocator,
});

export const setSelectedContactData = (contact) => ({
  type: types.summarySetSelectedContactData,
  payload: contact,
});

export const resetSelectedDates = () => ({
  type: types.summaryResetSelectedDates,
});

export const resetAllSummaryData = () => ({
  type: types.summaryResetAll,
});
