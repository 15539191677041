import React from "react";
import { NotificationModal } from "./NotificationModal";
import { NotificationToast } from "./NotificationToast";

export const Notifications = () => {
    return (
        <>
            <NotificationModal />
            <NotificationToast />
        </>
    );
};
