import { isBlueLabel } from "./isBlueLabel";
import { isPorsche } from "./isPorsche";
import { isVolkswagen } from "./isVolkswagen";
import {isAudi} from "./isAudi";
import { isLcv } from "./isLcv";


export const getLandingURLByBrandName = () => {
    let maxImages = 4;
    let folder = "";
    if (isBlueLabel()) {
        maxImages = 10;
        folder = "bluelabel";
    }
    if (isVolkswagen()) {
        maxImages = 4;
        folder = "volkswagen";
    }
    if (isLcv()) {
        maxImages = 3;
        folder = "lcv";
    }
    if (isPorsche()) {
        maxImages = 1;
        folder = "porsche";
    }
    if (isAudi()) {
        maxImages = 2;
        folder = "audi";
    }
    return `/images/landing/${folder}/${Math.floor(maxImages * Math.random()) + 1}.jpg`;
};
