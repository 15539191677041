import { postRequest } from "../config/axiosClient";
import { types } from "../types/types";
import { addNotificationError } from "./notificationAction";

export const getInstallations = (obj = {}) => {
    return async (dispatch) => {
        try {
            var { data } = await postRequest("installations", obj);
            var { installations } = data;
            if (installations) {
                dispatch(getInstallationsLoaded(installations));
            }
        } catch (error) {
            dispatch(addNotificationError("Ha ocurrido un error al obtener las instalaciones."));
        }
    };
};

export const getInstallationsLoaded = (payload) => ({ type: types.installationGetListLoaded, payload });
