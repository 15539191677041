import React, { useState } from "react";

import { Layout } from "@vwfs-bronson/bronson-react";
import { GoogleMap } from "../Map/GoogleMap";
import { InstallationForm } from "./InstallationForm";

export const LandingInstallation = () => {
    const [installationComboSelected, setInstallationComboSelected] = useState();
    return (
        <Layout>
            <Layout.Item default="1/2" m="1/1">
                <InstallationForm
                    installationComboSelected={installationComboSelected}
                    setInstallationComboSelected={setInstallationComboSelected}
                ></InstallationForm>
            </Layout.Item>
            <Layout.Item default="1/2" m="1/1">
                <GoogleMap
                    installationComboSelected={installationComboSelected}
                    setInstallationComboSelected={setInstallationComboSelected}
                ></GoogleMap>
            </Layout.Item>
        </Layout>
    );
};
