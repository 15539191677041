import React, { useEffect, useState } from "react";
import { useForm } from "../hooks/useForm";

import {
  Form,
  Fieldset,
  Layout,
  FormField,
  Checkbox,
  DatePicker,
  Input,
  Spinner,
  Button,
  Link,
  ErrorMessage,
} from "@vwfs-bronson/bronson-react";
import { addNotificationModal } from "../actions/notificationAction";
import { useDispatch } from "react-redux";
import { useSummary } from "../hooks/useSummary";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../config/axiosClient";
import { adapterBookingFormRequest } from "../adapters/adapterBookingFormRequest";
import {
  setSelectedBookingNumber,
  setSelectedContactData,
} from "../actions/summaryAction";
import { useCalculateTotals } from "../hooks/useCalculateTotals";
import { sendEmail } from "../actions/globalsAction";

export const ContactForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [inserting, setInserting] = useState(false);

  const {
    selectedInstallation,
    selectedVehicle,
    selectedTariff,
    selectedDates,
    selectedBigger25,
    selectedAdditionalDriver,
    selectedExtras,
    selectedCoverage,
  } = useSummary();

  const { subTotal, total } = useCalculateTotals();

  const [formValues, handleOnChange] = useForm({
    CardDate: null,
    BirthDate: null,
    Accept: false,
  });

  const [showAcceptError, setShowAcceptError] = useState(false);
  const [showBirthDateError, setShowBirthDateError] = useState(false);
  const [showCardDateError, setShowCardDateError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);

  useEffect(() => {
    if (showAcceptError || showBirthDateError || showCardDateError) {
      setShowAcceptError(!formValues.Accept);
      setShowBirthDateError(
        !formValues.BirthDate ||
          formValues.BirthDate === moment().format("DD/MM/YYYY")
      );
      setShowCardDateError(
        !formValues.CardDate ||
          formValues.CardDate === moment().format("DD/MM/YYYY")
      );
    }
  }, [formValues, showAcceptError, showBirthDateError, showCardDateError]);

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formValues.Email) || !validatePhone(formValues.Phone)) {
      return;
    }

    setInserting(true);
    setShowAcceptError(false);
    setShowBirthDateError(false);
    setShowCardDateError(false);

    if (!formValues.Accept || !formValues.BirthDate || !formValues.CardDate) {
      setShowAcceptError(!formValues.Accept);
      setShowBirthDateError(!formValues.BirthDate);
      setShowCardDateError(!formValues.CardDate);
      setInserting(false);
      return;
    }

    var formRequest = adapterBookingFormRequest(
      formValues,
      selectedInstallation,
      selectedVehicle,
      selectedTariff,
      selectedDates,
      selectedBigger25,
      selectedAdditionalDriver,
      selectedExtras,
      selectedCoverage,
      subTotal,
      total
    );

    try {
      var { data } = await postRequest("insertBooking", formRequest);
      if (data) {
        dispatch(
          setSelectedBookingNumber({ Locator: data.Locator, Status: "RE" })
        );
        dispatch(setSelectedContactData(formValues));
        dispatch(sendEmail({ Locator: data.Locator, Email: formValues.Email }));
        navigate("/booking-confirm");
      } else setInserting(false);
    } catch (error) {
      setInserting(false);
    }
  };

  const handleOnClick = (e) => {
    e.preventDefault();

    dispatch(
      addNotificationModal(
        <p>
          Le informamos que sus datos personales serán tratados por Volkswagen
          Renting S.A. con domicilio social en Avda. de Bruselas, nº 34, 28108,
          Alcobendas (Madrid) con la finalidad de gestionar la reserva del
          vehículo. Asimismo, le informamos que sus datos serán comunicados al
          Concesionario seleccionado por Ud. para que éste pueda procesar la
          reserva y formalizar con Ud. el correspondiente contrato de alquiler.
          Puede ejercitar los derechos previstos en la normativa de protección
          de datos (acceso, rectificación o supresión, oposición, limitación del
          tratamiento y portabilidad de los datos) en la dirección postal
          indicada, así como contactar con{" "}
          <a href="mailto:clientes.renting@vwfs.com">
            clientes.renting@vwfs.com
          </a>{" "}
          o con nuestro Delegado de Protección de Datos en la dirección:{" "}
          <a href="http://DPO.es@vwfs.com" target="_blank" rel="noreferrer">
            DPO.es@vwfs.com
          </a>{" "}
          .
        </p>
      )
    );
  };

  const validateEmail = (email) => {
    var res = true;
    if (email !== undefined && email.trim().length > 0) {
      //const regex1 = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]{2,}$/;
      //const regex1 = /^[a-zA-Z0-9.#+_-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const regex1 =
        /^[a-z0-9*+=_~-]+(?:\.[a-z0-9_~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
      res = regex1.test(email);
    }

    if (showEmailError === res) {
      setShowEmailError(!res);
    }

    return res;
  };

  const validatePhone = (phone) => {
    var res = true;
    if (phone !== undefined && phone.trim().length > 0) {
      const regex1 = /^\+?\d{7,}$/;
      res = regex1.test(phone);
    }

    if (showPhoneError === res) {
      setShowPhoneError(!res);
    }

    return res;
  };

  return (
    <Form autoComplete="off" onSubmit={handleOnSubmit} floatingLabel>
      <React.Fragment key=".0">
        <Fieldset>
          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">
                <FormField
                  labelId="Name"
                  labelText="Nombre"
                  noFloatingLabel
                  type="other"
                >
                  <Input
                    name={"Name"}
                    value={formValues.Name}
                    required
                    onChange={handleOnChange}
                  />
                </FormField>
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                <FormField
                  labelId="Surnames"
                  labelText="Apellidos"
                  noFloatingLabel
                  type="other"
                >
                  <Input
                    name={"Surnames"}
                    value={formValues.Surnames}
                    required
                    onChange={handleOnChange}
                  />
                </FormField>
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                <FormField
                  labelId="Email"
                  labelText="Email"
                  noFloatingLabel
                  type="other"
                >
                  <Input
                    type="email"
                    name={"Email"}
                    value={formValues.Email}
                    required
                    onChange={handleOnChange}
                    /*pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"*/
                    addonText="@"
                    error={!validateEmail(formValues.Email)}
                  />
                  {showEmailError && (
                    <ErrorMessage>Debes insertar un email válido.</ErrorMessage>
                  )}
                </FormField>
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                <FormField
                  labelId="Phone"
                  labelText="Teléfono"
                  noFloatingLabel
                  type="other"
                >
                  <Input
                    type="tel"
                    name={"Phone"}
                    value={formValues.Phone}
                    required
                    onChange={handleOnChange}
                    addonIcon="phone"
                    error={!validatePhone(formValues.Phone)}
                  />
                  {showPhoneError && (
                    <ErrorMessage>
                      Debes insertar un teléfono válido.
                    </ErrorMessage>
                  )}
                </FormField>
              </Layout.Item>
            </Layout>
          </Fieldset.Row>
          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">
                <FormField
                  labelId="birthDate"
                  labelText="Fecha de Nacimiento"
                  noFloatingLabel
                  type="other"
                >
                  <DatePicker
                    dateFormat="d/m/Y"
                    locale="es"
                    name={"BirthDate"}
                    value={formValues.BirthDate}
                    onChange={(e) => {
                      setShowBirthDateError(e === "");
                      handleOnChange({
                        target: { name: "BirthDate", value: e },
                      });
                    }}
                    monthSelectorDropdown={true}
                    flatpickrOptions={{
                      onClose: (e) => {
                        if (e) {
                          handleOnChange({
                            target: {
                              name: "BirthDate",
                              value: moment(e[0]).format("DD/MM/YYYY"),
                            },
                          });
                        } else setShowBirthDateError(true);
                      },
                    }}
                    maxDate={moment()
                      .add(
                        selectedBigger25
                          ? -25
                          : selectedTariff?.EdadMinima * -1,
                        "years"
                      )
                      .format("DD/MM/YYYY")}
                    error={showBirthDateError}
                  />
                  {showBirthDateError && (
                    <ErrorMessage>
                      Debes tener mínimo{" "}
                      {selectedBigger25 ? 25 : selectedTariff?.EdadMinima} años.
                    </ErrorMessage>
                  )}
                </FormField>
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                <FormField
                  labelId="cardDate"
                  labelText="Fecha de Carnet"
                  noFloatingLabel
                  type="other"
                >
                  <DatePicker
                    dateFormat="d/m/Y"
                    locale="es"
                    name={"CardDate"}
                    value={formValues.CardDate}
                    onChange={(e) => {
                      setShowCardDateError(e === "");
                      handleOnChange({
                        target: { name: "CardDate", value: e },
                      });
                    }}
                    monthSelectorDropdown={true}
                    flatpickrOptions={{
                      onClose: (e) => {
                        if (e) {
                          handleOnChange({
                            target: {
                              name: "CardDate",
                              value: moment(e[0]).format("DD/MM/YYYY"),
                            },
                          });
                        } else setShowCardDateError(true);
                      },
                    }}
                    maxDate={moment()
                      .add(
                        selectedTariff?.AntiguedadMinimaCarnetConducir * -1,
                        "years"
                      )
                      .format("DD/MM/YYYY")}
                    error={showCardDateError}
                  />
                  {showCardDateError && (
                    <ErrorMessage>
                      Debes tener carnet desde hace más de{" "}
                      {selectedTariff?.AntiguedadMinimaCarnetConducir} año
                      {selectedTariff?.AntiguedadMinimaCarnetConducir > 1 &&
                        "s"}
                    </ErrorMessage>
                  )}
                </FormField>
              </Layout.Item>
            </Layout>
          </Fieldset.Row>
          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/1">
                <FormField type="checkbox">
                  <Checkbox
                    name={"Accept"}
                    onChange={(e) =>
                      handleOnChange({
                        target: { name: "Accept", value: e.target.checked },
                      })
                    }
                  >
                    He leído y acepto la{" "}
                    <Link onClick={handleOnClick} normalLink>
                      política de privacidad
                    </Link>
                  </Checkbox>
                  {showAcceptError && (
                    <ErrorMessage>
                      Debes aceptar los términos y condiciones
                    </ErrorMessage>
                  )}
                </FormField>
              </Layout.Item>
            </Layout>
          </Fieldset.Row>
          <Fieldset.Row className="u-text-center u-mt">
            <Button disabled={inserting} type="submit">
              Finalizar reserva
            </Button>
            {inserting && <Spinner></Spinner>}
          </Fieldset.Row>
        </Fieldset>
      </React.Fragment>
    </Form>
  );
};
