import React, { useEffect, useState } from "react";

import { ToastNotifications, ToastNotification } from "@vwfs-bronson/bronson-react";
import { useDispatch } from "react-redux";
import { useNotification } from "../../../hooks/useNotification";
import { SEVERITY_NOTIFICACION, TYPE_NOTIFICACION } from "../../../models/Notification";
import { deleteNotification } from "../../../actions/notificationAction";

export const NotificationToast = () => {
    const { notifications } = useNotification();
    const dispatch = useDispatch();

    const [notificationsToast, setNotificationsToast] = useState([]);

    const handleOnClose = (notification) => {
        dispatch(deleteNotification(notification));
    };

    useEffect(() => {
        setNotificationsToast(notifications.filter((x) => x.Type === TYPE_NOTIFICACION.TOAST));
        // setNotificationsAlert(notifications.filter((x) => x.Type === TYPE_NOTIFICACION.ALERT));
    }, [notifications]);

    return (
        <>
            {notificationsToast?.length > 0 && (
                <ToastNotifications>
                    {notificationsToast.map((notification) => {
                        if (notification.Severity === SEVERITY_NOTIFICACION.ERROR) {
                            return (
                                <ToastNotification
                                    key={"Notification_"}
                                    error
                                    notification={notification}
                                    onClose={() => handleOnClose(notification)}
                                >
                                    {notification.Description}
                                </ToastNotification>
                            );
                        }
                        if (notification.Severity === SEVERITY_NOTIFICACION.INFO) {
                            return (
                                <ToastNotification
                                    info
                                    key={"Notification_"}
                                    notification={notification}
                                    onClose={() => handleOnClose(notification)}
                                >
                                    {notification.Description}
                                </ToastNotification>
                            );
                        }
                        if (notification.Severity === SEVERITY_NOTIFICACION.SUCCESS) {
                            return (
                                <ToastNotification
                                    success
                                    key={"Notification_"}
                                    notification={notification}
                                    onClose={() => handleOnClose(notification)}
                                >
                                    {notification.Description}
                                </ToastNotification>
                            );
                        }
                        if (notification.Severity === SEVERITY_NOTIFICACION.WARNING) {
                            return (
                                <ToastNotification
                                    warning
                                    key={"Notification_"}
                                    notification={notification}
                                    onClose={() => handleOnClose(notification)}
                                >
                                    {notification.Description}
                                </ToastNotification>
                            );
                        }

                        return (
                            <ToastNotification
                                key={"Notification_"}
                                notification={notification}
                                onClose={() => handleOnClose(notification)}
                            >
                                {notification.Description}
                            </ToastNotification>
                        );
                    })}
                </ToastNotifications>
            )}
        </>
    );
};
