import React, { useEffect, useState } from "react";

import {
  DescriptionList,
  ProductTable,
  IconList,
  Layout,
  Link,
} from "@vwfs-bronson/bronson-react";
import { Thumbnail } from "./Thumbnail";
import { renderAppImage } from "../helpers/renderAppImage";
import { useGlobal } from "../hooks/useGlobal";
import { VehicleInfoIcons } from "./Vehicles/VehicleInfoIcons";
import { getRoundDaysBetweenDates } from "../helpers/getRoundDaysBetweenDates";
import { useSummary } from "../hooks/useSummary";
import { financialFormat } from "../helpers/financialFormat";
import { LessMoreExtraButton } from "./Optionals/LessMoreExtraButton";
import { formatToDate } from "../helpers/formatToDate";
import { formatToTime } from "../helpers/formatToTime";
import { resetVehicleAvailables } from "../actions/vehicleAction";
import { useDispatch } from "react-redux";
import { useCalculateTotals } from "../hooks/useCalculateTotals";

export const Summary = ({ isContactInfo = false }) => {
  const { imagePath } = useGlobal();

  const { subTotal, menor25Total, taxesTotal, total } = useCalculateTotals();
  const dispatch = useDispatch();

  const {
    selectedDates,
    selectedCoverage,
    selectedVehicle,
    selectedExtras,
    selectedAdditionalDriver,
    selectedInstallation,
    selectedBigger25,
  } = useSummary();
  const [days, setDays] = useState(0);

  useEffect(() => {
    if (selectedDates)
      setDays(
        getRoundDaysBetweenDates(
          selectedDates.startDate,
          selectedDates.endDate,
          true
        )
      );
  }, [selectedDates]);

  return (
    <div>
      <Layout flush className="u-text-left">
        <Layout.Item>
          <h3 className="u-mb-small">Tu selección</h3>
          <IconList>
            <IconList.Item
              className="u-mb-small"
              icon="location"
              iconMeaning="Not included:"
            >
              {selectedInstallation?.name} ({selectedInstallation?.province})
            </IconList.Item>
          </IconList>
        </Layout.Item>
        <Layout.Item>
          <h6 className="u-mb-small">Recogida</h6>
        </Layout.Item>
        <Layout.Item default="1/2">
          <IconList>
            <IconList.Item
              className="u-mb-small"
              icon="calendar"
              iconMeaning="Not included:"
            >
              {formatToDate(selectedDates?.startDate)}
            </IconList.Item>
          </IconList>
        </Layout.Item>
        <Layout.Item default="1/2">
          <IconList>
            <IconList.Item
              className="u-mb-small"
              icon="clock"
              iconMeaning="Not included:"
            >
              {formatToTime(selectedDates?.startDate)}
            </IconList.Item>
          </IconList>
        </Layout.Item>
        <Layout.Item>
          <h6 className="u-mb-small">Devolución</h6>
        </Layout.Item>
        <Layout.Item default="1/2">
          <IconList>
            <IconList.Item
              className="u-mb-small"
              icon="calendar"
              iconMeaning="Not included:"
            >
              {formatToDate(selectedDates?.endDate)}
            </IconList.Item>
          </IconList>
        </Layout.Item>
        <Layout.Item default="1/2">
          <IconList>
            <IconList.Item
              className="u-mb-small"
              icon="clock"
              iconMeaning="Not included:"
            >
              {formatToTime(selectedDates?.endDate)}
            </IconList.Item>
          </IconList>
        </Layout.Item>
        {!selectedVehicle && (
          <Layout.Item className="u-mt-xsmall">
            <Link
              small="true"
              to={() => {}}
              icon="semantic-back"
              onClick={() => dispatch(resetVehicleAvailables())}
            >
              Modificar
            </Link>
          </Layout.Item>
        )}
      </Layout>
      {selectedVehicle && (
        <Layout flush>
          <Layout.Item>
            {selectedVehicle.images && (
              <Thumbnail
                src={renderAppImage(imagePath, selectedVehicle.images[0])}
                alt={selectedVehicle.family}
              ></Thumbnail>
            )}

            <VehicleInfoIcons vehicle={selectedVehicle}></VehicleInfoIcons>
            <h6 className="u-mt-small u-mb-small u-text-center">
              {selectedVehicle.family}
            </h6>
          </Layout.Item>
          <Layout.Item>
            <ProductTable
              summary={
                <DescriptionList split>
                  <DescriptionList.Group
                    termClassName="c-product-table__summary-term"
                    termText={
                      <>
                        <h6 className="u-m-none">Precio total</h6>
                        <span
                          style={{ fontWeight: "normal" }}
                          className="u-m-none u-text-fs-2"
                        >
                          IVA incluido
                        </span>
                      </>
                    }
                  >
                    <DescriptionList.Detail className="c-product-table__summary-detail">
                      {financialFormat(Math.round(total * 100))} €
                    </DescriptionList.Detail>
                  </DescriptionList.Group>
                </DescriptionList>
              }
            >
              <ProductTable.Section>
                <DescriptionList split>
                  <DescriptionList.Group
                    termText={
                      <>
                        <h6 className="u-mt-none u-mb-xsmall">
                          Tarifa de alquiler
                        </h6>
                      </>
                    }
                  >
                    <del
                      hidden={
                        (selectedVehicle.totalWithInPromotionalCode.hasValue &&
                          selectedVehicle.totalWithInPromotionalCode === 0) ||
                        !selectedVehicle.totalWithInPromotionalCode
                      }
                    >
                      {financialFormat(
                        Math.round(
                          selectedVehicle.totalWithInPromotionalCode * 100
                        )
                      )}
                      €{" "}
                    </del>
                    <span
                      className="c-description-list__term"
                      hidden={
                        (selectedVehicle.totalWithInPromotionalCode.hasValue &&
                          selectedVehicle.totalWithInPromotionalCode === 0) ||
                        !selectedVehicle.totalWithInPromotionalCode
                      }
                    >
                      Precio con descuento promocional (
                      {selectedVehicle.promotionalCodeAdjust}%)
                    </span>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {financialFormat(Math.round(selectedVehicle.total * 100))}{" "}
                      €
                    </span>
                  </DescriptionList.Group>

                  <DescriptionList.Group>
                    <DescriptionList.Detail>
                      {financialFormat(
                        Math.round(selectedVehicle.totalByDay * 100)
                      )}{" "}
                      €/Día (
                      {getRoundDaysBetweenDates(
                        selectedDates.startDate,
                        selectedDates.endDate,
                        true,
                        selectedVehicle.timeUnitId
                      )}
                      )
                    </DescriptionList.Detail>
                  </DescriptionList.Group>
                </DescriptionList>
              </ProductTable.Section>
              <ProductTable.Section>
                <DescriptionList split>
                  <DescriptionList.Group
                    termText={
                      <>
                        <h6 className="u-mt-none u-mb-none"></h6>
                      </>
                    }
                  ></DescriptionList.Group>
                  <DescriptionList.Group
                    termText={`En todo el viaje (${getRoundDaysBetweenDates(
                      selectedDates.startDate,
                      selectedDates.endDate,
                      true,
                      selectedVehicle.timeUnitId
                    )})`}
                  ></DescriptionList.Group>
                  {!selectedBigger25 && (
                    <DescriptionList.Group
                      termText={"Suplemento menor de 25 años"}
                    >
                      <DescriptionList.Detail>
                        {financialFormat(Math.round(menor25Total * 100))} €
                      </DescriptionList.Detail>
                    </DescriptionList.Group>
                  )}
                  {selectedCoverage && (
                    <DescriptionList.Group termText={selectedCoverage.name}>
                      <DescriptionList.Detail>
                        {financialFormat(
                          Math.round(selectedCoverage.total * 100)
                        )}{" "}
                        €
                      </DescriptionList.Detail>
                    </DescriptionList.Group>
                  )}
                  {selectedAdditionalDriver && (
                    <DescriptionList.Group
                      termText={selectedAdditionalDriver.name}
                    >
                      <DescriptionList.Detail>
                        {financialFormat(
                          Math.round(selectedAdditionalDriver.total * 100)
                        )}{" "}
                        €
                      </DescriptionList.Detail>
                    </DescriptionList.Group>
                  )}
                </DescriptionList>
              </ProductTable.Section>
              {selectedExtras && selectedExtras.length > 0 && (
                <ProductTable.Section>
                  <DescriptionList split>
                    <DescriptionList.Group
                      termText={
                        <>
                          <h6 className="u-mt-none u-mb-none">Accesorios</h6>
                        </>
                      }
                    ></DescriptionList.Group>
                    {selectedExtras.map((extra) => {
                      const { id, name, total, amount } = extra;
                      return (
                        <DescriptionList.Group
                          key={id}
                          termText={
                            <>
                              {name}
                              {!isContactInfo && (
                                <LessMoreExtraButton
                                  extra={extra}
                                ></LessMoreExtraButton>
                              )}
                            </>
                          }
                        >
                          <DescriptionList.Detail>
                            {financialFormat(Math.round(total * amount * 100))}{" "}
                            €
                          </DescriptionList.Detail>
                        </DescriptionList.Group>
                      );
                    })}
                  </DescriptionList>
                </ProductTable.Section>
              )}
              <ProductTable.Section>
                <DescriptionList split>
                  <DescriptionList.Group
                    termText={
                      <>
                        <h6 className="u-mt-none u-mb-xsmall">Subtotal</h6>
                      </>
                    }
                  >
                    <DescriptionList.Detail>
                      {financialFormat(Math.round(subTotal * 100))} €
                    </DescriptionList.Detail>
                  </DescriptionList.Group>
                </DescriptionList>
              </ProductTable.Section>
              <ProductTable.Section>
                <DescriptionList split>
                  <DescriptionList.Group
                    termText={
                      <>
                        <h6 className="u-mt-none u-mb-xsmall">Impuestos</h6>
                      </>
                    }
                  >
                    <DescriptionList.Detail>
                      {financialFormat(Math.round(taxesTotal * 100))} €
                    </DescriptionList.Detail>
                  </DescriptionList.Group>
                </DescriptionList>
              </ProductTable.Section>
            </ProductTable>
          </Layout.Item>
        </Layout>
      )}
    </div>
  );
};
