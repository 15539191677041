import React, { useRef, useState } from "react";

import { Spinner } from "@vwfs-bronson/bronson-react";

export const Thumbnail = ({ src, alt = "", className = "", aspectRatio = null, objectFit=null }) => {
    const imgEl = useRef(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const onImageLoaded = () => setLoaded(true);
    const onImageFailed = () => {
        setError(true);
        setLoaded(true);
    };

    React.useEffect(() => {
        const imgElCurrent = imgEl.current;

        if (imgElCurrent && src.startsWith("http")) {
            imgElCurrent.addEventListener("load", onImageLoaded);
            imgElCurrent.addEventListener("error", onImageFailed);
            return () => {
                imgElCurrent.removeEventListener("load", onImageLoaded);
                imgElCurrent.removeEventListener("error", onImageFailed);
            };
        }
    }, [imgEl, src]);

    return (
        <div className={`u-mb-small ${className}`}>
            {!loaded && <Spinner center></Spinner>}
            {!error && (
                <img
                    ref={imgEl}
                    src={src}
                    alt={alt}
                    style={
                        loaded
                            ? { display: "block", textAlign: "center", margin: "0 auto", aspectRatio: aspectRatio, objectFit:objectFit }
                            : { display: "none" }
                    }
                />
            )}
            {error && <p className="u-font-size-fs-3 u-text-center">Error al obtener la imagen</p>}
        </div>
    );
};
