import React from "react";

import { Layout, Button, Card } from "@vwfs-bronson/bronson-react";
import { Thumbnail } from "../Thumbnail";
import { renderAppImage } from "../../helpers/renderAppImage";
import { useGlobal } from "../../hooks/useGlobal";
import { financialFormat } from "../../helpers/financialFormat";

export const VehicleFamilies = ({ familiesAvailables, setSelectedFamily }) => {
  const { imagePath } = useGlobal();

  return familiesAvailables.map((family) => {
    const { vehicles, name } = family;

    return (
      <Layout.Item
        key={"Families_" + name}
        default={"1/2"}
        l={"1/2"}
        m="1/1"
        s={"1/1"}
      >
        <Card element="article" className={"u-mb-small"} small>
          <>
            <Layout flush>
              <Layout.Item default="3/5">
                <h6 className="u-mb-small">{name}</h6>
              </Layout.Item>
              <Layout.Item default="2/5">
                <del
                  hidden={
                    (vehicles[0].totalWithInPromotionalCode.hasValue &&
                      vehicles[0].totalWithInPromotionalCode === 0) ||
                    !vehicles[0].totalWithInPromotionalCode
                  }
                >
                  <h6 className="u-font-size-fs-1 u-text-right u-mb-none">
                    {financialFormat(
                      Math.round(
                        vehicles[0].totalWithInPromotionalCodeByDay * 100
                      )
                    )}{" "}
                    € / día
                  </h6>
                </del>
                <h6 className="u-font-size-fs-1 u-text-right u-mb-none">
                  {financialFormat(Math.round(vehicles[0].totalByDay * 100))} €
                  / día
                </h6>
              </Layout.Item>
              <Layout.Item className={"u-text-center"}>
                <Thumbnail
                  src={renderAppImage(imagePath, vehicles[0].images[0])}
                  alt={name}
                  aspectRatio="2 / 1"
                  objectFit="scale-down"
                ></Thumbnail>
              </Layout.Item>
              <Layout.Item>
                <Button
                  data-tr-component="Button Link"
                  data-tr-function="Continue"
                  icon="semantic-forward"
                  iconReversed
                  link
                  small
                  onClick={() => setSelectedFamily(family)}
                >
                  Ver {vehicles.length} vehículo
                  {vehicles.length > 1 && "s"} disponible
                  {vehicles.length > 1 && "s"}
                </Button>
              </Layout.Item>
            </Layout>
          </>
        </Card>
      </Layout.Item>
    );
  });
};
