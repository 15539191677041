import React from "react";

import { HeroTeaser, Main, ContentSection, Wrapper } from "@vwfs-bronson/bronson-react";

export const PrivacyPolicyPage = () => {
    return (
        <>
            <HeroTeaser title={[<>Política de Privacidad</>]} shallow auto noImage />
            <Main>
                <ContentSection pageWrapSize="medium">
                    <Wrapper>
                        <div style={{ textAlign: "justify" }}>
                            <p>
                                La protección de datos es fundamental para el grupo Volkswagen Financial Services. A
                                efectos de lo dispuesto en la normativa vigente en materia de protección de datos de
                                carácter personal, mediante esta Política de Privacidad, Volkswagen Renting S.A. (en
                                adelante, VWFS) informa a los usuarios de su web{" "}
                                <a href="https://rentacar.vwfs.es">https://rentacar.vwfs.es</a> (en adelante, la “Web”),
                                de la que es titular y responsable, acerca de su política de protección de los datos de
                                carácter personal recogidos de los Usuarios.
                            </p>
                            <p>
                                Los datos personales que VWFS recaba se tratan de manera{" "}
                                <strong>lícita y transparente</strong>, es decir, cumpliendo íntegramente la normativa
                                en materia de protección de datos, de forma leal y clara hacia el cliente.
                            </p>
                            <p>
                                Además, y como se ha venido realizando hasta ahora, para aquellas finalidades que sean
                                ajenas al contrato o su solicitud, solicitaremos su <strong>consentimiento</strong>,
                                garantizando asimismo que éste pueda ser revocado en cualquier momento.
                            </p>
                            <p>
                                Las finalidades para las que trataremos los datos se recogerán siempre para{" "}
                                <strong>fines determinados, explícitos y legítimos</strong>, en los términos en que les
                                informamos cuando nos facilita sus datos, no tratándolos para otras finalidades ajenas a
                                las necesarias.
                            </p>
                            <p>
                                Únicamente se solicitan los datos que son{" "}
                                <strong>adecuados, pertinentes y limitados</strong> a los necesarios en relación con el
                                fin para el que se solicitan, de modo que no recabaremos información adicional a la
                                necesaria, manteniendo sus datos personales únicamente durante el tiempo que sea
                                necesario para los fines del tratamiento o durante el plazo que nos haya autorizado.
                            </p>
                            <p>
                                Una adecuada política de protección de datos debe conducir a que los datos personales se
                                traten de manera que se garantice una <strong>seguridad adecuada</strong>. VWFS aplica
                                las medidas legales, técnicas y organizativas apropiadas en función del riesgo
                                analizado, garantizando en todo caso la confidencialidad, integridad, disponibilidad y
                                resiliencia de los sistemas y servicios.
                            </p>
                            <p>
                                Por último, VWFS contrata parte de su infraestructura virtual según un modelo de
                                computación en la nube a través de Salesforce.com, Inc, entidad domiciliada en The
                                Landmark @ One market Street, San Francisco CA 94105, Estados Unidos de América siendo
                                la garantía que ampara dicha transferencia internacional las cláusulas contractuales
                                tipo. En los demás supuestos, las transferencias internacionales podrán ampararse
                                también en normas corporativas vinculantes u otros mecanismos de transferencia válidos,
                                según corresponda.
                            </p>
                            <p>En todo caso, VWFS le informa que tiene derecho:</p>
                            <ul>
                                <li>
                                    A acceder a sus datos personales y obtener confirmación sobre cómo se están tratando
                                    dichos datos.
                                </li>
                                <li>
                                    A rectificar los datos inexactos o, solicitar su supresión cuando, entre otros
                                    motivos, los datos ya no sean necesarios para los fines que hayan sido recabados por
                                    VWFS.
                                </li>
                                <li>
                                    En determinadas circunstancias, podrá solicitar la limitación del tratamiento de sus
                                    datos, en cuyo caso VWFS únicamente los conservará para el ejercicio o la defensa de
                                    las posibles reclamaciones.
                                </li>
                                <li>
                                    También en determinadas circunstancias, usted podrá oponerse al tratamiento de sus
                                    datos personales con la finalidad informada por VWFS. En ese caso, VWFS cesará en el
                                    tratamiento de los datos personales, salvo que concurran motivos legítimos, o para
                                    garantizar el ejercicio o la defensa de posibles reclamaciones.
                                </li>
                                <li>
                                    Por último, usted podrá solicitar el derecho a la portabilidad y obtener para sí
                                    mismo o para otro prestador de servicios determinada información derivada de la
                                    relación contractual formalizada con VWFS.
                                </li>
                            </ul>
                            <p>
                                Puede ejercer los derechos anteriormente descritos, ante la entidad del grupo VWFS que
                                en cada caso haya recabado sus datos, mediante solicitud escrita en las direcciones
                                postales y/o de correo electrónico que en cada supuesto se le hayan facilitado, y en
                                todo caso en las siguientes, en función de cuál haya sido la sociedad del grupo VWFS que
                                ha recabado sus datos:
                            </p>
                            <p>
                                <strong>Volkswagen Renting S.A.</strong>
                            </p>
                            <p>
                                <strong>Dirección:</strong> Avda. Bruselas 34 – 28108 Alcobendas (Madrid)
                                <br />
                                <strong>CIF:</strong> A80/185051
                                <br />
                                <strong>Correo electrónico:</strong>{" "}
                                <a href="mailto:clientes.renting@vwfs.com">clientes.renting@vwfs.com</a>
                                <br />
                                <strong>Telf:</strong> 91 427 9903
                                <br />
                                <strong>Datos mercantiles:</strong> Registro Mercantil de la provincia de Madrid. Tomo
                                2.138. Folio 63. Hoja nº M-37860. Inscripción 1ª
                            </p>
                            <p>
                                Para la adecuada gestión en el tratamiento de sus datos personales, VWFS tiene designado
                                un Delegado de Protección de Datos, al que podrá contactar para resolver cualquier
                                cuestión que precise al respecto, a través del correo electrónico{" "}
                                <a href="mailto:DPO.es@vwfs.com">DPO.es@vwfs.com</a>.
                            </p>
                        </div>
                    </Wrapper>
                </ContentSection>
            </Main>
        </>
    );
};
