import React, { useEffect } from "react";

import { Card, Layout, Button } from "@vwfs-bronson/bronson-react";
import { useDispatch } from "react-redux";
import { useOptional } from "../../hooks/useOptional";
import { financialFormat } from "../../helpers/financialFormat";
import { useSummary } from "../../hooks/useSummary";
import { setCoverageSelected } from "../../actions/summaryAction";
import { showCoverageInfo } from "../../helpers/showCoverageInfo";

export const Coverages = () => {
    const dispatch = useDispatch();

    const { coverages } = useOptional();
    const { selectedCoverage } = useSummary();

    useEffect(() => {
        var arr = coverages.filter(ci=>ci.included==true);
        if(arr[0]){
            let coverageItem = arr[0];
            dispatch(
                setCoverageSelected({
                    ...coverageItem,
                    name: showCoverageInfo(coverageItem.coverage, "name"),
                    text: showCoverageInfo(coverageItem.coverage, "text"),
                }));
        }
      }, [coverages]);

    return (
        <Layout equalHeight>
            {coverages.map((coverageItem) => {
                const { coverage, total, totalByDay } = coverageItem;
                return (
                    <Layout.Item key={`Coverage_${coverage}`} default={"1/2"} l="1/1">
                        <Card element="article" className="u-text-left">
                            <h5>{showCoverageInfo(coverage, "name")}</h5>
                            <p>{showCoverageInfo(coverage, "text")}</p>
                            <hr className="u-mb-small" />
                            <Layout flush>
                                <Layout.Item default="1/2">
                                    <h5 className="u-m-none">{financialFormat(Math.round(totalByDay * 100))} €/día</h5>
                                    <p className="u-m-none">Total: {financialFormat(Math.round(total * 100))}</p>
                                </Layout.Item>
                                <Layout.Item default="1/2" className="u-text-right">
                                    {selectedCoverage?.coverage !== coverage ? (
                                        <Button
                                            icon="semantic-add"
                                            icononly="true"
                                            onClick={() =>
                                                dispatch(
                                                    setCoverageSelected({
                                                        ...coverageItem,
                                                        name: showCoverageInfo(coverage, "name"),
                                                        text: showCoverageInfo(coverage, "text"),
                                                    })
                                                )
                                            }
                                            secondary
                                        ></Button>
                                    ) : (
                                        <Button icon="semantic-checkmark" icononly="true"></Button>
                                    )}
                                </Layout.Item>
                            </Layout>
                        </Card>
                    </Layout.Item>
                );
            })}
        </Layout>
    );
};
