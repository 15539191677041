/** @format */

import {
  BackToTop,
  Heading,
  HeroTeaser,
  Layout,
  OrderedList,
  PageWrap,
  Paragraph,
  Table,
  UnorderedList,
  Button,
} from "@vwfs-bronson/bronson-react";
import { useContext } from "react";
import CookiesContext from "../context/CookiesContextProvider";
import { CookiesGenericModal } from "../components/UI/Cookies/CookiesGeneric/CookiesGenericModal";

export const CookiesPolicyPage = () => {
  const { showConfig, setShowConfig } = useContext(CookiesContext);

  return (
    <>
      <HeroTeaser title={[<>Política de Cookies</>]} shallow auto noImage />
      <PageWrap size="small">
        <Layout className="u-mt">
          <Layout.Item>
            Con la finalidad de poder ofrecer nuestros servicios, analizar los
            hábitos de navegación de los usuarios y obtener información
            estadística sobre la navegación efectuada, VWFS utiliza
            &ldquo;cookies&ldquo; en sus páginas web.
          </Layout.Item>
          <Layout.Item>
            <Heading level={5}>¿Qué son las “cookies”?</Heading>
            <Heading level={6}>Introducción</Heading>
            <Paragraph>
              Una cookie es un fichero que se descarga en su ordenador al
              acceder a determinadas páginas web. Las cookies permiten a una
              página web, entre otras cosas, almacenar y recuperar información
              sobre los hábitos de navegación de un usuario o de su equipo, en
              este caso, de forma anónima. Se pueden utilizar también para medir
              la audiencia, parámetros del tráfico y navegación, tiempo de
              sesión, y/o controlar el progreso y el número de entradas.
            </Paragraph>
            <Paragraph>
              Como se ha indicado, las cookies ofrecen información sobre los
              hábitos de navegación de los usuarios y en consecuencia pueden
              afectar a la privacidad de los mismos, por este motivo, queremos
              ofrecerte la información necesaria sobre la tipología de cookies
              utilizada en nuestra página web, para que tengas plena capacidad
              de decisión en relación al uso de las mismas y en relación a la
              protección de tu privacidad.
            </Paragraph>
            <Paragraph>
              En este sentido, te informamos de que para poder utilizar la
              totalidad de servicios y funcionalidades de la web es necesario
              habilitar las cookies en el navegador de Internet.
            </Paragraph>
          </Layout.Item>
          <Layout.Item>
            <Heading level={5}>¿Qué tipos de “cookies” utilizamos?</Heading>
            <Heading level={6}>Cookies propias</Heading>
            <OrderedList>
              <OrderedList.Item>
                <Heading level={6}>Cookies necesarias (técnicas)</Heading>
                <Paragraph>
                  Son aquellas que permiten la navegación a través de Web y la
                  utilización de las diferentes opciones o servicios que en ella
                  existan, incluyendo aquellas que VWFS pueda utilizar para
                  permitir la gestión y operativa de la Web y habilitar sus
                  funciones y servicios, como, por ejemplo, controlar el tráfico
                  y la comunicación de datos, identificar la sesión, recordar
                  los elementos que integran una compra, realizar el proceso de
                  compra, gestionar el pago o controlar el fraude vinculado a la
                  seguridad del servicio. También se incluyen, por su naturaleza
                  técnica, aquellas cookies que permiten la gestión, de la forma
                  más eficaz posible, de los espacios publicitarios que, como un
                  elemento más de diseño o “maquetación” del servicio ofrecido
                  al usuario, sin que se recopile información de los usuarios
                  con fines distintos, como puede ser personalizar ese contenido
                  publicitario u otros contenidos.
                </Paragraph>
              </OrderedList.Item>
              <OrderedList.Item>
                <Heading level={6}>Cookies de personalización</Heading>
                <Paragraph>
                  Son aquellas que permiten recordar información para que el
                  usuario acceda al servicio con determinadas características
                  que pueden diferenciar su experiencia de la de otros usuarios,
                  como, por ejemplo, el idioma, el número de resultados a
                  mostrar cuando el usuario realiza una búsqueda, el aspecto o
                  contenido del servicio en función del tipo de navegador a
                  través del cual el usuario accede al servicio o de la región
                  desde la que accede al servicio, etc.
                </Paragraph>
              </OrderedList.Item>
              <OrderedList.Item>
                <Heading level={6}>Cookies de análisis</Heading>
                <Paragraph>
                  Sirven para analizar los hábitos de navegación de los
                  usuarios, con el objetivo de optimizar las funcionalidades y
                  el mantenimiento técnico y operativo de las webs y portales de
                  VWFS y garantizar el mejor servicio posible. Nunca se
                  utilizarán para mostrar anuncios personalizados, ni para
                  recordar las preferencias del usuario más allá de la sesión.
                </Paragraph>
                <Paragraph>
                  Perfil tecnológico del dispositivo, origen del acceso a la
                  web, actividad de navegación y términos de búsqueda
                  utilizados.
                </Paragraph>
              </OrderedList.Item>
              <OrderedList.Item>
                <Heading level={6}>Cookies de publicidad de marketing</Heading>
                <Paragraph>
                  Son aquellas que permiten almacenar información del
                  comportamiento de los usuarios obtenida a través de la
                  observación continuada de sus hábitos de navegación, lo que
                  permite desarrollar un perfil específico de usuario. De esta
                  manera, se utilizan para personalizar las campañas
                  publicitarias que se ajusten a las preferencias del usuario.
                </Paragraph>
              </OrderedList.Item>
            </OrderedList>
          </Layout.Item>
          <Layout.Item>
            <Heading level={5}>Cookies de terceros</Heading>
            <Paragraph>
              <b>Las cookies de terceros</b> son las administradas por
              prestadores de servicios técnicos, de análisis, redes sociales, de
              personalización y de publicidad (incluida publicidad
              comportamental) ajenos a VWFS.
            </Paragraph>
            <Heading level={6}>Cookies de publicidad</Heading>
            <Paragraph>
              En determinadas ocasiones podemos ofrecer a través de nuestra
              página Web contenidos de terceros, por ejemplo, en la red de
              display de google. Éstos terceros pueden instalar cookies que
              escapan de nuestro control.
            </Paragraph>
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Nombre</Table.Th>
                  <Table.Th>Finalidad</Table.Th>
                  <Table.Th>Caducidad</Table.Th>
                  <Table.Th>Titular</Table.Th>
                  <Table.Th>Finalidad</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td>adobeujs-optin</Table.Td>
                  <Table.Td>
                    Esta cookie se instala cuando el usuario accepta la
                    configuración de las cookies
                  </Table.Td>
                  <Table.Td>1 año</Table.Td>
                  <Table.Td>VWFS</Table.Td>
                  <Table.Td>Necesarias</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Cookie Control</Table.Td>
                  <Table.Td>
                    Stores Civic Cookies Consenimiento dado por parte del
                    usuario
                  </Table.Td>
                  <Table.Td>2 meses</Table.Td>
                  <Table.Td>VWFS</Table.Td>
                  <Table.Td>Necesarias</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Cookie Policy</Table.Td>
                  <Table.Td>
                    Se instala cuando un usuario acepta la utilización de
                    cookies
                  </Table.Td>
                  <Table.Td>1 año</Table.Td>
                  <Table.Td>VWFS</Table.Td>
                  <Table.Td>Necesarias</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>AMCV_###@AdobeOrg</Table.Td>
                  <Table.Td>
                    Unique visitor IDs used by Experience Cloud Solutions
                  </Table.Td>
                  <Table.Td>2 años</Table.Td>
                  <Table.Td>Experience Cloud Solutions</Table.Td>
                  <Table.Td>Analiticas</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Demdex</Table.Td>
                  <Table.Td>
                    Audience Manager instala esta cookie para asignar un único
                    ID a un visitante del sitio. Esta cookie demdex ayuda a
                    Audience Manager a llevar a cabo diferentes funciones tales
                    como la identificación del visitante, la sincronización de
                    ID, la segmentación, el modelado, la creación de informes,
                    etc.
                  </Table.Td>
                  <Table.Td>180 dias</Table.Td>
                  <Table.Td>Audience Manager</Table.Td>
                  <Table.Td>Analiticas</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>dextp</Table.Td>
                  <Table.Td>
                    Audience Manager instala esta cookie para registrar el
                    último momento en el que realizó una petición de
                    sincronización de datos.
                  </Table.Td>
                  <Table.Td>180 días</Table.Td>
                  <Table.Td>Audience Manager</Table.Td>
                  <Table.Td>Analiticas</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>s_cc</Table.Td>
                  <Table.Td>
                    Esta cookie, establecida por el código de Javascript,
                    determina si se han habilitado las cookies en la
                    configuración del navegador del usuario.
                  </Table.Td>
                  <Table.Td>Sessión</Table.Td>
                  <Table.Td>VWFS</Table.Td>
                  <Table.Td>Analiticas</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>s_sq</Table.Td>
                  <Table.Td>
                    Esta cookie establecida por el código de Javascript,
                    contiene información sobre los vínculos anteriores de las
                    páginas en las que el usuario hizo clic. Esta información se
                    utiliza para compilar un ClickMap. Un ClickMap de Adobe
                    Analytics es una representación gráfica del número total de
                    clics de un usuario en un sitio.
                  </Table.Td>
                  <Table.Td>Sessión</Table.Td>
                  <Table.Td>VWFS</Table.Td>
                  <Table.Td>Analiticas</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>mbox</Table.Td>
                  <Table.Td>Adobe Target Cookie.</Table.Td>
                  <Table.Td>2 años</Table.Td>
                  <Table.Td>Adobe Target</Table.Td>
                  <Table.Td>Personalización</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>check</Table.Td>
                  <Table.Td>
                    Esta cookie es de Adobe Target Cookie y comprueba si las
                    cookies pueden estar establecidas o no.
                  </Table.Td>
                  <Table.Td>Sessión</Table.Td>
                  <Table.Td>Adobe Target</Table.Td>
                  <Table.Td>Personalización</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>at_check</Table.Td>
                  <Table.Td>
                    Adobe Target Cookie para verificar si las cookies se pueden
                    configurar (reemplace la cookie &ldquo;check&ldquo; en las
                    versiones actualizadas de Adobe Target).
                  </Table.Td>
                  <Table.Td>Sessión</Table.Td>
                  <Table.Td>Adobe Target</Table.Td>
                  <Table.Td>Personalización</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Facebook Pixel Code</Table.Td>
                  <Table.Td>
                    Cookie para medir y optimizar campañas publicitarias en base
                    a las visitas de un usuario y las conversiones en la landing
                    page.
                  </Table.Td>
                  <Table.Td>180 días</Table.Td>
                  <Table.Td>Facebook</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Insight tag</Table.Td>
                  <Table.Td>
                    Cookie para medir y optimizar campañas publicitarias en base
                    a las visitas de un usuario y las conversiones en la landing
                    page en LinkedIn.
                  </Table.Td>
                  <Table.Td>90 días</Table.Td>
                  <Table.Td>LinkedIn</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>doubleclick.net / google.com</Table.Td>
                  <Table.Td>
                    Cookie para medir y optimizar la actividad publicitaria de
                    una campaña en base a las visitas de un usuario y las
                    conversiones en la landing page en google campaign manager
                    (Adserver).
                  </Table.Td>
                  <Table.Td>90 días</Table.Td>
                  <Table.Td>Google Campaign Manager (Adserver)</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>ev_sync_*</Table.Td>
                  <Table.Td>
                    Cookie de terceros, que sincroniza el ad exchange con otras
                    cookies que sincronizan el Advertising Cloud surfer ID con
                    el proveedor del ad exchange. Se crea para navegadores
                    nuevos y envía una solicitud de sincronización cuando se
                    expira.
                  </Table.Td>
                  <Table.Td>1 mes</Table.Td>
                  <Table.Td>Adobe Marketing Cloud</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>_lcc</Table.Td>
                  <Table.Td>
                    Cookie de terceros que sirve para determinar si un evento
                    &ldquo;click&ldquo; en un anuncio de display se refleja en
                    un evento de Adobe Analytics
                  </Table.Td>
                  <Table.Td>15 minutos</Table.Td>
                  <Table.Td>Adobe Marketing Cloud</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>_tmae</Table.Td>
                  <Table.Td>
                    Una cookie de terceros que almacena interacciones de los
                    usuarios con anuncios, como &ldquo;último anuncio visto
                    xyz123 el día 30 de junio de 2018&ldquo;
                  </Table.Td>
                  <Table.Td>1 año</Table.Td>
                  <Table.Td>Adobe Marketing Cloud</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>everest_g_v2</Table.Td>
                  <Table.Td>
                    Una cookie que se crea después que un usuario clica en una
                    publicidad, y por lo tanto se utiliza para mapear los clics
                    actuales y futuros con otros eventos en la página web del
                    cliente.
                  </Table.Td>
                  <Table.Td>2 años</Table.Td>
                  <Table.Td>Adobe Marketing Cloud</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Everest_session_v2</Table.Td>
                  <Table.Td>
                    Cookie de terceros del navegador. Una misma cookie se
                    utiliza para todas las cuentas.
                  </Table.Td>
                  <Table.Td>Sessión</Table.Td>
                  <Table.Td>Adobe Marketing Cloud</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>adcloud</Table.Td>
                  <Table.Td>
                    Una cookie de origen que asocia el ID del internauta con
                    segmentos de audiencia y conversiones relevantes La
                    información sobre la última visita se utiliza para optimizar
                    los tiempos de carga de la página al evitar solicitudes
                    innecesarias a los servidores de Adobe. La información sobre
                    el último clic de búsqueda ayuda a determinar si un evento
                    de conversión fue el resultado de un clic o una
                    visualización (conversión que resulta en impresiones pero no
                    en clics).
                  </Table.Td>
                  <Table.Td>2 años</Table.Td>
                  <Table.Td>Adobe Marketing Cloud</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>s_ecid</Table.Td>
                  <Table.Td>
                    El dominio del cliente establece esta cookie después de que
                    el cliente establezca la cookie AMCV. El propósito de esta
                    cookie es permitir el seguimiento de ID persistentes en el
                    estado individual y se utiliza como ID de referencia si la
                    cookie AMCV ha caducado. Para obtener más detalles, consulte
                    la cookie AMCV aquí.
                  </Table.Td>
                  <Table.Td>2 años</Table.Td>
                  <Table.Td>VWFS</Table.Td>
                  <Table.Td>Analiticas</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>s_vi</Table.Td>
                  <Table.Td>
                    Esta cookie identifica a los usuarios determinando el
                    identificador de “visitante único” y la marca de fecha y
                    hora. De este modo, Adobe Analytics puede identificar un
                    usuario en visitas posteriores.
                  </Table.Td>
                  <Table.Td>2 años</Table.Td>
                  <Table.Td>Adobe Marketing Cloud</Table.Td>
                  <Table.Td>Analiticas</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>s_fid</Table.Td>
                  <Table.Td>
                    Esta cookie identifica a los usuarios determinando el
                    identificador de “visitante único”. Se trata de una cookie
                    de respaldo, en el caso de que la cookie s_vi, usada
                    normalmente con ese propósito, estuviera bloqueada.
                  </Table.Td>
                  <Table.Td>2 años</Table.Td>
                  <Table.Td>Adobe Marketing Cloud</Table.Td>
                  <Table.Td>Analiticas</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>ANID</Table.Td>
                  <Table.Td>
                    Google utiliza cookies para la publicidad que mostramos en
                    algunos espacios de nuestra web.
                  </Table.Td>
                  <Table.Td>2 años</Table.Td>
                  <Table.Td>Google</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    SIDCC
                    <br />
                    SID
                    <br />
                    __Secure-3PAPISID
                    <br />
                    __Secure-APISID
                    <br />
                    __Secure-SSID
                    <br />
                    __Secure-HSID
                    <br />
                    APISID
                    <br />
                    SSID
                    <br />
                    HSID __Secure-3PSID
                    <br />
                    SAPISID
                    <br />
                    DSID
                  </Table.Td>
                  <Table.Td>
                    La identificación de la cuenta de Google de un usuario y el
                    último inicio de sesión quedan registrados y encriptados
                    digitalmente. La combinación de estas dos cookies permite a
                    Google bloquear distintos tipos de ataques; por ejemplo, los
                    intentos de robar información de los formularios que el
                    usuario completa en las páginas web.
                  </Table.Td>
                  <Table.Td>Durante la sesión</Table.Td>
                  <Table.Td>Google</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>CONSENTIMIENTO</Table.Td>
                  <Table.Td>
                    Configuración del consentimiento de cookies. Puede provenir
                    de diferentes productos, como una Cuenta de Google, YouTube,
                    o Maps. Si la cookie está vinculada a una cuenta de Google,
                    permanecerá activa durante 2 años. Si no hay ninguna cuenta
                    de Google vinculada, la cookie solo permanece hasta que se
                    cierra la sesión.
                  </Table.Td>
                  <Table.Td>
                    <b>Durante la sesión</b> (si no se vincula a una cuenta de
                    Google)
                    <b>2 años</b> (si se inicia session en una cuenta de Google)
                  </Table.Td>
                  <Table.Td>Google</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>IDE</Table.Td>
                  <Table.Td>
                    Google también usa cookies para los anuncios que aparecen en
                    varios puntos de nuestra web. La cookie más importante de
                    Google para las preferencias publicitarias para páginas que
                    no son de Google se llama &ldquo;IDE&ldquo;.
                  </Table.Td>
                  <Table.Td>Durante la sesión</Table.Td>
                  <Table.Td>Google</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>NID</Table.Td>
                  <Table.Td>
                    La cookie NID contiene una identificación única que Google
                    utiliza para almacenar la configuración preferida del
                    usuario, entre otros datos. Por ejemplo, las preferencias de
                    idioma, cuántos resultados de búsqueda desea que se muestren
                    por página (por ejemplo, 10 o 20), o si quiere habilitar el
                    filtro de Google SafeSearch (búsqueda segura, sin contenidos
                    explícitos).
                  </Table.Td>
                  <Table.Td>6 semanas</Table.Td>
                  <Table.Td>Google</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>1P_JAR</Table.Td>
                  <Table.Td>
                    Esta cookie se establece cuando el usuario visita google.com
                    y almacena configuraciones como su región o idioma.
                  </Table.Td>
                  <Table.Td>7 días</Table.Td>
                  <Table.Td>Google</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>ID</Table.Td>
                  <Table.Td>
                    Es utilizada por Google para algunos de sus propios
                    productos, y se describe{" "}
                    <a href="https://policies.google.com/technologies/cookies?hl=en#types-of-cookies">
                      aquí
                    </a>
                    , debajo de la publicidad.
                  </Table.Td>
                  <Table.Td>Durante la sesión</Table.Td>
                  <Table.Td>Google</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>OGPC</Table.Td>
                  <Table.Td>
                    OGPC registra si el usuario ha aceptado cookies para
                    productos de Google como Maps o Gmail.
                  </Table.Td>
                  <Table.Td>30 días</Table.Td>
                  <Table.Td>Google</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>OPT_OUT</Table.Td>
                  <Table.Td>
                    OPT_OUT registra si el usuario ha aceptado ver publicidad
                    basada en sus intereses de Google.
                  </Table.Td>
                  <Table.Td>30 días</Table.Td>
                  <Table.Td>Google</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>OTZ</Table.Td>
                  <Table.Td>
                    OTZ registra la zona horaria de cada usuario en Google.
                  </Table.Td>
                  <Table.Td>30 días</Table.Td>
                  <Table.Td>Google</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>S</Table.Td>
                  <Table.Td>
                    Para mejorar la experiencia de navegación de cada usuario,
                    Google utiliza las &ldquo;cookies de estado de
                    sesión&ldquo;, que ayudan a afinar sus servicios.
                  </Table.Td>
                  <Table.Td>Durante la sesión</Table.Td>
                  <Table.Td>Google</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Google tag</Table.Td>
                  <Table.Td>
                    Medición y optimización de la actividad publicitaria de una
                    campaña en base a las visitas de un usuario y las
                    conversiones en la landing page.
                  </Table.Td>
                  <Table.Td>2 años</Table.Td>
                  <Table.Td>Google Adwords</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Google tag</Table.Td>
                  <Table.Td>
                    Medición y optimización de la actividad publicitaria de una
                    campaña en base a las visitas de un usuario y las
                    conversiones en la landing page.
                  </Table.Td>
                  <Table.Td>540 días</Table.Td>
                  <Table.Td>Display & Video 360</Table.Td>
                  <Table.Td>Marketing</Table.Td>
                </Table.Tr>
              </Table.Tbody>
            </Table>
          </Layout.Item>
          <Layout.Item>
            <Heading level={5}>¿Cómo configurar tus cookies?</Heading>
            <Paragraph>
              Teniendo en cuenta la información facilitada a través de la
              presente Política de cookies, a continuación, podrás gestionar qué
              cookies permites instalar.
            </Paragraph>
            <Button small link onClick={() => setShowConfig(true)}>
              <u>Accede al panel</u>
            </Button>
          </Layout.Item>
          <Layout.Item>
            <Heading level={5}>
              Cómo visualizar las &ldquo;cookies&ldquo;
            </Heading>
            <Paragraph>
              Todos los navegadores ofrecen al usuario la posibilidad de buscar
              y visualizar las cookies que instala un determinado sitio web,
              obtener información relativa a la duración de las mismas y la
              posibilidad de eliminarlas, tal y como se indica en el siguiente
              apartado. A continuación, se muestran una serie de enlaces
              informativos sobre esta cuestión de los navegadores más
              utilizados:
            </Paragraph>
            <UnorderedList>
              <UnorderedList.Item>
                Google Chrome:
                <a href="https://support.google.com/chrome/answer/95647?hl=es">
                  https://support.google.com/chrome/answer/95647?hl=es
                </a>
              </UnorderedList.Item>
              <UnorderedList.Item>
                Mozilla Firefox:
                <a href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-">
                  https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-
                </a>
              </UnorderedList.Item>
              <UnorderedList.Item>
                Internet Explorer:
                <a href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-ininternet-explorer-9">
                  http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-ininternet-explorer-9
                </a>
              </UnorderedList.Item>
              <UnorderedList.Item>
                Safari:
                <a href="https://www.apple.com/legal/privacy/es/cookies/">
                  https://www.apple.com/legal/privacy/es/cookies/
                </a>
              </UnorderedList.Item>
            </UnorderedList>
          </Layout.Item>
          <Layout.Item>
            <Heading level={5}>Cómo eliminar las &ldquo;cookies&ldquo;</Heading>
            <Paragraph>
              Para restringir o bloquear las cookies, deberás modificar la
              configuración del navegador que utilices en tu terminal, ya sea
              ordenador, smartphone o tablet. Con carácter general, los
              navegadores ofrecen las siguientes opciones de configuración en
              relación con la instalación de cookies:
            </Paragraph>
            <OrderedList>
              <OrderedList.Item>
                Que el navegador rechace todas las cookies y, por lo tanto, que
                no se instale ninguna cookie de ninguna página web en tu
                terminal.
              </OrderedList.Item>
              <OrderedList.Item>
                Que el navegador te avise antes de que se produzca la
                instalación de la cookie para que decidas si aceptas o no la
                instalación de la misma.
              </OrderedList.Item>
              <OrderedList.Item>
                Que el navegador únicamente rechace las cookies de terceros de
                los sitios web que visita, pero no las utilizadas por el sitio
                web por el que navegas.
              </OrderedList.Item>
              <OrderedList.Item>
                La opción de navegación en modo privado mediante la cual las
                cookies se instalan en el terminal, pero se eliminan de manera
                automática cuando finalizas la navegación por la página web:
                <UnorderedList>
                  <UnorderedList.Item>
                    Google Chrome:
                    <a href="https://support.google.com/chrome/answer/95464?hl=es">
                      https://support.google.com/chrome/answer/95464?hl=es
                    </a>
                  </UnorderedList.Item>
                  <UnorderedList.Item>
                    Mozilla Firefox:
                    <a href="https://support.mozilla.org/es/kb/navegacion-privada-navega-en-laweb-sin-guardar-in?redirectlocale=enUS&as=u&redirectslug=Private+Browsing&utm_source=inproduct">
                      https://support.mozilla.org/es/kb/navegacion-privada-navega-en-laweb-sin-guardar-in?redirectlocale=enUS&as=u&redirectslug=Private+Browsing&utm_source=inproduct
                    </a>
                  </UnorderedList.Item>
                  <UnorderedList.Item>
                    Internet Explorer:
                    <a href="http://windows.microsoft.com/es-ES/windows-8/help-protectyourself-ie-10?ocid=IE10_priv_browsing#firstTabControl=tab1">
                      http://windows.microsoft.com/es-ES/windows-8/help-protectyourself-ie-10?ocid=IE10_priv_browsing#firstTabControl=tab1
                    </a>
                  </UnorderedList.Item>
                  <UnorderedList.Item>
                    Safari:
                    <a href="https://www.apple.com/legal/privacy/es/">
                      https://www.apple.com/legal/privacy/es/
                    </a>
                  </UnorderedList.Item>
                </UnorderedList>
              </OrderedList.Item>
            </OrderedList>
            <Paragraph>
              Ten en cuenta que, si se impide la instalación de todas las
              cookies de la página web, algunas funcionalidades de la misma, así
              como algunos contenidos pueden verse afectados.
            </Paragraph>
            <Paragraph>
              Puede ocurrir que algunas cookies utilizadas en este sitio Web no
              estén relacionadas con la Entidad. Ello se debe a que algunas
              páginas del sitio web tienen insertado contenido procedente de
              webs de terceras partes (como, por ejemplo, un vídeo de YouTube).
              Debido a que el referido contenido procede de otra web, la Entidad
              no controla la configuración de dichas cookies. Si quieres cambiar
              tus preferencias de configuración de cookies, deberás consultar
              los sitios web de dichas terceras partes para obtener información.
            </Paragraph>
            <Paragraph>
              Te informamos que tienes la posibilidad de revocar en cualquier
              momento el consentimiento prestado para la utilización de cookies
              por parte de la Entidad, configurando para ello su navegador en
              los términos previstos en el punto anterior o en el panel de
              Configuración de las cookies.
            </Paragraph>
          </Layout.Item>
          <Layout.Item>
            <Heading level={5}>
              Actualizaciones y cambios en la política de &ldquo;cookies&ldquo;
            </Heading>
            <Paragraph>
              La Entidad podrá modificar la Política de Cookies total o
              parcialmente, publicando cualquier cambio en la misma forma en que
              aparece esta política o a través de cualquier tipo de comunicación
              dirigida a los usuarios, en función de exigencias legislativas,
              reglamentarias o con la finalidad de adaptar dicha política a las
              instrucciones de la Agencia Española de Protección de Datos. Por
              ello, te aconsejamos la visita periódica a su contenido.
            </Paragraph>
          </Layout.Item>
          <Layout.Item>
            <Heading level={5}>
              Publicidad en Google Ads (Google Adwords)
            </Heading>
            <Paragraph>
              En nuestra web usamos el servicio de Google Ads (Adwords) de
              google para hacer anuncios. Podrás encontrar más información sobre
              el tratamiento de datos de Google, bajo su propia responsabilidad
              aquí:{" "}
              <a href="https://policies.google.com/privacy?hl=en">
                https://policies.google.com/privacy?hl=en
              </a>
            </Paragraph>
          </Layout.Item>
          <Layout.Item>
            Última fecha de actualización: 22 de Marzo 2024
          </Layout.Item>
        </Layout>
      </PageWrap>
      {showConfig && <CookiesGenericModal />}
      <BackToTop>Ir arriba</BackToTop>
    </>
  );
};
