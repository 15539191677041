import { getHoursBetweenDates } from "../helpers/getHoursBetweenDates";
import { useGlobal } from "./useGlobal";
import { useSummary } from "./useSummary";

export const useCalculateTotals = () => {
    const {
        selectedVehicle,
        selectedCoverage,
        selectedExtras,
        selectedAdditionalDriver,
        selectedBigger25,
        selectedDates,
        selectedTariff,
    } = useSummary();

    const { installationTax } = useGlobal();

    const calculateLess25 = () => {
        let menor25total = 0;

        if (!selectedBigger25) {
            const totalHours = getHoursBetweenDates(selectedDates.startDate, selectedDates.endDate);
            menor25total = selectedTariff.SumplementoPorEdadHasta25 * totalHours;
        }

        return menor25total;
    };

    const calculateSubTotal = () => {
        const selectedExtrasTotal = selectedExtras.reduce((accumulator, extras) => {
            return accumulator + extras.total * extras.amount;
        }, 0);

        const subTotal =
            (selectedVehicle?.total || 0) +
            selectedExtrasTotal +
            calculateLess25() +
            (selectedCoverage?.total ?? 0) +
            (selectedAdditionalDriver?.total ?? 0);

        return subTotal;
    };

    const calculateTaxes = () => {
        return (calculateSubTotal() * installationTax?.PercentTax ?? 0) / 100;
    };

    const calculateTotal = () => {
        return calculateSubTotal() + calculateTaxes();
    };

    return {
        subTotal: calculateSubTotal(),
        menor25Total: calculateLess25(),
        taxesTotal: calculateTaxes(),
        total: calculateTotal(),
    };
};
