import { Layout, Modal } from "@vwfs-bronson/bronson-react";
import { useContext } from "react";
import CookiesContext from "../../../context/CookiesContextProvider";

export const CookiesBasicModal = () => {
    const { setCookies, setShowModal } = useContext(CookiesContext);

    const handleAcceptAll = () => {
        setCookies({
            analiticas: false,
            personalizacion: false,
            marketing: false,
        });
        closeModal();
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <Modal
            buttonConfirmText="Aceptar"
            center
            onClose={() => setShowModal(false)}
            onConfirm={handleAcceptAll}
            shown={true}
            title="Configuración de las cookies"
        >
            <Layout>
                <Layout.Item>
                    <p style={{ textAlign: "justify" }}>
                        Esta sección de la web utiliza solo cookies técnicas para permitir la navegación a través de la
                        misma y asegurar su correcto funcionamiento. Para más información sobre este tipo de cookies
                        técnicas, diríjase a la Política de Cookies que figura en la parte inferior de esta página.
                    </p>
                </Layout.Item>
            </Layout>
        </Modal>
    );
};
