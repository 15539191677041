import React from "react";
import { HashRouter } from "react-router-dom";

import GeneralRoutes from "./routes/GeneralRoutes";
import { CookiesContextProvider } from "./context/CookiesContextProvider";
import moment from "moment";
import "moment/locale/es";

moment.locale("es");

function App() {
    return (
        <HashRouter>
             <CookiesContextProvider>
            <GeneralRoutes>{(routeProps) => <GeneralRoutes routeProps={routeProps} />}</GeneralRoutes>
            </CookiesContextProvider>
        </HashRouter>
    );
}

export default App;
