import moment from "moment";

export const adapterBookingFormRequest = (
    formValues,
    selectedInstallation,
    selectedVehicle,
    selectedTariff,
    selectedDates,
    selectedBigger25,
    selectedAdditionalDriver,
    selectedExtras,
    selectedCoverage,
    subTotal,
    total
) => {
    var obj = {
        StartDate: moment(selectedDates.startDate).format(),
        EndDate: moment(selectedDates.endDate).format(),
        InstallationId: selectedInstallation.id,
        FareId: selectedTariff.Id,
        VehicleId: selectedVehicle.id,
        Client: {
            name: formValues.Name,
            surName: formValues.Surnames,
            email: formValues.Email,
            phone: formValues.Phone,
            birthDate: moment(formValues.BirthDate, "DD/MM/YYYY").format(),
            licenseDate: moment(formValues.CardDate, "DD/MM/YYYY").format(),
            over25: !!selectedBigger25,
        },
        Drivers: selectedAdditionalDriver !== null,
        Insurance: selectedCoverage.coverage,
        FuelPrepay: false,
        PrePay: false,
        Subtotal: Math.round(subTotal * 100) / 100,
        Total: Math.round(total * 100) / 100,
        TotalWithInPromotionalCode: Math.round(selectedVehicle.totalWithInPromotionalCode * 100) / 100,
        PromotionalCode:selectedVehicle.promotionalCode,
        DiasEnContratoPromotionalCode:selectedVehicle.diasEnContratoPromotionalCode,
        IdCodPromocion:selectedVehicle.idCodPromocion,
    };

    obj.Extras = [];

    selectedExtras.forEach((extra) => {
        obj.Extras.push({ id: extra.id, quantity: extra.amount });
    });

    return obj;
};
