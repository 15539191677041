import { types } from "../types/types";

export const initialState = {
    installations: [],
    codConcesion: null,
};

export const installationReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.installationGetListLoaded:
            return {
                ...state,
                installations: [...action.payload],
            };
        default:
            return state;
    }
};
