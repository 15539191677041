import React, { useCallback, useEffect, useState } from "react";
import { adapterDayDescription } from "../adapters/adapterDayDescription";
import moment from "moment";
import { adapterSchedulesToGroup } from "../adapters/adapterSchedulesToGroup";
import { postRequest } from "../config/axiosClient";

export const ScheduleInfo = ({ installation }) => {
  const [schedules, setSchedules] = useState([]);
  const getSchedules = useCallback(async () => {
    const { data } = await postRequest("schedule", installation.id);
    setSchedules(data);
  }, [installation]);

  useEffect(() => {
    if (installation) {
      getSchedules();
    }
  }, [installation, getSchedules]);

  return (
    <>
      {schedules.length > 0 ? (
        adapterSchedulesToGroup(schedules).map(({ dayId, schedule }, index) => (
          <div
            key={"ScheduleGroup_" + index}
            className="u-mb-xsmall"
            style={{
              fontWeight: moment().isoWeekday() === dayId ? "inherit" : "inherit",
            }}
          >
            <div>{adapterDayDescription(dayId)}</div>
            <div>
              {schedule.map((x, index) => (
                <span
                  key={"Schedule" + index}
                  style={{ marginLeft: index > 0 ? "20px" : "" }}
                >
                  {x.open} - {x.close}
                </span>
              ))}
            </div>
          </div>
        ))
      ) : (
        <p>No se han encontrado horarios para esta instalación</p>
      )}
    </>
  );
};
