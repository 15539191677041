import React from "react";
import { useNavigate } from "react-router-dom";

import { Wizard, Main, ContentSection, Wrapper, Layout, Link, Card } from "@vwfs-bronson/bronson-react";

import { useDispatch } from "react-redux";

import { resetSelectedVehicle } from "../actions/summaryAction";
import { resetVehicleAvailables } from "../actions/vehicleAction";
import { Summary } from "../components/Summary";
import { ContactForm } from "../components/ContactForm";
import { resetOptionalData } from "../actions/optionalAction";

export const ContactDetailsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <Main>
            <ContentSection pageWrapSize="medium">
                <Wrapper>
                    <Layout>
                        <Layout.Item className={"row"}>
                            <Layout className={"u-mt-large"}>
                                <Layout.Item>
                                    <Wizard collapseBreakpoint="m" collapsedCounter="3/4" collapsedText="Escoge coche">
                                        <Wizard.Item
                                            onClick={() => {
                                                dispatch(resetVehicleAvailables());
                                                dispatch(resetOptionalData());
                                                dispatch(resetSelectedVehicle());
                                            }}
                                            done
                                        >
                                            Localización y Fechas
                                        </Wizard.Item>
                                        <Wizard.Item
                                            onClick={() => {
                                                dispatch(resetSelectedVehicle());
                                                dispatch(resetOptionalData());
                                                navigate("/vehicles");
                                            }}
                                            done
                                        >
                                            Escoge coche
                                        </Wizard.Item>
                                        <Wizard.Item
                                            onClick={() => {
                                                navigate("/optionals-summary");
                                            }}
                                            done
                                        >
                                            Opcionales
                                        </Wizard.Item>
                                        <Wizard.Item active noInteraction>
                                            Datos personales y Reserva
                                        </Wizard.Item>
                                    </Wizard>
                                </Layout.Item>
                            </Layout>
                        </Layout.Item>
                        <Layout.Item>
                            <h3 className="u-m-none">Datos personales</h3>
                        </Layout.Item>
                        <Layout.Item className={"u-m-none u-mt-small"}>
                            <Layout flush>
                                <Layout.Item>
                                    <Link small="true" to={"/optionals-summary"} icon="semantic-back">
                                        Modificar opcionales
                                    </Link>
                                </Layout.Item>
                            </Layout>
                        </Layout.Item>
                        <Layout.Item default={"3/5"} m="1/1" s="1/1">
                            <ContactForm />
                        </Layout.Item>
                        <Layout.Item default={"2/5"} m="1/1" s="1/1">
                            <Card element="article">
                                <Summary isContactInfo={true}></Summary>
                            </Card>
                        </Layout.Item>
                    </Layout>
                </Wrapper>
            </ContentSection>
        </Main>
    );
};
