/** @format */

import { Outlet, useNavigate } from "react-router-dom";
import { BackToTop } from "@vwfs-bronson/bronson-react";
import { CustomHeader } from "../components/CustomHeader";
import { FooterPage } from "../components/FooterPage";
import { useDispatch } from "react-redux";
import { getInstallations } from "../actions/installationAction";
import { useContext, useEffect } from "react";
import { Notifications } from "../components/UI/Notifications/Notifications";
import {
  getImageGeneralVariablePath,
  getInstallationRacPercentTax,
  getTariff,
} from "../actions/globalsAction";
import { useSummary } from "../hooks/useSummary";
import { isLiteSite } from "../helpers/isLiteSite";
import { Cookies } from "../components/UI/Cookies/Cookies";
import CookiesContext from "../context/CookiesContextProvider";
import { isCookiesPolicy } from "../helpers/isCookiesPolicy";

function Initializer() {
  const navigate = useNavigate();
  const summary = useSummary();
  const dispatch = useDispatch();
  const { showModal } = useContext(CookiesContext);

  const { selectedTariff, selectedInstallation } = useSummary();

  useEffect(() => {
    dispatch(getImageGeneralVariablePath());
    dispatch(getTariff());
  }, [dispatch]);

  useEffect(() => {
    if (selectedTariff) {
      let dealership = sessionStorage.getItem("dealership");

      let installationRequest = {};

      if (process.env.REACT_APP_BRAND_CODE) {
        installationRequest.CodMarca = process.env.REACT_APP_BRAND_CODE;
      }

      if (!dealership) {
        dispatch(getInstallations(installationRequest));
        return;
      }

      dealership = JSON.parse(dealership);
      installationRequest.CodConcesion = dealership.code;
      dispatch(getInstallations(installationRequest));
    }
  }, [dispatch, selectedTariff]);

  useEffect(() => {
    localStorage.setItem("ReactSummary", JSON.stringify(summary));
  }, [summary]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const codConcesion = urlParams.get("codConcesion");
    if (codConcesion) {
      sessionStorage.setItem(
        "dealership",
        JSON.stringify({
          code: codConcesion,
        })
      );
      window.history.replaceState({}, document.title, "/");
    }
  }, [navigate]);

  useEffect(() => {
    if (selectedInstallation?.id) {
      dispatch(getInstallationRacPercentTax(selectedInstallation?.id));
    }
  }, [dispatch, selectedInstallation]);

  return (
    <>
      <Notifications />
      {!isLiteSite() && <CustomHeader></CustomHeader>}
      <Outlet />
      {!isLiteSite() && <FooterPage></FooterPage>}
      {!isLiteSite() && <BackToTop>Back to top</BackToTop>}
      {showModal && !isCookiesPolicy() && <Cookies />}
    </>
  );
}

export default Initializer;
