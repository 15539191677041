import React from "react";
import { Accordion, IconList } from "@vwfs-bronson/bronson-react";
import { ScheduleInfo } from "./ScheduleInfo";
export const AccordionSchedulesInfo = ({ installation, title }) => {
  return (
    <Accordion lazyRender>
      <Accordion.Item
        title={
          <IconList>
            <IconList.Item icon="clock" iconMeaning="Horario de la instalación">
              <span>{title}</span>
            </IconList.Item>
          </IconList>
        }
      >
        <ScheduleInfo installation={installation}></ScheduleInfo>
      </Accordion.Item>
    </Accordion>
  );
};
