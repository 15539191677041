import React from "react";

import { Button, ButtonContainer } from "@vwfs-bronson/bronson-react";
import { useDispatch } from "react-redux";
import { setAmountExtraSelectedExtras } from "../../actions/summaryAction";

export const LessMoreExtraButton = ({ extra, small = true }) => {
    const { amount, availables = 99 } = extra;

    const dispatch = useDispatch();

    const handleOnClickAdd = () => {
        dispatch(setAmountExtraSelectedExtras({ ...extra, amount: amount + 1 }));
    };

    const handleOnClickRemove = () => {
        dispatch(setAmountExtraSelectedExtras({ ...extra, amount: amount - 1 }));
    };

    return (
        <ButtonContainer>
            <Button
                inContainer
                ariaLabel="Add"
                icon="semantic-minus"
                secondary
                round
                small={small}
                disabled={amount <= 0}
                onClick={handleOnClickRemove}
            />
            <>{amount}</>
            <Button
                inContainer
                ariaLabel="Remove"
                icon="semantic-add"
                small={small}
                secondary
                disabled={availables <= amount}
                round
                onClick={handleOnClickAdd}
            />
        </ButtonContainer>
    );
};
