import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";

import { ThemeSelector } from "./themes/ThemeSelector";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeSelector>
                <App />
            </ThemeSelector>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
reportWebVitals();
