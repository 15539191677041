import React, { useEffect, useState } from "react";

import { useVehicle } from "../hooks/useVehicle";

import { Wizard, Main, ContentSection, Wrapper, Layout, Card, Link } from "@vwfs-bronson/bronson-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetVehicleAvailables } from "../actions/vehicleAction";
import { VehicleFamilies } from "../components/Vehicles/VehicleFamilies";
import { VehicleList } from "../components/Vehicles/VehicleList";
import { useSummary } from "../hooks/useSummary";
import { Summary } from "../components/Summary";

export const VehiclesPage = () => {
    const { vehiclesAvailables, familiesAvailables } = useVehicle();
    const { selectedInstallation } = useSummary();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedFamily, setSelectedFamily] = useState(null);

    useEffect(() => {
        if (vehiclesAvailables?.length === 0) navigate("/");
    }, [vehiclesAvailables, navigate, selectedFamily]);

    useEffect(() => {
        if (familiesAvailables?.length === 1) setSelectedFamily(familiesAvailables[0]);
    }, [familiesAvailables]);

    return (
        <Main>
            <ContentSection pageWrapSize="medium">
                <Wrapper>
                    <Layout>
                        <Layout.Item className={"row"}>
                            <Layout className={"u-mt-large"}>
                                <Layout.Item>
                                    <Wizard collapseBreakpoint="m" collapsedCounter="2/4" collapsedText="Escoge coche">
                                        <Wizard.Item onClick={() => dispatch(resetVehicleAvailables())} done>
                                            Localización y Fechas
                                        </Wizard.Item>
                                        <Wizard.Item noInteraction active>
                                            Escoge coche
                                        </Wizard.Item>
                                        <Wizard.Item noInteraction>Opcionales</Wizard.Item>
                                        <Wizard.Item noInteraction>Datos personales y Reserva</Wizard.Item>
                                    </Wizard>
                                </Layout.Item>
                            </Layout>
                        </Layout.Item>
                        <Layout.Item className={"u-m-none u-mt-small"}>
                            <Layout flush>
                                <Layout.Item>
                                    {!selectedFamily && (
                                        <h5>
                                            Hemos encontrado {vehiclesAvailables.length} vehículo
                                            {vehiclesAvailables.length > 1 && "s"} en {selectedInstallation?.name} (
                                            {selectedInstallation?.province})
                                        </h5>
                                    )}
                                </Layout.Item>
                                <Layout.Item>
                                    <Link
                                        small="true"
                                        to={() => {}}
                                        icon="semantic-back"
                                        onClick={() => {
                                            if (selectedFamily) {
                                                setSelectedFamily(null);
                                            } else dispatch(resetVehicleAvailables());
                                        }}
                                    >
                                        {selectedFamily ? "Ver modelos" : "Modificar selección"}
                                    </Link>
                                </Layout.Item>
                            </Layout>
                        </Layout.Item>
                        <Layout.Item default={"3/5"} m="1/2" s="1/1">
                            <Layout equalHeight>
                                {!selectedFamily && familiesAvailables?.length > 0 && (
                                    <VehicleFamilies
                                        familiesAvailables={familiesAvailables}
                                        setSelectedFamily={setSelectedFamily}
                                    ></VehicleFamilies>
                                )}
                                {selectedFamily && <VehicleList selectedFamily={selectedFamily}></VehicleList>}
                            </Layout>
                        </Layout.Item>
                        <Layout.Item default={"2/5"} m="1/2" s="1/1">
                            <Card element="article">
                                <Layout flush className="u-text-left">
                                    <Layout.Item>
                                        <Summary></Summary>
                                    </Layout.Item>
                                </Layout>
                            </Card>
                        </Layout.Item>
                    </Layout>
                </Wrapper>
            </ContentSection>
        </Main>
    );
};
