import React from "react";

import { Main, ContentSection, Wrapper, Layout, ItemTeaser, Button, Card, IconList } from "@vwfs-bronson/bronson-react";
import { Summary } from "../components/Summary";
import { useSummary } from "../hooks/useSummary";
import { formatToDate } from "../helpers/formatToDate";
import { formatToTime } from "../helpers/formatToTime";
import { AccordionSchedulesInfo } from "../components/AcordionSchedulesInfo";
import { useDispatch } from "react-redux";
import { sendEmail } from "../actions/globalsAction";
import { BookingSearch } from "../components/BookingSearch";
import { CancelBooking } from "../components/CancelBooking";
import moment from "moment";

export const BookingConfirmPage = () => {
    const { selectedInstallation, selectedDates, bookingLocator, selectedContactData } = useSummary();
    const dispatch = useDispatch();

    const handleOnClickSendEmail = () => {
        dispatch(sendEmail({ Locator: bookingLocator.Locator, Email: selectedContactData.Email }));
    };

    const itemTeaserTitleByStatus = () => {
        if (bookingLocator.Status === "RE")
            return `La reserva número ${bookingLocator.Locator} está pendiente de
        confirmación por parte del concesionario, se pondrán en contacto
        contigo con la mayor brevedad posible.`;

        if (bookingLocator.Status === "CA")
            return `Tu reserva ${bookingLocator.Locator} se canceló el ${moment(bookingLocator.ModificationDate).format(
                "DD/MM/YYYY, HH:mm"
            )}`;

        return "Para cualquier cambio en su reserva contacte con el concesionario";
    };

    return (
        <Main>
            <ContentSection pageWrapSize="medium">
                <Wrapper>
                    {selectedInstallation && selectedDates ? (
                        <Layout>
                            <Layout.Item className={"row u-mt"}></Layout.Item>
                            <Layout.Item className={"row u-mt"}></Layout.Item>
                            <Layout.Item className={"row u-mt"}></Layout.Item>
                            <Layout.Item default={"3/5"} m="1/2" s="1/1">
                                <Layout equalHeight>
                                    <Layout.Item className={"row"}>
                                        <Card element="article" className="u-text-center">
                                            <ItemTeaser
                                                alternative={"true"}
                                                icon="semantic-success"
                                                title={itemTeaserTitleByStatus()}
                                            >
                                                {bookingLocator.Status === "RE" && (
                                                    <div className="u-mt-small">
                                                        Por favor, revisa tu email para comprobar que has recibido toda
                                                        la información sobre tu reserva.
                                                    </div>
                                                )}
                                            </ItemTeaser>
                                            {bookingLocator.Status === "RE" && (
                                                <Button
                                                    icon="semantic-mail"
                                                    iconReversed
                                                    link
                                                    simple
                                                    small
                                                    className="u-mt-small"
                                                    onClick={handleOnClickSendEmail}
                                                >
                                                    En caso contrario, haz click aquí
                                                </Button>
                                            )}
                                        </Card>
                                    </Layout.Item>
                                    {selectedContactData && (
                                        <Layout.Item className={"row"}>
                                            <Card element="article">
                                                <Layout flush>
                                                    <Layout.Item className={"row"}>
                                                        <h5>Tus datos de contacto</h5>
                                                    </Layout.Item>
                                                    <Layout.Item className={"row"} default="1/2" m={"1/1"}>
                                                        <p>
                                                            <b>Nombre</b>
                                                            <br />
                                                            {selectedContactData.Name} {selectedContactData.Surnames}
                                                        </p>
                                                    </Layout.Item>
                                                    <Layout.Item default="1/2" m={"1/1"}>
                                                        <p>
                                                            <b>Email</b>
                                                            <br />
                                                            {selectedContactData.Email}
                                                        </p>
                                                    </Layout.Item>
                                                    <Layout.Item default="1/2" m={"1/1"}>
                                                        <p>
                                                            <b>Teléfono</b>
                                                            <br />
                                                            {selectedContactData.Phone}
                                                        </p>
                                                    </Layout.Item>
                                                    <Layout.Item default="1/2" m={"1/1"}>
                                                        <p>
                                                            <b>Fecha de nacimiento</b>
                                                            <br />
                                                            {selectedContactData.BirthDate}
                                                        </p>
                                                    </Layout.Item>
                                                    <Layout.Item default="1/2" m={"1/1"}>
                                                        <p>
                                                            <b>Fecha carnet de conducir</b>
                                                            <br />
                                                            {selectedContactData.CardDate}
                                                        </p>
                                                    </Layout.Item>
                                                </Layout>
                                            </Card>
                                        </Layout.Item>
                                    )}
                                    <Layout.Item default="1/2" m="1/1">
                                        <Card element="article">
                                            <Layout flush>
                                                <Layout.Item>
                                                    <h5>Recogida</h5>
                                                    <p>
                                                        {selectedInstallation?.name} ({selectedInstallation?.province})
                                                    </p>
                                                    <Layout>
                                                        <Layout.Item default="1/2">
                                                            <IconList className={"u-mb-small"}>
                                                                <IconList.Item
                                                                    icon="calendar"
                                                                    iconMeaning="Not included:"
                                                                >
                                                                    {formatToDate(selectedDates?.startDate)}
                                                                </IconList.Item>
                                                            </IconList>
                                                        </Layout.Item>
                                                        <Layout.Item default="1/2">
                                                            <IconList className={"u-mb-small"}>
                                                                <IconList.Item icon="clock" iconMeaning="Not included:">
                                                                    {formatToTime(selectedDates?.startDate)}
                                                                </IconList.Item>
                                                            </IconList>
                                                        </Layout.Item>
                                                    </Layout>
                                                    <IconList>
                                                        <IconList.Item icon="location">
                                                            <a
                                                                href={`https://maps.google.com/?q=${selectedInstallation.address}`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {selectedInstallation.address}
                                                            </a>
                                                        </IconList.Item>
                                                        <IconList.Item icon="phone">
                                                            <a href={`tel:${selectedInstallation.phone}`}>
                                                                {selectedInstallation.phone}
                                                            </a>
                                                        </IconList.Item>
                                                        <IconList.Item icon="mail">
                                                                <a href={`mailto:${selectedInstallation.email}`} className="u-text-wrap">
                                                               {selectedInstallation.email} 
                                                            </a>
                                                        </IconList.Item>
                                                    </IconList>
                                                    <AccordionSchedulesInfo
                                                        className="u-mt-large"
                                                        installation={selectedInstallation}
                                                        title={"Horario de apertura"}
                                                    />
                                                </Layout.Item>
                                            </Layout>
                                        </Card>
                                    </Layout.Item>
                                    <Layout.Item default="1/2" m="1/1">
                                        <Card element="article">
                                            <Layout flush>
                                                <Layout.Item>
                                                    <h5>Devolución</h5>
                                                    <p>
                                                        {selectedInstallation?.name} ({selectedInstallation?.province})
                                                    </p>
                                                    <Layout>
                                                        <Layout.Item default="1/2">
                                                            <IconList className={"u-mb-small"}>
                                                                <IconList.Item
                                                                    icon="calendar"
                                                                    iconMeaning="Not included:"
                                                                >
                                                                    {formatToDate(selectedDates?.endDate)}
                                                                </IconList.Item>
                                                            </IconList>
                                                        </Layout.Item>
                                                        <Layout.Item default="1/2">
                                                            <IconList className={"u-mb-small"}>
                                                                <IconList.Item icon="clock" iconMeaning="Not included:">
                                                                    {formatToTime(selectedDates?.endDate)}
                                                                </IconList.Item>
                                                            </IconList>
                                                        </Layout.Item>
                                                    </Layout>
                                                    <IconList>
                                                        <IconList.Item icon="location">
                                                            <a
                                                                href={`https://maps.google.com/?q=${selectedInstallation.address}`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {selectedInstallation.address}
                                                            </a>
                                                        </IconList.Item>
                                                        <IconList.Item icon="phone">
                                                            <a href={`tel:${selectedInstallation.phone}`}>
                                                                {selectedInstallation.phone}
                                                            </a>
                                                        </IconList.Item>
                                                        <IconList.Item icon="mail">
                                                            <a href={`mailto:${selectedInstallation.email}`} className="u-text-wrap">
                                                                {selectedInstallation.email}
                                                            </a>
                                                        </IconList.Item>
                                                    </IconList>

                                                    <AccordionSchedulesInfo
                                                        className="u-mt"
                                                        installation={selectedInstallation}
                                                        title={"Horario de apertura"}
                                                    />
                                                </Layout.Item>
                                            </Layout>
                                        </Card>
                                    </Layout.Item>
                                    {bookingLocator.Status === "RE" && <CancelBooking />}
                                    <Layout.Item>
                                        <Card element="article">
                                            <h5>Información importante</h5>
                                            <IconList>
                                                <React.Fragment key=".0">
                                                    <IconList.Item icon="edit" iconMeaning="Modificación de la reserva">
                                                        <div>
                                                            <b>Modificación de la reserva</b>
                                                            <br />
                                                            Para realizar cambios en tu reserva puedes llamar al{" "}
                                                            {selectedInstallation.phone} hasta 48 horas antes de la
                                                            recogida y estaremos encantados de ayudarte. Ten en cuenta
                                                            que en función de las modificaciones que se realicen, el
                                                            importe total del servicio puede verse afectado.
                                                        </div>
                                                    </IconList.Item>
                                                    <IconList.Item icon="chat" iconMeaning="Detalle de contacto">
                                                        <div>
                                                            <b>Detalle de contacto</b>
                                                            <br />
                                                            No dudes en contactarnos para cualquier duda relacionada con
                                                            tu reserva a través del teléfono{" "}
                                                            {selectedInstallation.phone}
                                                        </div>
                                                    </IconList.Item>
                                                </React.Fragment>
                                            </IconList>
                                        </Card>
                                    </Layout.Item>
                                </Layout>
                            </Layout.Item>
                            <Layout.Item default={"2/5"} m="1/2" s="1/1">
                                <Card element="article">
                                    <Layout flush className="u-text-left">
                                        <Layout.Item>
                                            <Summary isContactInfo={true}></Summary>
                                        </Layout.Item>
                                    </Layout>
                                </Card>
                            </Layout.Item>
                        </Layout>
                    ) : (
                        <BookingSearch></BookingSearch>
                    )}
                </Wrapper>
            </ContentSection>
        </Main>
    );
};
