export const financialFormat = (value, decimals = 2) => {
    if (!value) {
        value = "000";
    }
    value = value.toString();
    while (value.length <= decimals) {
        value = "0" + value;
    }

    let valuePart1 = value.substring(0, value.length - decimals);
    let valuePart2 = value.substring(value.length - decimals);

    return valuePart1.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + "," + valuePart2;
};
