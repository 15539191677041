import React from "react";
import { Card, Layout } from "@vwfs-bronson/bronson-react";

import { useOptional } from "../../hooks/useOptional";
import { showExtraInfo } from "../../helpers/showExtraInfo";
import { LessMoreExtraButton } from "./LessMoreExtraButton";
import { financialFormat } from "../../helpers/financialFormat";
import { useSummary } from "../../hooks/useSummary";

export const Extras = () => {
    const { extras } = useOptional();
    const { selectedExtras } = useSummary();

    return (
        <Layout equalHeight>
            {extras.map((extra) => {
                const { id, total, totalByDay } = extra;
                return (
                    <Layout.Item key={`Coverage_${id}`} default={"1/2"} l="1/1">
                        <Card element="article" className="u-text-left">
                            <Layout>
                                <Layout.Item default={"1/1"}>
                                    <h5 className="u-mb-xsmall">{showExtraInfo(id, "name")}</h5>
                                </Layout.Item>
                            </Layout>
                            <Layout>
                                <Layout.Item>
                                    <p>{showExtraInfo(id, "text")}</p>
                                </Layout.Item>
                            </Layout>
                            <hr />
                            <Layout>
                                <Layout.Item default="1/2">
                                    <h5 className="u-m-none">{financialFormat(Math.round(totalByDay * 100))} €/día</h5>
                                    <p className="u-m-none">Total: {financialFormat(Math.round(total * 100))}</p>
                                </Layout.Item>
                                <Layout.Item default="1/2" className="u-text-right">
                                    <LessMoreExtraButton
                                        extra={{
                                            ...extra,
                                            amount: selectedExtras.find((x) => x.id === id)?.amount || 0,
                                            name: showExtraInfo(id, "name"),
                                        }}
                                        small={false}
                                    ></LessMoreExtraButton>
                                </Layout.Item>
                            </Layout>
                        </Card>
                    </Layout.Item>
                );
            })}
        </Layout>
    );
};
