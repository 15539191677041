export const adapterSchedulesToGroup = (schedules) => {
    let res = [];

    schedules.forEach(({ dayId, open, close }) => {
        var indexSearch = res.findIndex((x) => x.dayId === dayId);
        if (indexSearch === -1) {
            res.push({ dayId, schedule: [{ open, close }] });
        } else {
            res[indexSearch].schedule.push({ open, close });
        }
    });
    return res;
};
