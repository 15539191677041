import moment from "moment";

export function getRoundDaysBetweenDates(StartDate, EndDate, withFormat = false, timeUnitId) {

    var a = moment(StartDate);
    var b = moment(EndDate);
    var hours = b.diff(a, "hours");
    var days = Math.ceil(hours / 24);
    if (withFormat) {
        if (timeUnitId === "HO" && days < 1) {
            days = hours + (hours>1?" horas":" hora");
        }
        else {
            return (days>1?days:1) + (days > 1 ? " días" : " día");
        }
    }
    return days;
}
