/** @format */

import Cookies from "universal-cookie";

const useAdobeAnalytics = () => {
  const loadScript = () => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = process.env.REACT_APP_ADOBE_URL;

    script.addEventListener("load", () => {});
    document.body.appendChild(script);
  };

  const getCookies = () => {
    return new Cookies().get("BP");
  };

  const setCookies = (values) => {
    new Cookies().set("BP", values, { path: "/" });
    if (window.adobe.optIn) {
      window.adobe.optIn.denyAll();

      if (values.analiticas) {
        window.adobe.optIn.approve(
          window.adobe.OptInCategories.ANALYTICS,
          true
        );
      }

      if (values.personalizacion) {
        window.adobe.optIn.approve(window.adobe.OptInCategories.TARGET, true);
      }

      if (values.marketing) {
        window.adobe.optIn.approve(window.adobe.OptInCategories.CAMPAIGN, true);
      }
    }
  };

  return { loadScript, getCookies, setCookies };
};

export default useAdobeAnalytics;
