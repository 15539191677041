import React from "react";

import { Layout, ItemTeaser } from "@vwfs-bronson/bronson-react";
import { isBlueLabel } from "../../helpers/isBlueLabel";

export const LandingBrandReason = () => {
    return (
        <Layout equalHeight className={"row"}>
            <Layout.Item default="1/2" s="1/1">
                <Layout>
                    <Layout.Item>
                        <ItemTeaser
                            icon={isBlueLabel() ? "semantic-guarantee" : "semantic-car-value"}
                            inline
                            title="Es lo que esperas"
                        >
                            El coche que reservas es el coche que conduces, sin "similares" ni "categorías".
                        </ItemTeaser>
                    </Layout.Item>
                    <Layout.Item>
                        <ItemTeaser
                            icon={isBlueLabel() ? "semantic-details" : "semantic-news"}
                            inline
                            title="Es transparente"
                        >
                            El precio que pone en la reserva es el que pagas, sin sorpresas.
                        </ItemTeaser>
                    </Layout.Item>
                </Layout>
            </Layout.Item>
            <Layout.Item default="1/2" s="1/1">
                <Layout>
                    <Layout.Item>
                        <ItemTeaser
                            icon={isBlueLabel() ? "calendar-pesosign" : "semantic-settings"}
                            inline
                            title="Es flexible"
                        >
                            Alquilas el vehículo por el periodo que necesitas, adaptándolo a tus planes.
                        </ItemTeaser>
                    </Layout.Item>
                    <Layout.Item>
                        <ItemTeaser
                            icon={isBlueLabel() ? "globe-car" : "semantic-guarantee"}
                            inline
                            title="Es del Grupo Volkswagen"
                        >
                            Te garantizamos vehículos de última generación y totalmente equipados, para tu tranquilidad.
                        </ItemTeaser>
                    </Layout.Item>
                </Layout>
            </Layout.Item>
        </Layout>
    );
};
