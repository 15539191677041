export const types = {
    installationGetListLoaded: "[Installation] Get installations list loaded",

    vehiclesSetListAvailableLoading: "[Vehicle] Set vehicle available loading status",
    vehiclesGetListAvailableLoaded: "[Vehicle] Get vehicle available list loaded",
    vehiclesSetFamiliesAvailablesLoaded: "[Vehicle] Set families availables",
    vehiclesAvailablesReset: "[Vehicle] Reset vehicles available",

    notificationAdd: "[Notification] Add notification",
    notificationDelete: "[Notification] Delete notification",

    globalsGetImageGeneralVariablePathLoaded: "[Globals] Get image variable path loaded",
    globalsGetInstallationRacPercentTaxLoaded: "[Globals] Installation tax loaded",
    globalsSetShowCookiesModal: "[Globals] Set show cookies modal",
    globalsSetSchedulesInfoModal: "[Globals] Set show schedules info modal",
    summarySetSelectedTariff: "[Summary] Set selected tariff",
    summarySetSelectedInstallation: "[Summary] Set selected installation",
    summaryResetSelectedInstallation: "[Summary] Reset selected installation",
    summarySetSelectedVehicle: "[Summary] Set selected vehicle",
    summaryResetSelectedVehicle: "[Summary] Reset selected vehicle",
    summarySetSelectedDates: "[Summary] Set selected dates",
    summarySetAmountExtraSelectedExtras: "[Summary] Set amount extra of selected extra",
    summarySetSelectedCoverage: "[Summary] Set selected coverage",
    summarySetSelectedAdditionalDriver: "[Summary] Set selected additional driver",
    summarySetSelectedBigger25: "[Summary] Set selected bigger 25",
    summaryResetSelectedAdditionalDriver: "[Summary] Reset selected additional driver",
    summaryResetSelectedDates: "[Summary] Reset selected dates",
    summarySetBookingLocator: "[Summary] Set booking locator",
    summarySetSelectedContactData: "[Summary] Set selected contact data",
    summaryResetAll: "[Summary] Reset all summary data",

    optionalGetCoverages: "[Optional] Get coverages ",
    optionalGetAdditionalDriver: "[Optional] Get additional driver ",
    optionalGetExtras: "[Optional] Get extras",
    optionalSetExtrasLoading: "[Optional] Set extras loading",
    optionalSetOptionalsLoading: "[Optional] Set optionals loading ",
    optionalResetData: "[Optional] Reset optional data ",
};
