import React from "react";

import { HeroTeaser, OrderedList, Main, ContentSection, Wrapper } from "@vwfs-bronson/bronson-react";
export const LegalNotePage = () => {
    return (
        <>
            <HeroTeaser title={[<>Aviso legal</>]} shallow auto noImage />
            <Main>
                <ContentSection pageWrapSize="medium">
                    <Wrapper>
                        <OrderedList style={{ textAlign: "justify" }} nestedCounter>
                            <>
                                <OrderedList.Item>
                                    El presente Sitio Web{" "}
                                    <a href="https://rentacar.vwfs.es">https://rentacar.vwfs.es</a> es titularidad de{" "}
                                    <strong>Volkswagen Renting S.A.</strong> (con domicilio social en Avda. de Bruselas,
                                    nº 34, Alcobendas - Madrid, CIF A80/185051, Registro Mercantil de la provincia de
                                    Madrid. Tomo 2.138. Folio 63. Hoja nº M-37860. Inscripción 1ª), en adelante, como
                                    <strong> “VOLKSWAGEN FINANCIAL SERVICES”</strong>.
                                </OrderedList.Item>
                                <OrderedList.Item>
                                    <strong>Aceptación por el usuario:</strong>
                                    <p>
                                        El presente aviso legal regula el uso de esta página web puesta a disposición de
                                        los usuarios en la URL{" "}
                                        <a href="https://rentacar.vwfs.es">https://rentacar.vwfs.es</a>. Cualquier uso
                                        de la presente web le otorga la condición de Usuario de la misma y supone su
                                        plena aceptación a las condiciones recogidas en este aviso legal. VOLKSWAGEN
                                        FINANCIAL SERVICES realiza continuos esfuerzos para evitar errores en los
                                        contenidos que pudieran aparecer en este web site. VOLKSWAGEN FINANCIAL SERVICES
                                        no garantiza, ni se responsabiliza de las consecuencias que pudieran derivarse
                                        de los errores en los contenidos que pudieran aparecer en este web site,
                                        proporcionados por terceros, asimismo no asume responsabilidad alguna por las
                                        discrepancias que pudiesen existir entre los documentos impresos y la versión
                                        electrónica publicada en este web site. En caso de discrepancia entre la versión
                                        impresa y la publicada en el web site, prevalecerá la versión impresa, salvo lo
                                        establecido respecto a erratas y actualizaciones.
                                    </p>
                                </OrderedList.Item>
                                <OrderedList.Item>
                                    <strong>Uso correcto del sitio web:</strong>
                                    <p className="u-text-jusitify">
                                        El Usuario se compromete a utilizar el Sitio Web, los contenidos y servicios de
                                        conformidad con la Ley, la presente nota legal, las buenas costumbres y el orden
                                        público. Del mismo modo el Usuario se obliga a no utilizar la Web o los
                                        servicios que se presten a través de él con fines o efectos ilícitos o
                                        contrarios al contenido de la presente nota legal, lesivos de los intereses o
                                        derechos de terceros, o que de cualquier forma pueda dañar, inutilizar o
                                        deteriorar la Web o sus servicios o impedir un normal disfrute del Sitio Web por
                                        otros Usuarios. Asimismo, el Usuario se compromete expresamente a no destruir,
                                        alterar, inutilizar o, de cualquier otra forma, dañar los datos, programas o
                                        documentos electrónicos y demás que se encuentren en la Web. De igual forma, el
                                        Usuario se compromete a no obstaculizar el acceso de otros usuarios al servicio
                                        de acceso mediante el consumo masivo de los recursos informáticos a través de
                                        los cuales VOLKSWAGEN FINANCIAL SERVICES presta el servicio, así como realizar
                                        acciones que dañen, interrumpan o generen errores en dichos sistemas. El Usuario
                                        se compromete a no introducir programas, virus, macros, applets, controles
                                        ActiveX o cualquier otro dispositivo lógico o secuencia de caracteres que causen
                                        o sean susceptibles de causar cualquier tipo de alteración en los sistemas
                                        informáticos de VOLKSWAGEN FINANCIAL SERVICES o de terceros. El Usuario se
                                        compromete a no obtener informaciones, mensajes, gráficos, dibujos, archivos de
                                        sonido y/o imagen, fotografías, grabaciones, software y en general, cualquier
                                        clase de material accesible a través del Web o de los servicios ofrecidos en el
                                        mismo.
                                    </p>
                                </OrderedList.Item>
                                <OrderedList.Item>
                                    <strong>Acceso al sitio web y contraseñas:</strong>
                                    <p>
                                        El acceso al Sitio Web por parte de los usuarios tiene carácter libre y
                                        gratuito. Cuando sea necesario que el Usuario se registre o aporte datos
                                        personales, a la recogida y el tratamiento de los datos personales de los
                                        usuarios será de aplicación lo dispuesto en el apartado 7 de esta Nota Legal.
                                        Queda prohibida la contratación a través del Sitio Web por parte de menores de
                                        edad. Cuando sea necesario que el Usuario se registre, será responsable de
                                        aportar información veraz y lícita. Si como consecuencia del registro, se dotara
                                        al Usuario de una contraseña, éste se compromete a hacer un uso diligente y a
                                        mantener en secreto la contraseña. Los Usuarios son responsables de la adecuada
                                        custodia y confidencialidad de cualesquiera identificadores y/o contraseñas que
                                        le sean suministradas por VOLKSWAGEN FINANCIAL SERVICES y se comprometen a no
                                        ceder su uso a terceros, ya sea temporal o permanente, ni a permitir su acceso a
                                        personas ajenas. Será responsabilidad del Usuario la utilización del Sitio Web
                                        por cualquier tercero ilegítimo que emplee a tal efecto una contraseña a causa
                                        de una utilización no diligente o de la pérdida de la misma por el Usuario. El
                                        Usuario está obligado a notificar de forma inmediata a VOLKSWAGEN FINANCIAL
                                        SERVICES cualquier hecho que permita el uso indebido de los identificadores y/o
                                        contraseñas, tales como el robo, extravío, o el acceso no autorizado a los
                                        mismos, con el fin de proceder a su inmediata cancelación. Mientras no se
                                        comuniquen tales hechos VOLKSWAGEN FINANCIAL SERVICES quedará eximida de
                                        cualquier responsabilidad que pudiera derivarse del uso indebido de los
                                        identificadores y/o contraseñas por terceros no autorizados.
                                    </p>
                                </OrderedList.Item>
                                <OrderedList.Item>
                                    <strong>Propiedad intelectual:</strong>
                                    <p>
                                        Todos los contenidos del Sitio Web, salvo que se indique lo contrario, son
                                        titularidad exclusiva de VOLKSWAGEN FINANCIAL SERVICES o de terceros que han
                                        autorizado su inclusión en el Sitio Web y, con carácter enunciativo, que no
                                        limitativo, el diseño gráfico, código fuente, logos, textos, ilustraciones,
                                        fotografías, y demás elementos que aparecen en el Web. Igualmente, todos los
                                        nombres comerciales, marcas o signos distintivos de cualquier clase contenidos
                                        en el Web están protegidos por la Ley. VOLKSWAGEN FINANCIAL SERVICES no concede
                                        ningún tipo de licencia o autorización de uso personal al Usuario sobre sus
                                        derechos de propiedad intelectual e industrial o sobre cualquier otro derecho
                                        relacionado con su Web; y los servicios ofrecidos en la misma. Por ello, el
                                        Usuario reconoce que la reproducción, distribución, comercialización o
                                        transformación no autorizadas de los elementos indicados en los apartados
                                        anteriores constituye una infracción de los derechos de propiedad intelectual
                                        y/o industrial de VOLKSWAGEN FINANCIAL SERVICES o del titular de los mismos.
                                    </p>
                                </OrderedList.Item>
                                <OrderedList.Item>
                                    <strong>Régimen de responsabilidad:</strong>
                                    <ol>
                                        <OrderedList.Item>
                                            <strong>Responsabilidad por el uso del sitio web</strong>
                                            <p>
                                                El Usuario es el único responsable de las infracciones en las que pueda
                                                incurrir o de los perjuicios que pueda causar o causarse por la
                                                utilización del Sitio Web, quedando VOLKSWAGEN FINANCIAL SERVICES
                                                exonerada de cualquier clase de responsabilidad que se pudiera derivar
                                                por las acciones del Usuario. El Usuario es el único responsable frente
                                                a cualquier reclamación o acción legal, judicial o extrajudicial,
                                                iniciada por terceras personas contra VOLKSWAGEN FINANCIAL SERVICES
                                                basada en la utilización por el Usuario del Web. En su caso, el Usuario
                                                asumirá cuantos gastos, costes e indemnizaciones sean irrogados a
                                                VOLKSWAGEN FINANCIAL SERVICES con motivo de tales reclamaciones o
                                                acciones legales.
                                            </p>
                                        </OrderedList.Item>
                                        <OrderedList.Item>
                                            <strong>Responsabilidad por el funcionamiento del sitio web</strong>
                                            <p>
                                                VOLKSWAGEN FINANCIAL SERVICES excluye toda responsabilidad que se
                                                pudiera derivar de interferencias, omisiones, interrupciones, virus
                                                informáticos, averías telefónicas o desconexiones en el funcionamiento
                                                operativo del sistema electrónico, motivado por causas ajenas a
                                                VOLKSWAGEN FINANCIAL SERVICES.
                                            </p>
                                        </OrderedList.Item>
                                    </ol>
                                </OrderedList.Item>
                                <OrderedList.Item>
                                    <strong>Tratamiento de los datos obtenidos a través del sitio web:</strong>
                                    <p>
                                        A través de los formularios incluidos en el Sitio Web se procederá a recabar los
                                        datos de los Usuarios tras haber prestado su oportuno consentimiento. Para mayor
                                        información diríjase a la Política de Privacidad incluida en el formulario y en
                                        este Sitio Web.
                                    </p>
                                </OrderedList.Item>
                                <OrderedList.Item>
                                    <strong>Modificaciones del aviso legal:</strong>
                                    <p>
                                        VOLKSWAGEN FINANCIAL SERVICES se reserva el derecho a modificar unilateralmente
                                        en cualquier momento y sin aviso previo, la presentación y configuración de este
                                        Sitio Web, así como los servicios y condiciones requeridas para usar el Sitio
                                        Web y sus servicios. El Usuario quedará obligado automáticamente por el Aviso
                                        Legal que se halle vigente en el momento en que acceda al Sitio Web, por lo que
                                        deberá leer periódicamente dicho Aviso Legal.
                                    </p>
                                </OrderedList.Item>
                                <OrderedList.Item>
                                    <strong>Confidencialidad:</strong>
                                    <p>
                                        VOLKSWAGEN FINANCIAL SERVICES se compromete al cumplimiento de su obligación de
                                        secreto de los datos de carácter personal y de su deber de guardarlos de forma
                                        confidencial y adoptará las medidas necesarias para evitar su alteración,
                                        pérdida, tratamiento o acceso no autorizado, habida cuenta en todo momento del
                                        estado de la tecnología.
                                    </p>
                                </OrderedList.Item>
                                <OrderedList.Item>
                                    <strong>Observaciones del usuario:</strong>
                                    <p>
                                        El Usuario garantiza que la información, material, contenidos u observaciones
                                        que no sean sus propios datos personales y que sean facilitados a VOLKSWAGEN
                                        FINANCIAL SERVICES a través del Sitio Web, no infringen los Derechos de
                                        Propiedad Intelectual o Industrial de terceros, ni ninguna otra disposición
                                        legal. La información, materiales, contenidos u observaciones que el Usuario
                                        facilite a VOLKSWAGEN FINANCIAL SERVICES se considerarán no confidenciales,
                                        reservándose VOLKSWAGEN FINANCIAL SERVICES el derecho a usarlas de la forma que
                                        considere más adecuada.
                                    </p>
                                </OrderedList.Item>
                                <OrderedList.Item>
                                    <strong>Legislación aplicable y competencia judicial:</strong>
                                    <p>
                                        Cualquier controversia surgida de la interpretación o ejecución del presente
                                        Aviso Legal se interpretará bajo la legislación española. Asimismo, VOLKSWAGEN
                                        FINANCIAL SERVICES y el Usuario, con renuncia a cualquier otro fuero, se someten
                                        al de los juzgados y tribunales del domicilio del Usuario para cualquier
                                        controversia que pudiera surgir. En el caso de que el Usuario tenga su domicilio
                                        fuera de España, VOLKSWAGEN FINANCIAL SERVICES y el Usuario se someten a los
                                        juzgados y tribunales de la ciudad de Madrid.
                                    </p>
                                </OrderedList.Item>
                            </>
                        </OrderedList>
                    </Wrapper>
                </ContentSection>
            </Main>
        </>
    );
};
