import Notification, { SEVERITY_NOTIFICACION, TYPE_NOTIFICACION } from "../models/Notification";
import { types } from "../types/types";

export const addNotificationError = (msg) => {
    return (dispatch) => {
        var notification = new Notification.fromObject({
            Description: msg,
            Type: TYPE_NOTIFICACION.TOAST,
            Severity: SEVERITY_NOTIFICACION.ERROR,
            Timeout: 5000,
        });
        dispatch(addNotification(notification));
    };
};

export const addNotificationSuccess = (msg) => {
    return (dispatch) => {
        var notification = new Notification.fromObject({
            Description: msg,
            Type: TYPE_NOTIFICACION.TOAST,
            Severity: SEVERITY_NOTIFICACION.SUCCESS,
            Timeout: 5000,
        });
        dispatch(addNotification(notification));
    };
};

export const addNotificationModal = (msg, title) => {
    return (dispatch) => {
        var notification = new Notification.fromObject({
            Description: msg,
            Type: TYPE_NOTIFICACION.MODAL,
            Title: title,
        });
        dispatch(addNotification(notification));
    };
};

export const addNotification = (notification) => {
    return (dispatch) => {
        dispatch({
            type: types.notificationAdd,
            payload: notification,
        });
        if (notification.Timeout) dispatch(setTimeOutForNotifications(notification));
    };
};

const setTimeOutForNotifications = (notification) => {
    return (dispatch) => {
        setTimeout(() => dispatch(deleteNotification(notification)), notification.Timeout);
    };
};

export const deleteNotification = (notificacion) => {
    return {
        type: types.notificationDelete,
        payload: notificacion.Id,
    };
};
