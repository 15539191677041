export const faqsGroups = [
    {
        title: "Recogida del vehículo",
        faqs: [
            {
                title: "¿Cuál es la edad mínima/máxima para alquilar un vehículo con Volkswagen Financial Services / Rent-a-Car?",
                description:
                    "Debes ser mayor de 21 años y tener un permiso de conducir con una antigüedad mínima de 12 meses. En el caso de conductores entre 21-24 años se aplica un cargo por seguro de conductor joven y las reducciones de franquicia también tendrán un suplemento.",
            },
            {
                title: "¿Qué documentos debo presentar en el concesionario cuando vaya a recoger mi vehículo?",
                description: "Debes presentar el permiso de conducir, DNI y una tarjeta de crédito válida a tu nombre.",
            },
            {
                title: "¿Puedo alquilar el vehículo si he olvidado mi permiso de conducir?",
                description:
                    "No. Es obligatorio tener un permiso de conducir vigente para alquilar un vehículo. Este permiso debes presentarlo en el momento de la recogida.",
            },
            {
                title: "¿Puedo alquilar el vehículo con el carnet de conducir internacional?",
                description:
                    "Sí, siempre y cuando vaya acompañado del carnet original ya que por sí solo no es suficiente. Es importante que tengas en cuenta que las autoridades pueden requerir el carnet internacional si el permiso de conducir es de fuera de la UE y retener el vehículo sino se aporta.",
            },
            {
                title: "Mi permiso de conducir caducará pronto, ¿puedo alquilar un vehículo?",
                description:
                    "Sí, siempre y cuando la fecha de expiración sea posterior a la fecha de devolución del vehículo alquilado.",
            },
            {
                title: "¿Puedo reservar y pagar un vehículo y que otra persona lo recoja y conduzca?",
                description:
                    "Sí. Puedes alquilarlo aunque no seas el conductor, pero debes estar presente en el momento de recogida (o antes) y mostrar el documento de identidad y la tarjeta de crédito en la que se efectuará el cobro y bloqueo de seguridad.",
            },
            {
                title: "¿Puedo añadir conductores adicionales?",
                description:
                    "Sí, es posible incluir un segundo conductor en el contrato. Las personas que vayan a conducir deben presentar sus permisos de conducir y firmar el contrato de alquiler cuando recojan el vehículo.",
            },
            {
                title: "¿Puedo cambiar las fechas de una reserva?",
                description:
                    "Si el vehículo aún no se ha recogido, puedes cambiar la reserva de alquiler con una antelación mínima de 24 horas llamando al concesionario VW FS | Rent-a-Car de recogida.",
            },
            {
                title: "¿Se cobrará una fianza al recoger el coche?",
                description:
                    "Al recoger el vehículo se bloquea un importe en la tarjeta de crédito a modo de autorización previa. En servicios de movilidad superiores a 28 días, dicha autorización se renueva. Si el concesionario VW FS | Rent-a-Car acepta, en circunstancias excepcionales, tarjetas de débito para alquileres de duración inferior a 28 días, se cobra la fianza y se reembolsará a la devolución del vehículo. No se aceptan fianzas en efectivo.",
            },
            {
                title: "¿Cuánto tarda en desbloquearse la fianza?",
                description:
                    "El desbloqueo se realiza de manera automática tras la devolución del vehículo y el abono de la fianza en el saldo de la tarjeta en un plazo aproximado de entre 15 y 28 días (dependerá de la entidad bancaria). Este importe se devuelve en la cuenta asociada a la tarjeta de crédito utilizada para realizar el depósito.",
            },
            {
                title: "¿El alquiler tiene un límite de kilometraje?",
                description:
                    "Sí, las tarifas tienen un kilometraje limitado. En el contrato de alquiler se muestra este límite que varía en función de la duración del alquiler y también el recargo adicional por kilómetro de exceso, en caso de superarlo.",
            },
            {
                title: "¿Qué tipo de cobertura incluye la reserva de alquiler?",
                description:
                    "VW FS | Rent-a-Car incluye en su tarifa de alquiler el seguro obligatorio del automóvil de acuerdo a la legislación vigente, seguro de ocupantes del vehículo y cobertura a terceros. También está incluida una cobertura de daños y de robo (CDW) con franquicia de 600 euros.",
            },
            {
                title: "¿Qué es una franquicia y cómo funciona?",
                description:
                    "Una franquicia es la cantidad máxima a pagar en caso de daños al vehículo. Ese máximo no aplica en casos de negligencia.",
            },
            {
                title: "¿Puedo contratar un seguro adicional?",
                description:
                    "Los vehículos en alquiler siempre incluyen una cobertura básica que consiste en un seguro a todo riesgo con franquicia de 600 euros. Durante el proceso de reserva y contratación, se puede ampliar dicha cobertura y contratar la franquicia 0 o la franquicia 300. El precio de la ampliación dependerá de la edad de los conductores, de la duración del servicio de movilidad y del tipo de vehículo.",
            },
            {
                title: "¿Puedo cambiar la reserva de un vehículo a uno de categoría superior?",
                description:
                    "En el momento de la recogida, el asesor VW FS | Rent-a-Car puede informarte sobre los vehículos disponibles en el periodo de alquiler y de la diferencia en el precio para poder realizar el cambio.",
            },
            {
                title: "¿Puedo elegir el modelo de vehículo?",
                description:
                    "Sí. Una de las ventajas de VW FS | Rent-a-Car es que siempre se garantiza el modelo reservado, por lo que siempre será el vehículo que hayas escogido en el momento de la reserva.",
            },
            {
                title: "¿Puedo llevar animales en los vehículos de alquiler?",
                description:
                    "Solo es posible llevar animales de asistencia para personas con discapacidad o minusvalías.",
            },
            {
                title: "¿Puedo disponer de asientos infantiles?",
                description:
                    "Para saber si puedes tener a tu disposición este elemento, deberás realizar la consulta directamente en el concesionario VW FS | Rent-a-Car donde quieras hacer la reserva.",
            },
            {
                title: "¿Existe servicio de entrega de vehículos a domicilio?",
                description:
                    "Para saber si puedes tener a tu disposición este servicio, deberás realizar la consulta directamente en el concesionario VW FS | Rent-a-Car donde quieras hacer la reserva.",
            },
            {
                title: "¿Debo verificar el estado del vehículo en el momento de la recogida y antes de abandonar las instalaciones?",
                description:
                    "En el momento de la recogida del vehículo un asesor de VW FS | Rent-a-Car revisará el estado del vehículo contigo y te enviará por email el protocolo de devolución del mismo. Además, este asesor estará a tu disposición para cualquier duda en cuanto al funcionamiento del vehículo y el proceso de alquiler.",
            },
        ],
    },
    {
        title: "Durante el alquiler",
        faqs: [
            {
                title: "¿Quién está autorizado a conducir el vehículo?, ¿es posible añadir un conductor durante el contrato de alquiler?",
                description:
                    "Las únicas personas autorizadas a conducir el vehículo son aquellas que figuren como conductor principal o conductor adicional en el contrato de alquiler. En el caso de que quieras añadir un conductor durante la vigencia del contrato, tendrás que pasar nuevamente por el concesionario VW FS | Rent-a-Car para incluirlo en el contrato. El nuevo conductor también deberá estar presente con su carnet de conducir y documento de identidad en vigor. Es importante que tengas en cuenta que si se produce cualquier incidencia con un conductor no autorizado, las coberturas contratadas (el seguro del vehículo) no podrían aplicarse.",
            },
            {
                title: "¿Puedo prolongar la duración del contrato de alquiler?",
                description:
                    "En el caso de que el vehículo ya lo hayas recogido, deberás ponerte en contacto con el concesionario VW FS | Rent-a-Car para que te informen de cómo proceder.",
            },
            {
                title: "¿El contrato de alquiler permite circular entre islas?",
                description:
                    "No. Está prohibido transportar el vehículo en un barco o ferry y por lo tanto no es posible circular entre islas o de territorio peninsular a insular y viceversa.",
            },
            {
                title: "¿Puedo circular fuera de España?",
                description:
                    "Si quieres  circular fuera de España, debes ponerte en contacto con el concesionario VW FS | Rent-a-Car previamente para que te informen de si es posible o no. ",
            },
            {
                title: "¿Qué debo hacer en caso de pérdida, rotura o robo de la llave del vehículo?",
                description:
                    "En cualquiera de los tres casos, deberás notificarlo con la mayor brevedad posible al concesionario de recogida y asumir los gastos que genere la petición de la nueva llave puesto que no está cubierto por el seguro. Además, en caso de pérdida o robo puede ser necesario inmovilizar el vehículo para mitigar riesgo de robo del vehículo. Si es así, se imputará el coste al cliente. Si la llave ha sido robada será necesario efectuar la denuncia pertinente ante las autoridades y entregar copia de la misma a la concesión.",
            },
            {
                title: "¿Qué debo hacer en caso de robo del vehículo?",
                description:
                    "En primer lugar debes denunciar el robo de manera inmediata a la autoridad competente y a continuación, avisar al concesionario VW FS | Rent-a-Car de recogida.",
            },
            {
                title: "¿Qué sucede si roban mis pertenencias del interior del vehículo?",
                description:
                    "Los objetos personales no se incluyen en la cobertura del seguro, por lo tanto, el arrendador no se hace responsable de las mismas. En el caso de que el vehículo sufra daños a consecuencia del robo, no existe una reducción de franquicia, por lo que deberás abonar los costes. La recomendación es no dejar nunca pertenencias a la vista en el interior del vehículo y sin vigilancia.",
            },
            {
                title: "¿Cómo recupero el vehículo si lo retira la grúa municipal?",
                description:
                    "Deberás dirigirte a la Policía Local de la zona para posteriormente ir al depósito municipal de vehículos correspondiente y abonar la multa más los gastos de arrastre del vehículo. El concesionario VW FS | Rent-a-Car no se hace responsable de la recuperación del vehículo en estos casos. En el caso de que el contrato de alquiler hubiese finalizado durante este trámite, deberás informar al concesionario para que lo prorrogue. Nota: Es imprescindible presentar el contrato de alquiler y DNI para recuperar el vehículo.",
            },
        ],
    },
    {
        title: "Devolución del vehículo",
        faqs: [
            {
                title: "¿Cuánto tarda el proceso de devolución?",
                description:
                    "El proceso de devolución suele tardar unos minutos. De todas formas, debes tener en cuenta que pueden surgir situaciones o imprevistos que demoren este proceso un poco más. En cualquier caso, el asesor VW FS | Rent-a-Car siempre intentará realizar todos los trámites necesarios lo más rápido posible.",
            },
            {
                title: "¿Qué sucede si entrego el vehículo más tarde de lo establecido en el contrato?",
                description:
                    "Todos los contratos de alquiler tienen un periodo de cortesía pasada la hora de devolución. Pero tras este tiempo de cortesía, el concesionario VW FS | Rent-a-Car podrá aplicar las penalizaciones reflejadas en el contrato.",
            },
            {
                title: "¿Tengo que devolver el vehículo con el depósito lleno?",
                description:
                    "Sí. El contrato de alquiler se ha fijado con la política de combustible lleno-lleno, por lo que debes devolverlo con el repostaje realizado. En caso contrario, se cobrarán los litros faltantes y el coste del servicio del llenado. De todas formas, en el momento de recoger el vehículo al inicio del contrato, el asesor te ofrecerá la opción de prepago de llenado de combustible a precio de mercado.",
            },
            {
                title: "¿Qué me cobran en caso de producirse daños en el vehículo?",
                description:
                    "Si se detectan nuevos daños al devolver el vehículo, se cobrará la franquicia correspondiente –salvo en casos de negligencia- . Si el coste final de la reparación es inferior a la franquicia cobrada, se reembolsará la diferencia. Si se demostrara que fue un tercero el responsable de los daños y la aseguradora puede reclamar los gastos ocasionados, la franquicia pagada se podrá reembolsar íntegramente.",
            },
            {
                title: "Si el vehículo lo devuelvo antes de finalizar el contrato, ¿me reembolsan parte del importe?",
                description:
                    "No. En el contrato se establece la duración del alquiler y no se reembolsa ningún importe cobrado por el servicio.",
            },
            {
                title: "¿Puedo devolver un vehículo fuera del horario de apertura del concesionario?",
                description:
                    "Para saber si puedes devolver el vehículo fuera de horario, deberás realizar la consulta directamente en el concesionario VW FS | Rent-a-Car donde hiciste la reserva.",
            },
            {
                title: "¿Qué hago si olvido objetos personales en el vehículo?",
                description:
                    "En primer lugar, se recomienda revisar bien el vehículo antes de su devolución y abandonar las instalaciones del concesionario, puesto que VW FS | Rent-a-Car no se hace responsable de las pertenencias olvidadas. En cualquier caso, puedes llamar directamente al concesionario donde realizaste el alquiler para preguntar si las encontraron y las tienen guardadas.",
            },
        ],
    },
    {
        title: "Métodos de pago",
        faqs: [
            {
                title: "¿Puedo pagar con tarjeta de débito?",
                description:
                    "Sí, pero el depósito de seguridad debe pagarse con tarjeta de crédito. De todas formas, y de manera excepcional, en alquileres inferiores a 28 días el concesionario VW FS | Rent-a-Car podría aceptar cobrar la fianza en tarjeta de débito.",
            },
            {
                title: "¿Puedo pagar en metálico?",
                description:
                    "Sí, se admiten pagos en metálico una vez finalizado el servicio de movilidad, pero en ningún caso se aceptará que el depósito se realice mediante esta forma de pago.",
            },
            {
                title: "En caso de dudas con la factura, ¿con quién debo contactar?",
                description:
                    "En caso de dudas sobre la factura, puedes ponerte en contacto con el concesionario VW FS | Rent-a-Car donde recogiste el vehículo.",
            },
        ],
    },
    {
        title: "Tarifas",
        faqs: [
            {
                title: "¿Cómo se calcula mi tarifa de alquiler?",
                description:
                    "La tarifa total del alquiler se calcula a partir de la información facilitada al realizar la reserva. Se basa en la duración del alquiler, en la edad del conductor, en el periodo del año, en el tipo de vehículo alquilado, y en los extras seleccionados.",
            },
        ],
    },
    {
        title: " Multas de tráfico ",
        faqs: [
            {
                title: "¿Qué pasa si me ponen una multa?",
                description:
                    "En el caso de haber sido multado durante el contrato de alquiler, recibirás una notificación por parte del organismo sancionador o del concesionario VW FS | Rent-a-Car. Además, se efectuará un cargo en concepto de Tasas por Gestión de Multas, que se incluirá directamente en la factura final del alquiler.",
            },
            {
                title: "En caso de infracción ¿cómo se tramita el pago?",
                description:
                    "En el caso de cometer una infracción deberás dirigirte a la entidad emisora de la multa y seguir sus indicaciones para pagarla.",
            },
        ],
    },
];
