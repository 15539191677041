import React, { useEffect, useRef } from "react";

import { HeroTeaser, Main, ContentSection, Wrapper, Layout } from "@vwfs-bronson/bronson-react";

import { LandingBrandLogos } from "../components/Landing/LandingBrandLogos";
import { LandingInstallation } from "../components/Landing/LandingInstallation";
import { LandingBrandThreeSteps } from "../components/Landing/LandingBrandThreeSteps";
import { LandingBrandReason } from "../components/Landing/LandingBrandReason";
import { useDispatch } from "react-redux";
import { resetAllSummaryData } from "../actions/summaryAction";
import { isBlueLabel } from "../helpers/isBlueLabel";
import { getLandingURLByBrandName } from "../helpers/getLandingURLByBrandName";
import { isLiteSite } from "../helpers/isLiteSite";

export const LandingPage = () => {
    const ref = useRef();

    const dispatch = useDispatch();

    const scrollToLandingInstallation = () => {
        const yOffset = -105;
        const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
    };

    useEffect(() => {
        dispatch(resetAllSummaryData());
    }, [dispatch]);

    return (
        <>
            {!isLiteSite() && (
                <HeroTeaser
                    inverted
                    buttonText="Haz Tu Reserva"
                    imgProps={{
                        sizes: "100vw",
                        src: getLandingURLByBrandName(),
                    }}
                    onButtonClick={scrollToLandingInstallation}
                    sub="¿Capricho o necesidad?"
                    title={[
                        <>
                            Alquilar un coche
                            <br />
                            como siempre has querido
                        </>,
                    ]}
                />
            )}
            <Main>
                <ContentSection pageWrapSize="medium">
                    <Wrapper>
                        <Layout>
                            <Layout.Item>
                                <h3 ref={ref}> El modelo que eliges es el que conduces.</h3>
                            </Layout.Item>
                            <Layout.Item>
                                <LandingInstallation></LandingInstallation>
                            </Layout.Item>
                            {!isLiteSite() && (
                                <>
                                    <Layout.Item>
                                        <h3>
                                            {" "}
                                            El alquiler como debería ser: Volkswagen Financial Services | Rent-a-Car{" "}
                                        </h3>
                                    </Layout.Item>
                                    <Layout.Item>
                                        <LandingBrandReason></LandingBrandReason>
                                    </Layout.Item>
                                    <Layout.Item>
                                        <h3> Llévate el vehículo que quieres en solo tres pasos </h3>
                                    </Layout.Item>
                                    <Layout.Item>
                                        <LandingBrandThreeSteps></LandingBrandThreeSteps>
                                    </Layout.Item>
                                    {isBlueLabel() && (
                                        <>
                                            <Layout.Item>
                                                <h3> Conduce siempre las mejores marcas </h3>
                                            </Layout.Item>
                                            <Layout.Item>
                                                <LandingBrandLogos></LandingBrandLogos>
                                            </Layout.Item>
                                        </>
                                    )}
                                </>
                            )}
                        </Layout>
                    </Wrapper>
                </ContentSection>
            </Main>
        </>
    );
};
