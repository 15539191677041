import React, { useRef, useState } from "react";

import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";

import { ReactComponent as InstallationSVG } from "./marcador-mapa.svg";

import { useInstallation } from "../../hooks/useInstallation";
import { useEffect } from "react";
import { adapterInstallationToGoogleMap } from "../../adapters/adapterInstallationToGoogleMap";

import { Badge, InteractionLayer } from "@vwfs-bronson/bronson-react";
import { GoogleMapIntallationInfo } from "./GoogleMapInstallationInfo";

import "./GoogleMap.scss";

export const GoogleMap = () => {
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [selectedInstallationId, setSelectedInstallationId] = useState(null);
  const [hideModal, setHideModal] = useState(true);
  const { installations } = useInstallation();

  const [mapConfig] = useState({
    defaultZoom: 6,
    defaultLat: 40.1301544,
    defaultLng: -3.690985,
  });

  const { clusters, supercluster } = useSupercluster({
    points: installations.map((x) => adapterInstallationToGoogleMap(x)),
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  useEffect(() => {
    if (selectedInstallationId) setHideModal(false);
    else setHideModal(true);
  }, [selectedInstallationId]);

  return (
    <div style={{ height: "600px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={{ lat: mapConfig.defaultLat, lng: mapConfig.defaultLng }}
        defaultZoom={mapConfig.defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;

          if (isCluster) {
            let size = (pointCount * 20) / installations.length;

            return (
              <Marker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <div
                  className="cluster-marker"
                  style={{ width: size + "px", height: size + "px" }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  }}
                >
                  <Badge className={"installationBadge"}>{pointCount}</Badge>
                </div>
              </Marker>
            );
          } else {
            return (
              <Marker
                key={`installation-${cluster.properties.installationId}`}
                lat={latitude}
                lng={longitude}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setSelectedInstallationId(cluster.properties.installationId)
                  }
                >
                  <Badge
                    className={`installationBadge ${
                      cluster.properties.installationId ===
                        selectedInstallationId && "selected"
                    }`}
                  >
                    <InstallationSVG />
                  </Badge>
                </div>
              </Marker>
            );
          }
        })}
      </GoogleMapReact>
      <InteractionLayer
        isHidden={hideModal}
        onClose={() => {
          setHideModal(true);
          setSelectedInstallationId(null);
        }}
        size="small"
      >
        {selectedInstallationId && (
          <GoogleMapIntallationInfo
            installationFiltered={installations.filter(
              (x) => x.id === selectedInstallationId
            )}
          ></GoogleMapIntallationInfo>
        )}
      </InteractionLayer>
    </div>
  );
};

const Marker = ({ children }) => children;
