import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addNotificationError, addNotificationSuccess } from "../actions/notificationAction";
import { postRequest } from "../config/axiosClient";

import { Layout, Button, Card, Modal } from "@vwfs-bronson/bronson-react";
import { useSummary } from "../hooks/useSummary";
import { setSelectedBookingNumber } from "../actions/summaryAction";
import moment from "moment/moment";

export const CancelBooking = () => {
    const dispatch = useDispatch();

    const [showConfirmCancel, setShowConfirmCancel] = useState(false);

    const { bookingLocator, selectedContactData } = useSummary();

    const handleOnClick = async () => {
        setShowConfirmCancel(true);
    };

    const cancelBooking = async () => {
        try {
            var { data, status } = await postRequest("cancelBooking", {
                Locator: bookingLocator.Locator,
                Email: selectedContactData.Email,
            });

            setShowConfirmCancel(false);

            if (status === 404) {
                dispatch(addNotificationError("No se ha encontrado la reserva"));
                return;
            }

            if (data === 1) {
                dispatch(addNotificationSuccess("Reserva cancelada correctamente."));
                dispatch(
                    setSelectedBookingNumber({
                        Locator: bookingLocator.Locator,
                        Status: "CA",
                        ModificationDate: moment().toDate(),
                    })
                );
                return;
            }

            if (data === 0) {
                dispatch(addNotificationError("Ha ocurrido un error y no se ha podido cancelar la reserva"));
                return;
            }
        } catch (error) {
            dispatch(addNotificationError("Ha ocurrido un error y no se puede cancelar la reserva"));
        }
    };

    return (
        <Layout.Item>
            <Card element="article" className="u-text-center">
                <p>Puedes cancelar la reserva en el siguiente link</p>
                <Button onClick={handleOnClick}>Cancelar</Button>
            </Card>
            <Modal
                buttonCloseLabel="Close this dialog window."
                buttonConfirmText={"Confirmar"}
                buttonConfirmType="submit"
                id="modalId"
                center
                onClickOutside={() => {}}
                onClose={() => setShowConfirmCancel(false)}
                onConfirm={cancelBooking}
                shown={showConfirmCancel}
                title="Confirmación"
            >
                ¿Estás seguro que quieres cancelar la reserva?
            </Modal>
        </Layout.Item>
    );
};
