import { postRequest } from "../config/axiosClient";
import { types } from "../types/types";
import { addNotificationError } from "./notificationAction";

export const getOptionals = (obj = {}) => {
    return async (dispatch) => {
        try {
            dispatch(setOptionalsLoading(true));
            var { data } = await postRequest("rentals/optionals", obj);
            var { insurance, driver } = data;
            if (driver) {
                dispatch(getAdditionalDriverLoaded(driver));
            }
            if (insurance) {
                dispatch(getCoveragesLoaded(insurance));
            }
            dispatch(setOptionalsLoading(false));
        } catch (error) {
            dispatch(addNotificationError("Ha ocurrido un error al obtener los opcionales."));
        }
    };
};

export const getExtras = (obj = {}) => {
    return async (dispatch) => {
        try {
            dispatch(setExtrasLoading(true));
            var { data } = await postRequest("rentals/extras", obj);
            dispatch(getExtrasLoaded(data));

            dispatch(setExtrasLoading(false));
        } catch (error) {
            dispatch(addNotificationError("Ha ocurrido un error al obtener los extras."));
        }
    };
};

export const getCoveragesLoaded = (payload) => ({ type: types.optionalGetCoverages, payload });

export const setOptionalsLoading = (payload) => ({ type: types.optionalSetOptionalsLoading, payload });

export const getAdditionalDriverLoaded = (payload) => ({ type: types.optionalGetAdditionalDriver, payload });

export const getExtrasLoaded = (payload) => ({ type: types.optionalGetExtras, payload });

export const setExtrasLoading = (payload) => ({ type: types.optionalSetExtrasLoading, payload });

export const resetOptionalData = (payload) => ({ type: types.optionalResetData, payload });
