import React from "react";
import { InlineGroup, Tooltip } from "@vwfs-bronson/bronson-react";

import "./VehicleInfoIcons.scss";

export const VehicleInfoIcons = ({ vehicle, align = "center" }) => {
    if (!vehicle) return null;

    const { airConSystem, bluetooth, colorHtml, gps, transmission } = vehicle;

    return (
        <InlineGroup className={"vehicleInfoIcons"} align={align}>
            {colorHtml && (
                <Tooltip content="Color">
                    <span className="round-color" style={{ backgroundColor: `#${colorHtml}` }}></span>
                </Tooltip>
            )}
            {airConSystem === "C" && (
                <Tooltip content="Climatizador">
                    <img src="/images/icons/aircon-icon.svg" alt="" />
                </Tooltip>
            )}
            {airConSystem === "A" && (
                <Tooltip content="Aire acondicionado">
                    <img src="/images/icons/ac-icon.svg" alt="" />
                </Tooltip>
            )}
            {bluetooth && (
                <Tooltip content="Bluetooth">
                    <img src="/images/icons/bluetooth-icon.svg" alt="" />
                </Tooltip>
            )}
            {gps && (
                <Tooltip content="GPS">
                    <img src="/images/icons/gps-icon.svg" alt="" />
                </Tooltip>
            )}
            {transmission && (
                <Tooltip content={transmission.trim() === "M" ? "Manual" : "Automático"}>
                    <img src="/images/icons/transmission-icon.svg" alt="" />
                </Tooltip>
            )}
        </InlineGroup>
    );
};
