export const adapterAvailableVehiclesToFamilies = (vehicles) => {
    let res = [];
    vehicles.forEach((v) => {
        v.vehicle.price = Math.abs(v.price);
        v.vehicle.totalWithInPromotionalCode = Math.abs(v.totalWithInPromotionalCode);
        v.vehicle.totalWithInPromotionalCodeByDay = Math.abs(v.totalWithInPromotionalCodeByDay);
        v.vehicle.promotionalCode = v.promotionalCode;
        v.vehicle.diasEnContratoPromotionalCode = v.diasEnContratoPromotionalCode;
        v.vehicle.idCodPromocion=v.idCodigoPromocion;
        v.vehicle.promotionalCodeAdjust = v.promotionalCodeAdjust;
        v.vehicle.totalByDay = Math.abs(v.totalByDay);
        v.vehicle.total = Math.abs(v.total);

        var indexSearch = res.findIndex((x) => x.name === v.vehicle.family);
        if (indexSearch === -1) {
            res.push({ name: v.vehicle.family, vehicles: [v.vehicle], priceFrom: v.price, priceTo: v.price });
        } else {
            if (res[indexSearch].priceFrom > v.price) res[indexSearch].priceFrom = v.price;
            if (res[indexSearch].priceTo < v.price) res[indexSearch].priceTo = v.price;
            res[indexSearch].vehicles.push(v.vehicle);
        }
    });

    return res;
}; 
