import React from "react";

import { SiteNav, Header, Logo } from "@vwfs-bronson/bronson-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetAllSummaryData } from "../actions/summaryAction";
import { isPorsche } from "../helpers/isPorsche";
import { isAudi } from "../helpers/isAudi";
import { isLcv } from "../helpers/isLcv";

export const CustomHeader = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();

    const handleOnClick = () => {
        if (location.pathname === "/") {
            window.location.reload();
        } else {
            navigate("/");
        }
    };

    const handleOnClickGestionaReserva = () => {
        dispatch(resetAllSummaryData());
        navigate("/booking-confirm");
    };

    const logoSelector = () => {
        if (isPorsche())
            return <Logo specificLogo={"porsche"} onClick={handleOnClick} ariaLabel="Logo" tabIndex={0} title="Logo" />;
        if (isLcv())
            return <Logo specificLogo={"commercial-vehicle-es"} onClick={handleOnClick} ariaLabel="Logo" tabIndex={0} title="Logo" />;
        return <Logo onClick={handleOnClick} ariaLabel="Logo" tabIndex={0} title="Logo" />;
    };

    return (
        <Header logo={logoSelector()}>
            <SiteNav>
                <SiteNav.Area>
                    <SiteNav.Item label="Inicio" onClick={() => navigate("/")} />
                    <SiteNav.Item label="Gestiona tu reserva" onClick={handleOnClickGestionaReserva} />
                    <SiteNav.Item label="Sobre nosotros" onClick={() => navigate("/aboutus")} />
                    <SiteNav.Item label="Preguntas frecuentes" onClick={() => navigate("/faqs")} />
                    <SiteNav.Item label="Contacto" onClick={() => navigate("/contact")} />
                </SiteNav.Area>
                {!isPorsche() && !isAudi() &&
                <SiteNav.Area>
                    <img
                        src="/images/logos/header-logo.png"
                        style={{ maxWidth: "200px", cursor: "pointer" }}
                        onClick={() => navigate("/")}
                        alt=""
                    />
                </SiteNav.Area>}
            </SiteNav>
        </Header>
    );
};
