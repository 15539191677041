/** @format */

import { Layout, Modal } from "@vwfs-bronson/bronson-react";
import { useContext, useEffect } from "react";
import { CookiesGenericCustomizationModal } from "./CookiesGenericCustomizationModal";
import CookiesContext from "../../../../context/CookiesContextProvider";

export const CookiesGenericModal = () => {
  const {
    showConfig,
    setShowConfig,
    setCookies,
    loadScript,
    showModal,
    setShowModal,
  } = useContext(CookiesContext);

  useEffect(() => {
    loadScript();
  });
  const handleRejectAll = async () => {
    setTimeout(() => {
      setCookies({
        analiticas: false,
        personalizacion: false,
        marketing: false,
      });
      closeModal();
    }, 1000);
  };
  const handleAcceptAll = () => {
    setTimeout(() => {
      setCookies({
        analiticas: true,
        personalizacion: true,
        marketing: true,
      });
      closeModal();
    }, 1000);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <Modal
        buttonCancelText="Rechazar Cookies"
        buttonConfirmText="Acepto"
        center
        hideCloseButton
        onCancel={handleRejectAll}
        onClose={closeModal}
        onConfirm={handleAcceptAll}
        shown={showModal}
        title="Configuración de las cookies"
      >
        <span>
          Utilizamos cookies y tecnologías similares, propias y de terceros,
          para personalizar los servicios que te ofrecemos, analizar el uso del
          sitio web y mostrarte publicidad relacionada con tus preferencias
          sobre la base de un perfil elaborado a partir de tus hábitos de
          navegación (por ejemplo, páginas visitadas). Puedes aceptar o rechazar
          las cookies en los botones correspondientes. También puedes elegir qué
          cookies aceptar haciendo click en &ldquo;Personaliza tus
          cookies&ldquo;. Para más información, consulta la Política de cookies{" "}
          <a href="/#/cookies-policy">AQUÍ</a>.
        </span>
        <Layout>
          <Layout.Item className="u-text-center">
            <a onClick={() => setShowConfig(true)}>Personalizar Cookies</a>
          </Layout.Item>
        </Layout>
      </Modal>
      {showConfig && <CookiesGenericCustomizationModal />}
    </>
  );
};
