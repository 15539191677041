import React from "react";

import { HeroTeaser, Main, ContentSection, Wrapper, Layout } from "@vwfs-bronson/bronson-react";

export const AboutUsPage = () => {
    return (
        <>
            <HeroTeaser
                inverted
                imgProps={{
                    sizes: "100vw",
                    src: `/images/cabeceras/Header-about-us.jpg`,
                }}
                auto
                title={"Sobre nosotros"}
            />
            <Main>
                <ContentSection equalHeight pageWrapSize="medium">
                    <Wrapper>
                        <Layout>
                            <Layout.Item>
                                <h4 className="u-text-center">Volkswagen Financial Services | Rent-a-Car</h4>
                                <p>
                                    En Volkswagen Financial Services apostamos por un nuevo servicio de alquiler de
                                    vehículos a través de nuestra red profesional de concesionarios. Porque alquilar un
                                    vehículo debería ser algo fácil, rápido y simple, con un precio claro que incluya
                                    todo lo que necesitas.
                                </p>
                            </Layout.Item>
                        </Layout>
                        <Layout>
                            <Layout.Item default="2/5" m="1/2" s="1/1">
                                <img alt="sobre nosotros 1" src="/images/aboutUs/1.jpg" />
                            </Layout.Item>
                            <Layout.Item default="3/5" m="1/2" s="1/1">
                                <h4>El vehículo que reservas es el que conduces</h4>
                                <p>
                                    Te garantizamos que siempre conducirás el vehículo que has reservado. Escoge entre
                                    la gran variedad de modelos del Grupo Volkswagen y olvídate de "categorías" o
                                    "similares".
                                </p>
                            </Layout.Item>
                            <Layout.Item default="2/5" m="1/2" s="1/1">
                                <img alt="sobre nosotros 2" src="/images/aboutUs/2.jpg" />
                            </Layout.Item>
                            <Layout.Item default="3/5" m="1/2" s="1/1">
                                <h4>Reserva tu coche el tiempo que quieras</h4>
                                <p>
                                    ¿Lo necesitas durante un día? ¿O quizás un fin de semana? No te preocupes,
                                    tienes flexibilidad para alquilarlo el tiempo que quieras.
                                </p>
                            </Layout.Item>
                            <Layout.Item default="2/5" m="1/2" s="1/1">
                                <img alt="sobre nosotros 3" src="/images/aboutUs/3.jpg" />
                            </Layout.Item>
                            <Layout.Item default="3/5" m="1/2" s="1/1">
                                <h4>Los precios claros existen</h4>
                                <p>
                                    El precio que ves en la reserva es el que pagas, tan simple como eso. No hay gastos
                                    ocultos ni extras que se aplican sin saber por qué: en la reserva aparecen todos los
                                    conceptos de manera clara y sencilla.
                                </p>
                            </Layout.Item>
                            <Layout.Item default="2/5" m="1/2" s="1/1">
                                <img alt="sobre nosotros 4" src="/images/aboutUs/4.jpg" />
                            </Layout.Item>
                            <Layout.Item default="3/5" m="1/2" s="1/1">
                                <h4>Asesoramiento especializado</h4>
                                <p>
                                    Para tu comodidad, la recogida y devolución del vehículo se realiza en los
                                    concesionarios oficiales de nuestras marcas. Esto quiere decir que cuentas en todo
                                    momento con asesores expertos que te ayudan a escoger según tus necesidades y
                                    preferencias.
                                </p>
                            </Layout.Item>
                            <Layout.Item default="2/5" m="1/2" s="1/1">
                                <img alt="sobre nosotros 5" src="/images/aboutUs/5.jpg" />
                            </Layout.Item>
                            <Layout.Item default="3/5" m="1/2" s="1/1">
                                <h4>Seguridad y transparencia</h4>
                                <p>
                                    Olvídate de productos adicionales complejos e innecesarios. Disfruta del vehículo
                                    que reservas con la garantía de un seguro a todo riesgo con una franquicia que
                                    podrás reducir gracias a un único producto de protección total.
                                </p>
                            </Layout.Item>
                            <Layout.Item default="2/5" m="1/2" s="1/1">
                                <img alt="sobre nosotros 6" src="/images/aboutUs/6.jpg" />
                            </Layout.Item>
                            <Layout.Item default="3/5" m="1/2" s="1/1">
                                <h4>Con la garantía y calidad del Grupo Volkswagen</h4>
                                <p>
                                    Además de garantizarte vehículos de última generación y totalmente equipados,
                                    ponemos a tu disposición una amplia gama de accesorios para que disfrutes de una
                                    experiencia completa con Volkswagen Financial Services | Rent-a-Car.
                                </p>
                            </Layout.Item>
                            <Layout.Item>
                                <p>
                                    Todo el proceso está respaldado por el Grupo Volkswagen, la mayor compañía
                                    automovilística de Europa. Ponemos a tu disposición el vehículo que quieres, cuando
                                    lo quieres, con un precio justo y transparente, para que disfrutes de la movilidad
                                    con total tranquilidad.
                                </p>
                            </Layout.Item>
                        </Layout>
                    </Wrapper>
                </ContentSection>
            </Main>
        </>
    );
};
