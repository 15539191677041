/** @format */

import React, { useEffect } from "react";

import { Layout, IconList } from "@vwfs-bronson/bronson-react";
import { useGlobal } from "../../hooks/useGlobal";
import { Thumbnail } from "../Thumbnail";
import { renderAppImage } from "../../helpers/renderAppImage";
import { useDispatch } from "react-redux";
import { setSelectedInstallation } from "../../actions/summaryAction";
import { AccordionSchedulesInfo } from "../AcordionSchedulesInfo";

export const GoogleMapIntallationInfo = ({ installationFiltered }) => {
  const dispatch = useDispatch();

  const { imagePath } = useGlobal();

  useEffect(() => {
    if (installationFiltered) {
      dispatch(setSelectedInstallation(installationFiltered[0]));
      document
        .getElementsByClassName("c-combobox__value-presentation__text")
        .item(
          0
        ).textContent = `${installationFiltered[0].name} (${installationFiltered[0].province})`;
    }
  }, [dispatch, installationFiltered]);

  if (!installationFiltered) return null;

  var installation = installationFiltered[0];

  return (
    <Layout flush>
      <Layout.Item className={"u-text-center"}>
        <Thumbnail
          src={renderAppImage(imagePath, installation.image)}
          alt={installation.name}
          aspectRatio="2 / 1"
        ></Thumbnail>
      </Layout.Item>
      <Layout.Item>
        <h5>{installation.name}</h5>
        <IconList>
          <>
            <IconList.Item icon="location">
              <a
                href={`https://maps.google.com/?q=${installation.address}`}
                target="_blank"
                rel="noreferrer"
              >
                {installation.address}
              </a>
            </IconList.Item>
            <IconList.Item icon="phone">
              <a href={`tel:${installation.phone}`}>{installation.phone}</a>
            </IconList.Item>
            <IconList.Item icon="mail">
              <a href={`mailto:${installation.email}`}>{installation.email}</a>
            </IconList.Item>
          </>
        </IconList>
        <div className="u-mt-small">
          <AccordionSchedulesInfo
            installation={installation}
            title={"Horario de apertura"}
          />
        </div>
      </Layout.Item>
    </Layout>
  );
};
