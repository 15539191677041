export const formatToDate = (date) => {
    if (date) {
        let dateaux = new Date(date);
        var dd = dateaux.getDate();
        var mm = dateaux.getMonth() + 1;
        var yyyy = dateaux.getFullYear();
        if (dd < 10) {
            dd = "0" + dd;
        }

        if (mm < 10) {
            mm = "0" + mm;
        }
        dateaux = dd + "/" + mm + "/" + yyyy;

        return dateaux;
    } else {
        return "Sin fecha";
    }
};
