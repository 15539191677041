import moment from "moment";

export const adapterAvailableSearchFormRequest = ({
    StartDate,
    StartTime,
    EndDate,
    EndTime,
    installationId,
    fareId,
    PromotionalCode,
}) => {
    return {
        startDate: moment(StartDate + " " + StartTime, "DD/MM/YYYY HH:mm A").toDate(),
        endDate: moment(EndDate + " " + EndTime, "DD/MM/YYYY HH:mm A").toDate(),
        fareId,
        installationId: parseInt(installationId),
        promotionalCode:PromotionalCode
    };
};
