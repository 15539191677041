import React from "react";

import { Card, Layout, Button } from "@vwfs-bronson/bronson-react";
import { useOptional } from "../../hooks/useOptional";
import { financialFormat } from "../../helpers/financialFormat";
import { useSummary } from "../../hooks/useSummary";
import { useDispatch } from "react-redux";
import { resetAdditionalDriverSelected, setAdditionalDriverSelected } from "../../actions/summaryAction";
import { showAdditionalDriverInfo } from "../../helpers/showAdditionalDriverInfo";

export const AdditionalDriver = () => {
    const dispatch = useDispatch();

    const { additionalDriver } = useOptional();
    const { selectedAdditionalDriver } = useSummary();

    if (!additionalDriver) return null;

    const { totalByDay, total } = additionalDriver;

    return (
        <Card element="article" className="u-text-left">
            <h5>{showAdditionalDriverInfo("name")}</h5>
            <p>{showAdditionalDriverInfo("text")}</p>
            <hr className="u-mb-small" />
            <Layout flush>
                <Layout.Item default="1/2">
                    <h5 className="u-m-none">{financialFormat(Math.round(totalByDay * 100))} €/día</h5>
                    <p className="u-m-none">Total: {financialFormat(Math.round(total * 100))}</p>
                </Layout.Item>
                <Layout.Item default="1/2" className="u-text-right">
                    {!selectedAdditionalDriver ? (
                        <Button
                            icon="semantic-add"
                            icononly="true"
                            onClick={() =>
                                dispatch(
                                    setAdditionalDriverSelected({
                                        ...additionalDriver,
                                        name: showAdditionalDriverInfo("name"),
                                        text: showAdditionalDriverInfo("text"),
                                    })
                                )
                            }
                            secondary
                        ></Button>
                    ) : (
                        <Button
                            icon="semantic-checkmark"
                            icononly="true"
                            onClick={() => dispatch(resetAdditionalDriverSelected())}
                        ></Button>
                    )}
                </Layout.Item>
            </Layout>
        </Card>
    );
};
