import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Layout,
  Card,
  ProductInfoSummary,
  Button,
} from "@vwfs-bronson/bronson-react";

import { renderAppImage } from "../../helpers/renderAppImage";
import { useDispatch } from "react-redux";
import { useGlobal } from "../../hooks/useGlobal";
import { useSummary } from "../../hooks/useSummary";
import { setSelectedVehicle } from "../../actions/summaryAction";
import { VehicleInfoIcons } from "./VehicleInfoIcons";
import { financialFormat } from "../../helpers/financialFormat";
import { getRoundDaysBetweenDates } from "../../helpers/getRoundDaysBetweenDates";

import "./VehicleList.scss";

export const VehicleList = ({ selectedFamily }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { imagePath } = useGlobal();
  const { selectedVehicle, selectedDates } = useSummary();

  const onClickHandler = (vehicle) => {
    dispatch(setSelectedVehicle(vehicle));
  };

  useEffect(() => {
    if (selectedVehicle) {
      navigate("/optionals-summary");
    }
  }, [selectedVehicle, navigate]);

  return selectedFamily.vehicles.map((vehicle) => {
    const { id, images } = vehicle;

    const subtitleVehicleText = (v) => {
      var res = "";
      if (v.doors) res += `Puertas ${v.doors}`;
      if (v.kw) {
        if (res) res += " | ";
        res += `${v.kw} KW`;
      }
      if (v.passengers) {
        if (res) res += " | ";
        res += `${v.passengers} Plazas`;
      }

      if (v.transmission) {
        if (res) res += " | ";
        res += v.transmission.trim() === "M" ? "Manual" : "Automático";
      }

      return res;
    };

    return (
      <Layout.Item key={"Vehicles" + id}>
        <Card element="article">
          <Layout flush>
            <Layout.Item>
              <ProductInfoSummary
                imgAlt={selectedFamily.name}
                imgSrc={renderAppImage(imagePath, images[0])}
                subtitle={subtitleVehicleText(vehicle)}
                title={selectedFamily.name}
              />
            </Layout.Item>
            <Layout.Item>
              <hr className="u-mt-small" />
            </Layout.Item>
            <Layout.Item>
              <Layout flush>
                <Layout.Item default="1/4" l="1/2">
                  <VehicleInfoIcons vehicle={vehicle} />
                </Layout.Item>

                <Layout.Item
                  default="2/4"
                  l="1/2"
                  className="u-text-right u-mb-xxsmall"
                >
                  <h5 className="u-m-none">
                    {getRoundDaysBetweenDates(
                      selectedDates.startDate,
                      selectedDates.endDate,
                      true,
                      vehicle.timeUnitId
                    )}{" "}
                    :{" "}
                    <del
                      hidden={
                        (vehicle.totalWithInPromotionalCode.hasValue &&
                          vehicle.totalWithInPromotionalCode === 0) ||
                        !vehicle.totalWithInPromotionalCode
                      }
                    >
                      {financialFormat(
                        Math.round(vehicle.totalWithInPromotionalCode * 100)
                      )}
                      € <br></br>
                    </del>
                    {financialFormat(Math.round(vehicle.total * 100))} €
                  </h5>
                  <span className="u-font-size-fs-2">
                    Impuestos no incluidos{" "}
                  </span>
                </Layout.Item>
                <Layout.Item default="1/4" l="1/1" className="u-text-right">
                  <Button
                    onClick={() => onClickHandler(vehicle, selectedFamily)}
                  >
                    Reservar
                  </Button>
                </Layout.Item>
              </Layout>
            </Layout.Item>
          </Layout>
        </Card>
      </Layout.Item>
    );
  });
};
