import React, { useContext } from "react";
import CookiesContext from "../../../../context/CookiesContextProvider";
import { CookiesGenericCustomizationModal } from "./CookiesGenericCustomizationModal";
import { CookiesGenericModal } from "./CookiesGenericModal";

export const CookiesGeneric = () => {
    const { showConfig } = useContext(CookiesContext);

    return <>{showConfig ? <CookiesGenericCustomizationModal /> : <CookiesGenericModal />}</>;
};
