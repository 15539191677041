import React, { Fragment, useEffect, useState } from "react";

import {
    HeroTeaser,
    Main,
    ContentSection,
    Wrapper,
    Accordion,
    Layout,
    Input,
    Button,
} from "@vwfs-bronson/bronson-react";
import { faqsGroups } from "../models/Faqs";
import useDebounce from "../hooks/useDebounce";
import { removeAccents } from "../helpers/removeAccents";

export const FaqsPage = () => {
    const [search, setSearch] = useState("");
    const [faqsG, setFaqsG] = useState(faqsGroups);
    const debouncedValue = useDebounce(search, 400);

    useEffect(() => {
        let searchObj = [...faqsGroups];
        faqsGroups.forEach((faqsGroup, index) => {
            const faqs = faqsGroup.faqs.filter(
                (x) =>
                    removeAccents(x.title.toLowerCase()).includes(removeAccents(debouncedValue.toLowerCase())) &&
                    debouncedValue !== ""
            );

            faqsGroup.faqs.forEach((x) => (x.mark = false));

            if (faqs.length > 0) {
                searchObj[index].faqs.forEach((faq) => {
                    if (faqs.filter((x) => x.title === faq.title).length > 0) {
                        faq.mark = true;
                    }
                });
            }
        });
        setFaqsG(searchObj);
    }, [debouncedValue]);

    return (
        <>
            <HeroTeaser
                inverted
                imgProps={{
                    sizes: "100vw",
                    src: `/images/cabeceras/Header-faqs1.jpg`,
                }}
                auto
                title={"Preguntas frecuentes"}
            />
            <Main>
                <ContentSection equalHeight pageWrapSize="medium">
                    <Wrapper>
                        <Layout>
                            <Layout.Item className="u-mb">
                                <h5> ¿Te podemos ayudar? </h5>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <span style={{ width: "100%", marginRight: "10px" }}>
                                        <Input
                                            value={search}
                                            placeholder="Introduce el texto a buscar ..."
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                    </span>
                                    <Button icon="semantic-close" onClick={() => setSearch("")} icononly></Button>
                                </div>
                            </Layout.Item>
                            <Layout.Item>
                                {faqsG.map(({ title, faqs }, index) => (
                                    <Fragment key={"accordionGroup_" + index}>
                                        <h3>{title}</h3>
                                        <Accordion className="u-mb-large" multipleOpen withBackground>
                                            {faqs.map(({ title, description, mark }, index2) => {
                                                return (
                                                    <Accordion.Item
                                                        key={"accordionGroup_" + index + "_accordionItem_" + index2}
                                                        title={mark ? <mark>{title}</mark> : title}
                                                    >
                                                        {description}
                                                    </Accordion.Item>
                                                );
                                            })}
                                        </Accordion>
                                    </Fragment>
                                ))}
                            </Layout.Item>
                        </Layout>
                    </Wrapper>
                </ContentSection>
            </Main>
        </>
    );
};
