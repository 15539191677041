import React from "react";

import { Footer } from "@vwfs-bronson/bronson-react";
import moment from "moment";

export const FooterPage = () => {
    return (
        <Footer>
            <Footer.ModuleMetaNav copyrightText={`VWFS${moment().format("YYYY")}`}>
                <Footer.ModuleMetaNav.Item href="/legal-note">Aviso legal</Footer.ModuleMetaNav.Item>
                <Footer.ModuleMetaNav.Item href="/privacy-policy">Política de privacidad</Footer.ModuleMetaNav.Item>
                <Footer.ModuleMetaNav.Item href="/cookies-policy">Política de cookies</Footer.ModuleMetaNav.Item>
                <Footer.ModuleMetaNav.Item href="/aboutUs">Sobre nosotros</Footer.ModuleMetaNav.Item>
                <Footer.ModuleMetaNav.Item href="/faqs">Preguntas frecuentes</Footer.ModuleMetaNav.Item>
                <Footer.ModuleMetaNav.Item href="/contact">Contacto</Footer.ModuleMetaNav.Item>
                <Footer.ModuleMetaNav.Item href="/licenses">Aviso de licencia de terceros </Footer.ModuleMetaNav.Item>
            </Footer.ModuleMetaNav>
            <Footer.ModuleFootnotes footerText="Volkswagen Financial Services es una marca comercializada por Volkswagen Renting S.A., Avda. Bruselas, nº 34 - 28108 Alcobendas (Madrid), Registro Mercantil de Madrid, Tomo 2.138, Folio 63, Hoja nº M-37860, N.I.F. A80185051."></Footer.ModuleFootnotes>
        </Footer>
    );
};
