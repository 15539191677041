import moment from "moment";
export function installationFormValidateSchedules(schedules, formValues) {

  const startDateTime = formValues.StartDate + " " + formValues.StartTime;
  const endDateTime = formValues.EndDate + " " + formValues.EndTime;
  const startDayOfWeek = moment(startDateTime, "DD/MM/YYYY H:mm")
    .toDate()
    .getDay();
  const endDayOfWeek = moment(endDateTime, "DD/MM/YYYY HH:mm").toDate().getDay();
  const startTime = moment(startDateTime, "DD/MM/YYYY HH:mm").add(24, 'hours').format('HH:mm');
  const endTime = moment(endDateTime, "DD/MM/YYYY HH:mm").add(24, 'hours').format('HH:mm');
  const startDayExist = schedules.filter((x) => x.dayId === startDayOfWeek);
  const endDayExist = schedules.filter((x) => x.dayId === endDayOfWeek);
  var inicioCorrecto = false;
  var finCorrecto = false;
  if (startDayExist.length > 0 && endDayExist.length > 0) {
    startDayExist.forEach((element) => {
     

      if (element.open <= startTime && element.close >= startTime) {
        inicioCorrecto = true;
      }
    });
    endDayExist.forEach((element) => {
  
      if (element.open <= endTime && element.close >= endTime) {
        finCorrecto = true;
      }
    });
  }
  if (inicioCorrecto && finCorrecto) {
    return true;
  } else {
    return false;
  }
}
