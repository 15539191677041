const extrasInfoList = [
    {
        name: "Portabicicletas",
        text: "Disfruta al máximo de tus vacaciones llevando tu propia bicicleta.",
        img: "./bronson/" + process.env.REACT_APP_BRONSON_BRAND + "/img/portaBici.svg",
    },
    {
        name: "Porta esquís/snowboard",
        text: "Puedes llevar tus equís y  tabla de snow para tus próximas vacaciones a la nieve.",
        img: "./bronson/" + process.env.REACT_APP_BRONSON_BRAND + "/img/portaEsqui.svg",
    },
    {
        name: "Cadenas para nieve",
        text: "Ten todo previsto. Máxima seguridad y adherencia durante tus viajes de invierno.",
        img: "./bronson/" + process.env.REACT_APP_BRONSON_BRAND + "/img/cadenaNieve.svg",
    },
    {
        name: "Baca/baúl de carga sobre barras portantes.",
        text: "Puedes ampliar la capacidad de carga del vehículo.",
        img: "./bronson/" + process.env.REACT_APP_BRONSON_BRAND + "/img/baca.svg",
    },
    {
        name: "Navegador GPS",
        text: "Podrás llegar a tu destino siguiendo la mejor ruta.",
        img: "./bronson/" + process.env.REACT_APP_BRONSON_BRAND + "/img/gps.svg",
    },
    {
        name: "Silla bebé (hasta 1 año)",
        text: "Sistema de retención infantil indicado para bebés hasta un año de edad.",
        img: "./bronson/" + process.env.REACT_APP_BRONSON_BRAND + "/img/bb_1ano.svg",
    },
    {
        name: "Asiento infantil Grupo 0 (hasta 10 Kg.)",
        text: "Sistema de retención infantil indicado para niños de un año hasta 10kg de peso.",
        img: "./bronson/" + process.env.REACT_APP_BRONSON_BRAND + "/img/bb_13kg.svg",
    },
    {
        name: "Asiento infantil Grupo 1 (de 9 a 18 Kg.)",
        text: "Sistema de retención infantil  inidicado para niños de  9 a 18 kg de peso.",
        img: "./bronson/" + process.env.REACT_APP_BRONSON_BRAND + "/img/bb_18kg.svg",
    },
    {
        name: "Asiento infantil Grupo 2 (de 15 a 25 Kg.)",
        text: "Sistema de retención infantil para niños de 15 a  25 kg de peso.",
        img: "./bronson/" + process.env.REACT_APP_BRONSON_BRAND + "/img/bb_22kg.svg",
    },
    {
        name: "Asiento infantil Grupo 3 (de 22 a 36 Kg.)",
        text: "Sistema de retención infantil indicado para niños de  22 a 36 kg de peso.",
        img: "./bronson/" + process.env.REACT_APP_BRONSON_BRAND + "/img/bb_36kg.svg",
    },
    {
        name: "Elevador infantil",
        text: "Sistema de retención infantil que eleva al niño para que pueda hacer uso normal del cinturón de seguridad.",
        img: "./bronson/" + process.env.REACT_APP_BRONSON_BRAND + "/img/elevadorInfantil.svg",
    },
    {
        name: "Dispositivo WiFi",
        text: "Podrás estar conectado a internet desde varios dispositivos a la vez durante todo tu alquiler. Disfruta de una conexión rápida y fiable.",
        img: "./bronson/" + process.env.REACT_APP_BRONSON_BRAND + "/img/wifi.svg",
    },
    {
        name: "Tablet",
        text: "Solución todo en uno: la tablet podrá funcionar como GPS, te permitirá tener acceso a internet y también utilizarlo como punto Wi-Fi al que conectar otros dispostivos.",
        img: "./bronson/" + process.env.REACT_APP_BRONSON_BRAND + "/img/tablet.svg",
    },
    {
        name: "Remolques",
        text: "Aumenta la capacidad de cargalquilando un remolque que podrás enganchar al vehículo cuando lo necesites.",
        img: "./bronson/" + process.env.REACT_APP_BRONSON_BRAND + "/img/remolque.svg",
    },
    {
        name: "Carretillas",
        text: "Para facilitar el transporte, carga y descarga de los elementos que transportes en el vehículo de alquiler.",
        img: "./bronson/" + process.env.REACT_APP_BRONSON_BRAND + "/img/carretilla.svg",
    },
    {
        name: "Cuerdas para carga",
        text: "Podrá sujetar su carga para que evitar deslizamientos durante el viaje.",
        img: "./bronson/" + process.env.REACT_APP_BRONSON_BRAND + "/img/cuerdas.svg",
    },
];

export const showExtraInfo = (id, option = "name") => {
    return extrasInfoList[id - 1][option] || "Extra sin información";
};
