export const adapterInstallationsToProvinces = (installaciones) => {
    let res = [];

    installaciones.forEach((installation) => {
        var indexSearch = res.findIndex((x) => x.province === installation.province);
        if (indexSearch === -1) {
            res.push({ province: installation.province, installations: [installation] });
        } else {
            res[indexSearch].installations.push(installation);
        }
    });
    return res;
};
