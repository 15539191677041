import React, { useEffect, useState } from "react";

import { HeroTeaser, Main, ContentSection, Wrapper, IconList, Accordion, Card } from "@vwfs-bronson/bronson-react";
import { useInstallation } from "../hooks/useInstallation";
import { adapterInstallationsToProvinces } from "../adapters/adapterInstallationsToProvinces";
import { AccordionSchedulesInfo } from "../components/AcordionSchedulesInfo";

export const ContactPage = () => {
    const { installations: inst } = useInstallation();

    const [provinces, setProvinces] = useState([]);

    useEffect(() => {
        setProvinces(adapterInstallationsToProvinces(inst));
    }, [inst]);

    return (
        <>
            <HeroTeaser
                inverted
                imgProps={{
                    sizes: "100vw",
                    src: `/images/cabeceras/Header-contact.jpg`,
                }}
                auto
                title={"Contacto"}
            />
            <Main>
                <ContentSection equalHeight pageWrapSize="small">
                    <Wrapper>
                        <Accordion lazyRender multipleOpen onChange={function noRefCheck() {}}>
                            {provinces.map(({ province, installations }) => {
                                return (
                                    <Accordion.Item key={"accordionItem_" + province} title={province}>
                                        {installations.map((installation) => {
                                            return (
                                                <Card
                                                    element="article"
                                                    key={`accordionItem_${province}_${installation.id}`}
                                                >
                                                    <h5>{installation.name}</h5>
                                                    <IconList>
                                                        <IconList.Item icon="location">
                                                            <a
                                                                href={`https://maps.google.com/?q=${installation.address}`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {installation.address}
                                                            </a>
                                                        </IconList.Item>
                                                        <IconList.Item icon="phone">
                                                            <a href={`tel:${installation.phone}`}>
                                                                {installation.phone}
                                                            </a>
                                                        </IconList.Item>
                                                        <IconList.Item icon="mail">
                                                            <a href={`mailto:${installation.email}`}>
                                                                {installation.email}
                                                            </a>
                                                        </IconList.Item>
                                                    </IconList>
                                                    <div className="u-mt">
                                                        <AccordionSchedulesInfo
                                                            installation={installation}
                                                            title={"Horario de apertura"}
                                                        />
                                                    </div>
                                                </Card>
                                            );
                                        })}
                                    </Accordion.Item>
                                );
                            })}
                        </Accordion>
                    </Wrapper>
                </ContentSection>
            </Main>
        </>
    );
};
