/** @format */

import { createContext, useState } from "react";
import useAdobeAnalytics from "../hooks/useAdobeAnalytics";

const CookiesContext = createContext();
export const CookiesContextProvider = ({ children }) => {
    const [showConfig, setShowConfig] = useState(false);
    const { getCookies, setCookies, loadScript } = useAdobeAnalytics();
    const [showModal, setShowModal] = useState(getCookies() == null ? true : false);
    return (
        <>
            <CookiesContext.Provider
                value={{
                    showConfig,
                    setShowConfig,
                    getCookies,
                    setCookies,
                    loadScript,
                    showModal,
                    setShowModal,
                }}
            >
                {children}
            </CookiesContext.Provider>
        </>
    );
};
export default CookiesContext;
