import { useState } from "react";

export const useLoading = (initState = false, initMessage = null) => {
    const [isLoading, setIsLoading] = useState(initState);
    const [message, setMessage] = useState(initMessage);

    const start = (message = null) => {
        setIsLoading(true);
        setMessage(message);
    };

    const stop = () => {
        setIsLoading(false);
        setMessage(undefined);
    };

    return [
        { message, isLoading },
        { start, stop },
    ];
};
