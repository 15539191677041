import moment from "moment";

export const getHoursBetweenDates = (StartDate, EndDate, withFormat = false) => {
    var a = moment(StartDate);
    var b = moment(EndDate);
    var days = b.diff(a, "hours");

    if (withFormat) return days + (days > 1 ? " horas" : " hora");

    return days;
};
