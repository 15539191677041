import { useContext, useEffect, useState } from "react";
import CookiesContext from "../../../context/CookiesContextProvider";
import { isPorsche } from "../../../helpers/isPorsche";
import { isLiteSite } from "../../../helpers/isLiteSite";
import { CookiesBasicModal } from "./CookiesBasicModal";
import { CookiesGeneric } from "./CookiesGeneric/CookiesGeneric";

export const Cookies = () => {
    const { loadScript } = useContext(CookiesContext);

    const [modalType, setModalType] = useState("generic");
    useEffect(() => {
        loadScript();
        if (isPorsche() && isLiteSite()) {
            setModalType("basic");
        } else {
            setModalType("generic");
        }
    }, [loadScript]);

    return (
        <>
            {modalType === "basic" && <CookiesBasicModal />}
            {modalType === "generic" && <CookiesGeneric />}
        </>
    );
};
