import { types } from "../types/types";

export const initialState = {
    coverages: [],
    additionalDriver: null,
    extras: [],
    loadingOptionals: true,
    loadingExtras: true,
};

export const optionalReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.optionalGetCoverages:
            return {
                ...state,
                coverages: [...action.payload],
            };
        case types.optionalGetAdditionalDriver:
            return {
                ...state,
                additionalDriver: { ...action.payload },
            };
        case types.optionalGetExtras:
            return {
                ...state,
                extras: [...action.payload],
            };
        case types.optionalSetOptionalsLoading:
            return {
                ...state,
                loadingOptionals: action.payload,
            };
        case types.optionalSetExtrasLoading:
            return {
                ...state,
                loadingExtras: action.payload,
            };
        case types.optionalResetData:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
