import React, { useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import Initializer from "../hoc/Initializer";
import { AboutUsPage } from "../pages/AboutUsPage";
import { ContactPage } from "../pages/ContactPage";
import { CookiesPolicyPage } from "../pages/CookiesPolicyPage";
import { FaqsPage } from "../pages/FaqsPage";

//PAGES
import { LandingPage } from "../pages/LandingPage";
import { LegalNotePage } from "../pages/LegalNotePage";
import { LicensesPage } from "../pages/LicensesPage";
import { VehiclesPage } from "../pages/VehiclesPage";
import { PrivacyPolicyPage } from "../pages/PrivacyPolicyPage";
import { OptionalsSummaryPage } from "../pages/OptionalsSummaryPage";
import { ContactDetailsPage } from "../pages/ContactDetailsPage";
import { BookingConfirmPage } from "../pages/BookingConfirmPage";

function GeneralRoutes() {
    const location = useLocation();
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
    }, [location]);
    return (
        <Routes>
            <Route element={<Initializer />}>
                <Route index element={<LandingPage />} />
                <Route path="/legal-note" element={<LegalNotePage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/cookies-policy" element={<CookiesPolicyPage />} />
                <Route path="/aboutUs" element={<AboutUsPage />} />
                <Route path="/faqs" element={<FaqsPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/licenses" element={<LicensesPage />} />
                <Route path="/vehicles" element={<VehiclesPage />} />
                <Route path="/optionals-summary" element={<OptionalsSummaryPage />} />
                <Route path="/contact-details" element={<ContactDetailsPage />} />
                <Route path="/booking-confirm" element={<BookingConfirmPage />} />
                <Route path="*" element={<Navigate to={"/"} replace />} />
            </Route>
        </Routes>
    );
}

export default GeneralRoutes;
