import React, { lazy, Suspense } from "react";
import "../scss/components/bronson-error.scss";
const VW6 = lazy(() => import("./vw6"));
const LCV = lazy(() => import("./lcv"));
const AUDI = lazy(() => import("./audi"));
const SKODA = lazy(() => import("./skoda"));
const SEAT = lazy(() => import("./seat"));
const BLUELABEL = lazy(() => import("./bluelabel"));
const GENERIC = lazy(() => import("./generic"));
const PORSCHE = lazy(() => import("./porsche"));
const CUPRA = lazy(() => import("./cupra"));
/**
 * The theme components only imports it's theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */
export const ThemeSelector = ({ children }) => {
  return (
    <>
      <Suspense fallback={<></>}>
        {(process.env.REACT_APP_BRONSON_BRAND === "vw6" ||
          process.env.REACT_APP_BRONSON_BRAND === "vw") && (
          <VW6>{children}</VW6>
        )}
        {process.env.REACT_APP_BRONSON_BRAND === "lcv" && <LCV>{children}</LCV>}
        {process.env.REACT_APP_BRONSON_BRAND === "audi" && (
          <AUDI>{children}</AUDI>
        )}
        {process.env.REACT_APP_BRONSON_BRAND === "skoda" && (
          <SKODA>{children}</SKODA>
        )}
        {process.env.REACT_APP_BRONSON_BRAND === "seat" && (
          <SEAT>{children}</SEAT>
        )}
        {process.env.REACT_APP_BRONSON_BRAND === "bluelabel" && (
          <BLUELABEL>{children}</BLUELABEL>
        )}
        {process.env.REACT_APP_BRONSON_BRAND === "generic" && (
          <GENERIC>{children}</GENERIC>
        )}
        {process.env.REACT_APP_BRONSON_BRAND === "porsche" && (
          <PORSCHE>{children}</PORSCHE>
        )}
        {process.env.REACT_APP_BRONSON_BRAND === "cupra" && (
          <CUPRA>{children}</CUPRA>
        )}
      </Suspense>
    </>
  );
};
