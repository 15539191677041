import React from "react";

import { Layout, Card } from "@vwfs-bronson/bronson-react";

export const LandingBrandThreeSteps = () => {
    return (
        <Layout equalHeight center className={"row"}>
            <Layout.Item default="1/3" s="1/2" xs="1/1">
                <Card element="article" imageSrc={"/images/landing/eliges-img.jpg"} title={"1. Eliges"}>
                    El vehículo que mejor se adapta a tus necesidades, al momento.
                </Card>
            </Layout.Item>
            <Layout.Item default="1/3" s="1/2" xs="1/1">
                <Card element="article" imageSrc={"/images/landing/reservas-img.jpg"} title={"2. Reservas"}>
                    Sin complicaciones, y despreocúpate del pago hasta la recogida.
                </Card>
            </Layout.Item>
            <Layout.Item default="1/3" s="1/2" xs="1/1">
                <Card element="article" imageSrc={"/images/landing/disfrutas-img.jpg"} title={"3. Conduces"}>
                    Recoge y devuelve el vehículo en un concesionario oficial con el asesoramiento de un experto.
                </Card>
            </Layout.Item>
        </Layout>
    );
};
